<template>
  <div>
    <div class="moto-bg">
      <div class="nav">
        <a href="/" target="_self"
          ><img
            src="../assets/images/ui/logo.png"
            style="margin-left: -150px; max-width: 250px"
        /></a>
        <div class="menu details">
          <!-- <div v-if="!token" class="menu-auth">
            <button @click="openLoginPopup">
              Se connecter
            </button>
            |
            <button @click="openRegistrationPopup">
              S'inscrire
            </button>
          </div>
          <div v-else class="menu-auth">
            <button @click="openLoginPopup">Mon Espace</button>
            |
            <button @click="logout">Se déconnecter</button>
          </div> -->
          <a href="/" target="_self" class="menu-web">Accueil</a>
          <a href="/moto" target="_self" class="menu-web">Symone à moto</a>
          <a href="/investors" target="_self" class="menu-web">Espace investisseurs</a>
          <a href="/news" target="_self" class="menu-web">Actus</a>
          <a href="/contact" target="_blank" class="menu-web">Contact</a>
          <a href="/faq" target="_blank" class="menu-web">FAQ</a>
        
          <el-menu
            ellipsis
            mode="horizontal"
            background-color="white"
            text-color="black"
            active-text-color="transparent"
            class="menu-mobile"
          >
            <el-menu-item index="1"><a href="/" target="_self">Accueil</a></el-menu-item>
            <el-menu-item index="2"><a href="/moto" target="_self">Symone à moto</a></el-menu-item>
            <el-menu-item index="3"><a href="/investors" target="_self">Espace investisseurs</a></el-menu-item>
            <el-menu-item index="4"><a href="/news" target="_self">Actus</a></el-menu-item>
            <el-menu-item index="5"><a href="/contact" target="_blank">Contact</a></el-menu-item>
            <el-menu-item index="6"><a href="/faq" target="_blank">FAQ</a></el-menu-item>
          </el-menu>
          <a href="/" target="_blank" class="lang"
            ><img
              src="../assets/images/ui/france.png"
              style="width: 25px; height: 25px"
          /></a>
          <a href="/en" target="_blank" class="lang"
            ><img
              src="../assets/images/ui/royaume-uni.png"
              style="width: 25px; height: 25px"
          /></a>
        </div>
      </div>
      <div class="about1" style="text-align: -webkit-center; padding: 20px 50px 50px 50px">
        <div class="title">
          <span style="color: #e6007e">P</span>rofitez de votre temps, faites
          des économies
        </div>
        <div class="title sub" style="font-size: 25px; line-height: 30px; margin-top: -10px">
          <span style="color: #e6007e">F</span>ini les contraintes à moto sur
          l'autoroute !<br><span style="color: #e6007e">S</span>ymone Moto, le service unique en Europe : transport moto AVEC pilote ou moto seule
        </div>
        <figure class="wp-block-video" style="width: 100%">
          <video
            id="videoSymoneMoto"
            style="border-radius: 10px; max-height: 50vh; max-width: 100%"
            src="../assets/videos/Publicite-Symone-moto-V12.mp4"
            autoplay="autoplay"
            loop="loop"
            muted=""
            controls="controls"
          ></video>
        </figure>
        <div class="cta-container">
          <div class="cta-btn">
            <img
              src="../assets/images/ui/cta3.png"
            />
            <div class="cta-btn-txt" style="left: 24%;"><a href="#about2"><b>Symone en 2 minutes</b></a><br><div class="border-l" style="margin: 15px 0 0 0;">Unique en Europe</div></div>
          </div>
          <div class="cta-btn">
            <img
              src="../assets/images/ui/cta2-o.png"
            />
            <div class="cta-btn-txt" style="left: 17%;">
              <a href="#results" @click="currentSubMenuRoute = true; subMenuDevis = 'color: white'; subMenuRoute = 'color: #44bbc3';">
                <b>Compléter un trajet existant</b>
              </a><br>
              <div class="border-l" style="margin: 15px 0 0 0;">
                <a href="#devis-title" @click="currentSubMenuRoute = false; subMenuDevis = 'color: #44bbc3'; subMenuRoute = 'color: white';">Demander un devis</a>
              </div>
            </div>
            <div class="or">OU</div>
          </div>
          <div class="cta-btn">
            <img
              src="../assets/images/ui/cta1.png"
              style="margin-left: 0;"
            />
            <div class="cta-btn-txt" style="left: 17%;"><a @click="searchSp()"><b>Spécial Paris - Marseille</b></a><br><div class="border-l" style="margin: 15px 0 0 -50px;">à partir de <span class="rose" style="font-size: 30px"><b>250€</b></span> TTC !</div></div>
          </div>
        </div>
        <br />
        <div
          class="border"
          style="
            max-width: 80%;
            color: white;
            font-size: 20px;
            margin-bottom: 30px;
          "
        >
          Confiez à Symone votre voyage et vos trajets longue distance sur
          autoroutes. Vous et votre moto arriverez à destination dans les
          meilleures conditions, au meilleur prix, pour profiter du plaisir de
          piloter là où le meilleur de votre passion peut s'exercer. Transport
          de moto sans pilote possible.
          <br /><br />
          Votre moto est assurée pendant toute la durée du trajet en Symone.
        </div>
        <!-- <a href="/gift" target="_blank"
          ><button class="btn-rose">
            Offrez un bon cadeau Symone moto pour vos proches motards !
          </button></a
        > -->
      </div>
      <!-- 
      <div class="tags">
        <button class="tag" :style="tagBg1" @click="switchTab(1)">
          <div>Présentation</div>
        </button>
        <button class="tag tag-child" :style="tagBg2" @click="switchTab(2)">
          <div>Nos Trajets</div>
        </button>
        <button class="tag tag-child" :style="tagBg3" @click="switchTab(3)">
          <div>Mes trajets</div>
        </button>
        <button class="tag tag-child" :style="tagBg4" @click="switchTab(4)">
          <div>Mes devis</div>
        </button>
        <button class="tag tag-child" :style="tagBg5" @click="switchTab(5)">
          <div>Mon compte</div>
        </button>
      </div> -->
      <div class="submenu"></div>
      <div v-if="currentTab !== 2" class="submenu-bottom"></div>
      <div v-if="currentTab == 2" class="submenu-bottom">
        <div
          class="submenu-option w-2"
          :style="subMenuRoute"
          @click="
            currentSubMenuRoute = true;
            subMenuRoute = 'color: #44bbc3';
            subMenuDevis = 'color: white';
          "
        >
          - Trajets -
        </div>
        <div
          id="tab-devis"
          class="submenu-option w-2"
          :style="subMenuDevis"
          @click="
            currentSubMenuRoute = false;
            subMenuDevis = 'color: #44bbc3';
            subMenuRoute = 'color: white';
          "
        >
          - Demande devis -
        </div>
      </div>
      <div class="window">
        <div v-if="currentTab == 2">
          <div class="route">
            <div class="route-search">
              <a
                class="route-search-partners"
                v-for="(row, index) in partnersLogo"
                :key="index"
                :href="row.link"
                target="_blank"
              >
                <img
                  :src="row.src"
                  width="200"
                  :title="row.alt"
                  :alt="row.alt"
                  style="margin-bottom: 80px"
                />
              </a>
              <div class="route-search-container">
                <div class="flex-col">
                  <div class="number">1</div>
                  <div class="border">
                    <span style="color: #e6007e">V</span>otre souhait de trajet
                  </div>
                </div>
                <div class="route-search-options">
                  <div class="route-search-options-card">
                    <el-form
                      :model="search"
                      label-width="auto"
                      style="max-width: 600px"
                    >
                      <el-form-item>
                        <Autocomplete
                          :key="refreshAutocomplete"
                          :required="true"
                          class="autocomplete"
                          v-model="search.depart"
                          placeholder="Ville de départ"
                        ></Autocomplete>
                      </el-form-item>
                      <el-form-item>
                        <Autocomplete
                          :key="refreshAutocomplete"
                          :required="true"
                          class="autocomplete"
                          v-model="search.arrival"
                          placeholder="Destination"
                        ></Autocomplete>
                      </el-form-item>
                      <el-form-item>
                        <el-date-picker
                          v-model="search.date"
                          type="date"
                          value-format="YYYYMMDD"
                          style="width: 100%"
                          placeholder="Date de départ"
                        />
                      </el-form-item>
                    </el-form>
                    <button class="btn-rose" @click="searchCities">
                      Rechercher
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="currentSubMenuRoute" class="route-results">
              <div class="loading-container" v-if="isLoading">
                <div class="loading-spinner"></div>
              </div>
              <div class="flex" id="results">
                <div class="number">2</div>
                <div style="color: #000047; text-align: center;">
                  <div style="display: inline-block; vertical-align: middle;"><span style="color: #e6007e">S</span>électionnez un trajet à
                  compléter OU&nbsp;</div>
                  <button class="btn-rose"
                    @click="
                      currentSubMenuRoute = false;
                      subMenuDevis = 'color: #44bbc3';
                      subMenuRoute = 'color: white';
                    "
                    >Demandez un devis</button
                  >
                </div>
              </div>
              <br />

              <div class="pagination-date">
                <button 
                  class="pagination-btn" 
                  @click="handleCurrentYYYYMM(1, resultsYYYYMM[resultsYYYYMM.indexOf(currentYYYYMM) - 1])" 
                  :disabled="resultsYYYYMM.indexOf(visibleResultsYYYYMM[0]) == 0"
                >
                  &lt;
                </button>

                <button
                  v-for="(item) in visibleResultsYYYYMM"
                  :key="item"
                  :class="['pagination-date-btn', { active: item === currentYYYYMM }]"
                  @click="handleCurrentYYYYMM(2, item)"
                >
                  {{ convertToFRMonthYear(item) }}
                </button>

                <button 
                  class="pagination-btn" 
                  @click="handleCurrentYYYYMM(3, resultsYYYYMM[resultsYYYYMM.indexOf(currentYYYYMM) + 1])" 
                  :disabled="resultsYYYYMM.indexOf(visibleResultsYYYYMM[0]) >= resultsYYYYMM.length - 5"
                >
                  &gt;
                </button>
              </div>

              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalItems"
                :page-size="pageSize"
                :pager-count="6"
                @current-change="handleCurrentChange"
                v-model:current-page="currentPage"
              />

              <div class="results">
                <div class="flex sticky">
                  <el-affix target=".results" :offset="10" position="bottom">
                    <el-button
                      type="primary"
                      color="#000047"
                      @click="getResults()"
                      >Afficher tous les trajets</el-button
                    >
                  </el-affix>
                  <el-affix target=".results" :offset="10" position="bottom">
                    <el-button type="primary" color="#44bbc3" @click="backTop()"
                      >TOP↑</el-button
                    >
                  </el-affix>
                </div>
                <div
                  class="result-container"
                  v-for="(row, index) in displayedItems"
                  :key="index"
                >
                  <!-- <div v-if="row.first" class="hr-line border">
                    <hr />
                    <br />Ancien(s) trajet(s)<br /><br />
                    <hr />
                  </div> -->
                  <div v-if="row.type == 'route'" class="card-route">
                    <div v-if="!checkDate(row.date)" class="overlay-blue">
                      <div>Réalisé</div>
                    </div>
                    <div
                      v-if="checkDate(row.date) && row.completed"
                      class="overlay-blue"
                    >
                      <div>Complet</div>
                    </div>
                    <div class="card-route-header">
                      <div class="card-route-header-date">
                        {{ fullDateFR(row.date) }}
                      </div>
                      <div
                        v-if="!row.completed && checkDate(row.date)"
                        class="card-route-header-info"
                      >
                        
                        <div class="card-route-header-info-moto">
                          <el-popover
                            placement="top"
                            trigger="click"
                            content="Prise 220v"
                          >
                            <template #reference>
                              <span
                                class="material-symbols-outlined"
                                title="Prise 220v"
                                >power</span
                              >
                            </template>
                          </el-popover>

                          <el-popover
                            placement="top"
                            trigger="click"
                            content="Prise USB"
                          >
                            <template #reference>
                              <span
                                class="material-symbols-outlined"
                                title="Prises USB"
                                >usb</span
                              >
                            </template>
                          </el-popover>

                          <el-popover
                            placement="top"
                            trigger="click"
                            content="Café"
                          >
                            <template #reference>
                              <span
                                class="material-symbols-outlined"
                                title="Café"
                                >coffee</span
                              >
                            </template>
                          </el-popover>

                          <el-popover
                            placement="top"
                            trigger="click"
                            content="Jeux de société"
                          >
                            <template #reference>
                              <span
                                class="material-symbols-outlined"
                                title="Jeux de société"
                                >playing_cards</span
                              >
                            </template>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                    <div class="card-route-info">
                      <div
                        v-if="
                          row.discount_value &&
                          checkDate(row.date) &&
                          !row.completed
                        "
                        class="card-discount"
                      >
                        -{{ row.discount_value
                        }}{{
                          row.discount_type == "1" ? "%" : "€"
                        }}
                        immédiatement
                      </div>
                      <div class="card-route-info-flex">
                        <div>
                          <div class="card-route-info-first">
                            <div class="card-route-info-route">
                              <b
                                ><span style="color: black">{{
                                  row.depart.split(",")[1].trim() == "France"
                                    ? row.depart.split(",")[0]
                                    : row.depart.split(",")[0] +
                                      "(" +
                                      showCountryAbbreviation(
                                        row.depart.split(",")[1]
                                      ) +
                                      ")"
                                }}</span></b
                              >
                              <span
                                class="rose"
                                style="font-size: 20px; display: contents"
                                ><span
                                  v-if="row.layover1"
                                  class="material-symbols-outlined rose"
                                  >east</span
                                >
                                {{
                                  row.layover1
                                    ? row.layover1.split(",")[1].trim() == "France"
                                      ? row.layover1.split(",")[0] + "(*)"
                                      : row.layover1.split(",")[0] +
                                        "(" +
                                        showCountryAbbreviation(
                                          row.layover1.split(",")[1]
                                        ) +
                                        ")" +
                                        "(*)"
                                    : ""
                                }}
                                <span
                                  v-if="row.layover2"
                                  class="material-symbols-outlined rose"
                                  >east</span
                                >
                                {{
                                  row.layover2
                                    ? row.layover2.split(",")[1].trim() == "France"
                                      ? row.layover2.split(",")[0] + "(*)"
                                      : row.layover2.split(",")[0] +
                                        "(" +
                                        showCountryAbbreviation(
                                          row.layover2.split(",")[1]
                                        ) +
                                        ")" +
                                        "(*)"
                                    : ""
                                }}
                                <span
                                  v-if="row.layover3"
                                  class="material-symbols-outlined rose"
                                  >east</span
                                >
                                {{
                                  row.layover3
                                    ? row.layover3.split(",")[1].trim() == "France"
                                      ? row.layover3.split(",")[0] + "(*)"
                                      : row.layover3.split(",")[0] +
                                        "(" +
                                        showCountryAbbreviation(
                                          row.layover3.split(",")[1]
                                        ) +
                                        ")" +
                                        "(*)"
                                    : ""
                                }}
                                <span
                                  v-if="
                                    row.layover1 || row.layover2 || row.layover3
                                  "
                                  class="material-symbols-outlined rose"
                                  >east</span
                                >
                              </span>
                              <span
                                v-if="
                                  !row.layover1 && !row.layover2 && !row.layover3
                                "
                                class="material-symbols-outlined rose"
                                >east</span
                              ><b
                                ><span style="color: black">{{
                                  row.arrival.split(",")[1].trim() == "France"
                                    ? row.arrival.split(",")[0]
                                    : row.arrival.split(",")[0] +
                                      "(" +
                                      showCountryAbbreviation(
                                        row.arrival.split(",")[1]
                                      ) +
                                      ")"
                                }}</span></b
                              >
                            </div>
                          </div>
                          <div
                            v-if="row.layover1 || row.layover2 || row.layover3"
                            class="card-route-info-second rose"
                          >
                            *Arrêt(s) facultatif(s)
                          </div>
                        </div>
                        <br><br>
                        <div class="card-route-info-price">
                          <div v-if="row.completed == 0 && row.price_max > 0 && row.price_max != row.price_min && row.price_min != 9999">De <b>{{ row.price_min }}€</b> à <b>{{ row.price_max }}€</b> TTC⁽¹⁾</div>
                          <div v-else-if="row.completed == 0 && row.price_max > 0 && row.price_max == row.price_min"><b>{{ row.price_max }}€</b> TTC⁽¹⁾</div>
                          <div v-else-if="row.price_max == 0"></div>
                        </div>
                      </div>
                    </div>
                    <el-collapse v-model="collapseStatus[row.id]">
                      <el-collapse-item name="1">
                        <template #title>
                          <div class="card-route-info-footer-txt" style="min-width: fit-content; color: #000047">
                            Sur demande, autres trajets possibles sur ce
                            parcours.
                            <div v-if="row.completed == 0 && row.price_max > 0">⁽¹⁾ Tarif pour une moto avec son pilote. Le transport de motos seules possède une tarification différente.</div>
                          </div>
                          <div style="width: -webkit-fill-available"></div>
                          <button class="btn-blue">Détails</button>
                        </template>
                        <div
                          class="route-plan"
                          v-if="!row.completed && checkDate(row.date)"
                        >
                          <el-timeline
                            style="
                              width: 200px;
                              background: white;
                              border-radius: 20px;
                            "
                          >
                            <div class="timeline-bloc" v-if="row.time0">
                              <div class="time">
                                {{
                                  formatTimestamp(row.time0, row.places_moto_1)
                                }}
                              </div>
                              <el-timeline-item color="#44bbc3" size="large">
                                <div>
                                  Début chargement
                                  {{
                                    row.places_moto_1 <= 3
                                      ? "30"
                                      : "15"
                                  }}
                                  minutes avant le départ
                                </div>
                              </el-timeline-item>
                            </div>
                            <div class="timeline-bloc" v-if="row.depart">
                              <div v-if="row.time0" class="time">
                                {{ row.time0 }}
                              </div>
                              <div v-else class="time white">00:00</div>
                              <el-timeline-item color="black" size="large">
                                <a
                                  v-if="row.address_depart"
                                  :title="row.address_depart"
                                  :href="`https://www.google.com/maps?q=${row.address_depart}`"
                                  target="_blank"
                                  ><u
                                    ><b>{{ row.depart.split(",")[0] }}</b></u
                                  ></a
                                >
                                <div v-else>
                                  <b>{{ row.depart.split(",")[0] }}</b>
                                </div>

                                <div
                                  class="timeline-comment"
                                  v-if="row.address_depart"
                                >
                                  Lien cliquable adresse
                                </div>
                              </el-timeline-item>
                            </div>
                            <div class="timeline-bloc" v-if="row.layover1">
                              <div v-if="row.time1" class="time">
                                {{ row.time1 }}
                              </div>
                              <div v-else class="time white">00:00</div>
                              <el-timeline-item color="#E6007E" size="normal">
                                <a
                                  v-if="row.address_layover1"
                                  :title="row.address_layover1"
                                  :href="`https://www.google.com/maps?q=${row.address_layover1}`"
                                  target="_blank"
                                >
                                  <span class="rose"
                                    ><u
                                      ><b>{{
                                        row.layover1.split(",")[0]
                                      }}</b></u
                                    ></span
                                  ></a
                                >
                                <div v-else>
                                  <span class="rose"
                                    ><b>{{
                                      row.layover1.split(",")[0]
                                    }}</b></span
                                  >
                                </div>
                                <div
                                  class="timeline-comment"
                                  v-if="row.address_layover1"
                                >
                                  Lien cliquable adresse
                                </div>
                              </el-timeline-item>
                            </div>

                            <div class="timeline-bloc" v-if="row.layover2">
                              <div v-if="row.time2" class="time">
                                {{ row.time2 }}
                              </div>
                              <div v-else class="time white">00:00</div>
                              <el-timeline-item color="#E6007E" size="normal"
                                ><a
                                  v-if="row.address_layover2"
                                  :title="row.address_layover2"
                                  :href="`https://www.google.com/maps?q=${row.address_layover2}`"
                                  target="_blank"
                                >
                                  <span class="rose"
                                    ><u
                                      ><b>{{
                                        row.layover2.split(",")[0]
                                      }}</b></u
                                    ></span
                                  ></a
                                >
                                <div v-else>
                                  <span class="rose"
                                    ><b>{{
                                      row.layover2.split(",")[0]
                                    }}</b></span
                                  >
                                </div>
                                <div
                                  class="timeline-comment"
                                  v-if="row.address_layover2"
                                >
                                  Lien cliquable adresse
                                </div>
                              </el-timeline-item>
                            </div>
                            <div class="timeline-bloc" v-if="row.layover3">
                              <div v-if="row.time3" class="time">
                                {{ row.time3 }}
                              </div>
                              <div v-else class="time white">00:00</div>
                              <el-timeline-item color="#E6007E" size="normal"
                                ><a
                                  v-if="row.address_layover3"
                                  :title="row.address_layover3"
                                  :href="`https://www.google.com/maps?q=${row.address_layover3}`"
                                  target="_blank"
                                >
                                  <span class="rose"
                                    ><u
                                      ><b>{{
                                        row.layover3.split(",")[0]
                                      }}</b></u
                                    ></span
                                  >
                                </a>
                                <div v-else>
                                  <span class="rose"
                                    ><b>{{
                                      row.layover3.split(",")[0]
                                    }}</b></span
                                  >
                                </div>
                                <div
                                  class="timeline-comment"
                                  v-if="row.address_layover3"
                                >
                                  Lien cliquable adresse
                                </div>
                              </el-timeline-item>
                            </div>
                            <div class="timeline-bloc" v-if="row.arrival">
                              <div v-if="row.time4" class="time">
                                {{ row.time4 }}
                              </div>
                              <div v-else class="time white">00:00</div>

                              <el-timeline-item color="black" size="large">
                                <a
                                  v-if="row.address_arrival"
                                  :title="row.address_arrival"
                                  :href="`https://www.google.com/maps?q=${row.address_arrival}`"
                                  target="_blank"
                                >
                                  <u
                                    ><b>{{ row.arrival.split(",")[0] }}</b></u
                                  ></a
                                >
                                <div v-else>
                                  <b>{{ row.arrival.split(",")[0] }}</b>
                                </div>

                                <div
                                  class="timeline-comment"
                                  v-if="row.address_arrival"
                                >
                                  Lien cliquable adresse
                                </div>
                              </el-timeline-item>
                            </div>
                          </el-timeline>
                          <div class="route-options">
                            <div class="route-options-discount">
                              <div
                                v-if="row.discount_value"
                                class="card-discount-red"
                              >
                                -{{ row.discount_value
                                }}{{
                                  row.discount_type == "1" ? "%" : "€"
                                }}
                                immédiatement
                              </div>
                              <div
                                v-if="
                                  row.discount_group_value &&
                                  row.discount_group_moto
                                "
                                class="card-discount-blue"
                              >
                                Tarif groupe: -{{ row.discount_group_value }}%
                                pour ≥ {{ row.discount_group_moto }} motos
                              </div>
                              <span
                                @click="showHelpPopup = true"
                                style="cursor: pointer; font-size: 40px; color: #000047"
                                class="material-symbols-outlined"
                                title="Comment ça fonctionne ?"
                                >help</span
                              >
                            </div>
                            <b>- Veuillez choisir votre trajet -</b>
                            <br />
                            <div
                              v-if="row.price0_4 && row.depart && row.arrival"
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '0-4')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price0_4) == false
                                  "
                                >
                                  {{ row.depart.split(",")[0] }} -
                                  {{ row.arrival.split(",")[0] }} :
                                  <b>{{ row.price0_4 }}</b
                                  >{{ /^\d+$/.test(row.price0_4) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.depart.split(",")[0] }} -
                                  {{ row.arrival.split(",")[0] }} :
                                  <s>{{ row.price0_4 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price0_4) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price0_4) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="row.price0_1 && row.depart && row.layover1"
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '0-1')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price0_1) == false
                                  "
                                >
                                  {{ row.depart.split(",")[0] }} -
                                  {{ row.layover1.split(",")[0] }} :
                                  <b>{{ row.price0_1 }}</b
                                  >{{ /^\d+$/.test(row.price0_1) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.depart.split(",")[0] }} -
                                  {{ row.layover1.split(",")[0] }} :
                                  <s>{{ row.price0_1 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price0_1) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price0_1) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="row.price0_2 && row.depart && row.layover2"
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '0-2')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price0_2) == false
                                  "
                                >
                                  {{ row.depart.split(",")[0] }} -
                                  {{ row.layover2.split(",")[0] }} :
                                  <b>{{ row.price0_2 }}</b
                                  >{{ /^\d+$/.test(row.price0_2) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.depart.split(",")[0] }} -
                                  {{ row.layover2.split(",")[0] }} :
                                  <s>{{ row.price0_2 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price0_2) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price0_2) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="row.price0_3 && row.depart && row.layover3"
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '0-3')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price0_3) == false
                                  "
                                >
                                  {{ row.depart.split(",")[0] }} -
                                  {{ row.layover3.split(",")[0] }} :
                                  <b>{{ row.price0_3 }}</b
                                  >{{ /^\d+$/.test(row.price0_3) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.depart.split(",")[0] }} -
                                  {{ row.layover3.split(",")[0] }} :
                                  <s>{{ row.price0_3 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price0_3) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price0_3) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="
                                row.price1_2 && row.layover1 && row.layover2
                              "
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '1-2')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price1_2) == false
                                  "
                                >
                                  {{ row.layover1.split(",")[0] }} -
                                  {{ row.layover2.split(",")[0] }} :
                                  <b>{{ row.price1_2 }}</b
                                  >{{ /^\d+$/.test(row.price1_2) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.layover1.split(",")[0] }} -
                                  {{ row.layover2.split(",")[0] }} :
                                  <s>{{ row.price1_2 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price1_2) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price1_2) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="
                                row.price1_3 && row.layover1 && row.layover3
                              "
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '1-3')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price1_3) == false
                                  "
                                >
                                  {{ row.layover1.split(",")[0] }} -
                                  {{ row.layover3.split(",")[0] }} :
                                  <b>{{ row.price1_3 }}</b
                                  >{{ /^\d+$/.test(row.price1_3) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.layover1.split(",")[0] }} -
                                  {{ row.layover3.split(",")[0] }} :
                                  <s>{{ row.price1_3 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price1_3) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price1_3) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="row.price1_4 && row.layover1 && row.arrival"
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '1-4')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price1_4) == false
                                  "
                                >
                                  {{ row.layover1.split(",")[0] }} -
                                  {{ row.arrival.split(",")[0] }} :
                                  <b>{{ row.price1_4 }}</b
                                  >{{ /^\d+$/.test(row.price1_4) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.layover1.split(",")[0] }} -
                                  {{ row.arrival.split(",")[0] }} :
                                  <s>{{ row.price1_4 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price1_4) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price1_4) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="
                                row.price2_3 && row.layover2 && row.layover3
                              "
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '2-3')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price2_3) == false
                                  "
                                >
                                  {{ row.layover2.split(",")[0] }} -
                                  {{ row.layover3.split(",")[0] }} :
                                  <b>{{ row.price2_3 }}</b
                                  >{{ /^\d+$/.test(row.price2_3) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.layover2.split(",")[0] }} -
                                  {{ row.layover3.split(",")[0] }} :
                                  <s>{{ row.price2_3 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price2_3) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price2_3) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="row.price2_4 && row.layover2 && row.arrival"
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '2-4')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price2_4) == false
                                  "
                                >
                                  {{ row.layover2.split(",")[0] }} -
                                  {{ row.arrival.split(",")[0] }} :
                                  <b>{{ row.price2_4 }}</b
                                  >{{ /^\d+$/.test(row.price2_4) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.layover2.split(",")[0] }} -
                                  {{ row.arrival.split(",")[0] }} :
                                  <s>{{ row.price2_4 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price2_4) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price2_4) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div
                              v-if="row.price3_4 && row.layover3 && row.arrival"
                            >
                              <button class="btn-option" @click="openReservationPopup(row, '3-4')">
                                <p
                                  v-if="
                                    !row.discount_value ||
                                    /^\d+$/.test(row.price3_4) == false
                                  "
                                >
                                  {{ row.layover3.split(",")[0] }} -
                                  {{ row.arrival.split(",")[0] }} :
                                  <b>{{ row.price3_4 }}</b
                                  >{{ /^\d+$/.test(row.price3_4) ? "€ TTC" : "" }}
                                </p>
                                <p v-else>
                                  {{ row.layover3.split(",")[0] }} -
                                  {{ row.arrival.split(",")[0] }} :
                                  <s>{{ row.price3_4 }}</s
                                  ><span style="color: #c50909"
                                    ><b>{{
                                      row.discount_type == "1"
                                        ? parseInt(row.price3_4) *
                                          (1 - parseInt(row.discount_value) / 100)
                                        : parseInt(row.price3_4) -
                                          parseInt(row.discount_value)
                                    }}</b
                                    >€</span
                                  >
                                  TTC
                                </p>
                              </button>
                            </div>
                            <div>
                              <button class="btn-option" @click="openReservationPopup(row, 'other')">
                              Autre trajet souhaité sur ce parcours
                              </button>
                            </div>
                            <br />
                          </div>
                        </div>
                        <div
                          v-if="
                            row.eco &&
                            row.eco != '<p><br></p>' &&
                            !row.completed &&
                            checkDate(row.date)
                          "
                          class="route-eco"
                          v-html="row.eco"
                        ></div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>

                  <el-card
                    v-if="row.type == 'event' && checkDate(row.date)"
                    style="max-width: 500px; margin: 30px 0"
                  >
                    <template #header>
                      <div class="card-header">
                        <span style="font-size: 20px">{{
                          fullDateFR(row.date)
                        }}</span>
                        <button
                          class="btn-blue"
                          @click="openReservationPopup(row)"
                        >
                          Devis
                        </button>
                      </div>
                    </template>
                    <div class="card-title">
                      <img
                        v-if="row.img"
                        :src="row.img"
                        style="max-width: 30%"
                      />
                      <span style="font-size: 20px"
                        ><b
                          >{{ row.name
                          }}{{
                            row.location ? "(" + row.location + ")" : ""
                          }}</b
                        ></span
                      >
                    </div>
                    <template #footer
                      ><p style="font-size: 15px">
                        {{ row.details }}
                      </p></template
                    >
                  </el-card>
                </div>
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="totalItems"
                  :page-size="pageSize"
                  :pager-count="6"
                  @current-change="handleCurrentChange"
                  v-model:current-page="currentPage"
                />
                <div class="pagination-date">
                  <button 
                    class="pagination-btn" 
                    @click="handleCurrentYYYYMM(1, resultsYYYYMM[resultsYYYYMM.indexOf(currentYYYYMM) - 1])" 
                    :disabled="resultsYYYYMM.indexOf(visibleResultsYYYYMM[0]) == 0"
                  >
                    &lt;
                  </button>

                  <button
                    v-for="(item) in visibleResultsYYYYMM"
                    :key="item"
                    :class="['pagination-date-btn', { active: item === currentYYYYMM }]"
                    @click="handleCurrentYYYYMM(2, item)"
                  >
                    {{ convertToFRMonthYear(item) }}
                  </button>

                  <button 
                    class="pagination-btn" 
                    @click="handleCurrentYYYYMM(3, resultsYYYYMM[resultsYYYYMM.indexOf(currentYYYYMM) + 1])" 
                    :disabled="resultsYYYYMM.indexOf(visibleResultsYYYYMM[0]) >= resultsYYYYMM.length - 5"
                  >
                    &gt;
                  </button>
                </div>
              </div>
              <div class="partners w-1">
                <div class="white">NOS PARTENAIRES</div>
              </div>
              <div class="w-1 partners-list">
                <a
                  v-for="(row, index) in partnersLogo"
                  :key="index"
                  :href="row.link"
                  target="_blank"
                >
                  <img
                    :src="row.src"
                    width="200"
                    :title="row.alt"
                    :alt="row.alt"
                  />
                </a>
              </div>
              <hr class="w-1" />
            </div>

            <div v-if="!currentSubMenuRoute" class="route-results">
              <div class="flex-col" id="devis-title">
                <div class="flex">
                  <div class="number">2</div>
                  <div class="border" style="text-align: center">
                    <span style="color: #e6007e">A</span>ucun trajet
                    correspondant, demandez un devis
                  </div>
                </div>
                <el-form 
                  ref="ruleEstimateRef"
                  style="max-width: 600px; font-size: 10px;"
                  :model="formEstimate"
                  :rules="rulesEstimate"
                  label-width="auto"
                  label-position="top"
                  class="form"
                  v-loading="isEstimateSending"
                  status-icon
                  id="formEstimateTop"
                >
                  <el-steps style="max-width: 600px; width: 100%;" :active="stepEstimate" finish-status="success" align-center>
                    <el-step v-if="stepEstimate > 0" title="Trajet" @click="stepEstimate = 0" class="clickable" />
                    <el-step v-else title="Trajet" />
                    <el-step v-if="stepEstimate > 1" title="Contact" @click="stepEstimate = 1" class="clickable" />
                    <el-step v-else title="Contact" />
                    <el-step v-if="stepEstimate > 2" title="Moto" @click="stepEstimate = 2" class="clickable" />
                    <el-step v-else title="Moto" />
                    <el-step v-if="stepEstimate > 3" title="Voyageur" @click="stepEstimate = 3" class="clickable" />
                    <el-step v-else title="Voyageur" />
                    <el-step v-if="stepEstimate > 4" title="Options" @click="stepEstimate = 4" class="clickable" />
                    <el-step v-else title="Options" />
                    <el-step title="Récapitulatif" />
                  </el-steps>
                  <br><br>
                  <div v-if="stepEstimate == 0">
                    <el-form-item v-if="formEstimate.type == 'roundTrip'"><b>Trajet ALLER</b></el-form-item>
                    <br v-if="formEstimate.type == 'roundTrip'">
                    <div>
                      <el-col :span="12">
                        <el-form-item
                          required
                          prop="depart_date"
                          class="flex-nowrap"
                        >
                          <span style="color: red">*&nbsp;</span><el-input
                            v-model="formEstimate.depart_date"
                            type="text"
                            placeholder="Date ou période de départ"
                            style="width: 100%"
                            maxlength="45"
                          />
                        </el-form-item>
                      </el-col>
                    </div>

                    <div>
                      <el-col :span="12" class="c-12">
                        <el-form-item prop="depart_city" class="flex-nowrap">
                          De&nbsp;<Autocomplete
                            placeholder="Ville de départ"
                            v-model="formEstimate.depart_city"
                          ></Autocomplete>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" class="c-12">
                        <el-form-item prop="arrival_city" class="flex-nowrap">
                          &nbsp;à&nbsp;<Autocomplete
                            placeholder="Ville d'arrivée"
                            v-model="formEstimate.arrival_city"
                          ></Autocomplete>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="En cas de problème de saisie, veuillez indiquer la ville dans le commentaire en étape 5 : Options."></el-form-item>
                      </el-col>
                    </div>
                    
                    <el-col :span="12" class="c-12">
                      <el-form-item
                        required 
                        prop="moto_nb"
                        class="flex-nowrap"
                      >
                        <span style="color: red">*&nbsp;</span>Nbr motos :&nbsp;<el-input
                          placeholder="≤12"
                          type="number" @wheel.prevent 
                          v-model.number="formEstimate.moto_nb"
                          @input="formEstimate.moto_nb > 12 ? formEstimate.moto_nb = 12 : ''; formEstimate.moto_nb < 0 ? formEstimate.moto_nb = 0 : ''"
                        />
                      </el-form-item><br>
                    </el-col>
                    
                    <el-col :span="12" class="c-12">
                      <el-form-item
                        required
                        prop="passenger_nb"
                        class="flex-nowrap"
                      >
                        <span style="color: red">&nbsp;*</span>&nbsp;Nbr personnes :&nbsp;<el-input
                          placeholder="≤20"
                          type="number" @wheel.prevent 
                          v-model.number="formEstimate.passenger_nb"
                          @input="generatePassengerList"
                        />
                      </el-form-item>
                    </el-col>

                    <div>
                      <el-form-item required prop="type">
                        <el-radio-group v-model="formEstimate.type" @change="setDefaultReturnInfo">
                          <el-radio value="oneWay">Aller simple</el-radio>
                          <el-radio value="roundTrip">Aller-retour</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>

                    <div v-if="formEstimate.type === 'roundTrip'" class="roundTrip">
                      <br>
                      <el-form-item><b>Trajet RETOUR</b></el-form-item>
                      <br>
                      <el-col :span="12">
                        <el-form-item
                          required
                          prop="date_return"
                          class="flex-nowrap"
                        >
                          <span style="color: red">*&nbsp;</span><el-input
                            v-model="formEstimate.date_return"
                            type="text"
                            placeholder="Date ou période de retour"
                            style="width: 100%"
                            maxlength="45"
                          />
                        </el-form-item>
                      </el-col>
                      <br>
                      <el-col :span="12" class="c-12">
                        <el-form-item prop="depart_return" class="flex-nowrap">
                          De&nbsp;<Autocomplete
                            :key="refreshAutocomplete"
                            placeholder="Ville de départ RETOUR"
                            v-model="formEstimate.depart_return"
                          ></Autocomplete>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" class="c-12">
                        <el-form-item prop="arrival_return" class="flex-nowrap">
                          &nbsp;à&nbsp;<Autocomplete
                            :key="refreshAutocomplete"
                            placeholder="Ville d'arrivée RETOUR"
                            v-model="formEstimate.arrival_return"
                          ></Autocomplete>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item label="En cas de problème de saisie, veuillez indiquer la ville dans le commentaire en étape 5 : Options."></el-form-item>
                      </el-col>
                      <el-col :span="12" class="c-12">
                        <el-form-item 
                          required 
                          prop="moto_nb_return"
                          class="flex-nowrap"
                        >
                          <span style="color: red">*&nbsp;</span>Nbr motos :&nbsp;<el-input
                            placeholder="≤12"
                            type="number" @wheel.prevent 
                            v-model.number="formEstimate.moto_nb_return"
                            @input="formEstimate.moto_nb_return > 12 ? formEstimate.moto_nb_return = 12 : ''; formEstimate.moto_nb_return < 0 ? formEstimate.moto_nb_return = 0 : ''"
                          />
                        </el-form-item><br>
                      </el-col>

                      <el-col :span="12" class="c-12">
                        <el-form-item
                          required
                          prop="passenger_nb_return"
                          class="flex-nowrap"
                        >
                          <span style="color: red">&nbsp;*</span>&nbsp;Nbr personnes :&nbsp;<el-input
                            placeholder="≤20"
                            type="number" @wheel.prevent 
                            v-model.number="formEstimate.passenger_nb_return"
                            @input="generatePassengerListReturn"
                          />
                        </el-form-item>
                      </el-col>
                    </div>
                  </div>

                  <div v-if="stepEstimate == 1">
                    <br />
                    <el-form-item label="Vos coordonnées de contact">
                      <br>
                      <el-col :span="12">
                        <el-form-item label="Prénom" required prop="firstname">
                          <el-input type="text" v-model="formEstimate.firstname" maxlength="45" />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Nom" required prop="lastname">
                          <el-input
                            type="text"
                            v-model="formEstimate.lastname"
                            required
                            maxlength="45" 
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="E-mail" required prop="email">
                          <el-input type="email" v-model="formEstimate.email" maxlength="200" />
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Téléphone" required prop="phone">
                          <el-input type="text" v-model="formEstimate.phone" maxlength="45" />
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                  </div>

                  <div v-if="stepEstimate == 2">
                    <el-form-item v-if="formEstimate.type == 'roundTrip' && formEstimate.moto_nb > 0"><b>Trajet ALLER</b></el-form-item>
                    <br v-if="formEstimate.type == 'roundTrip' && formEstimate.moto_nb > 0">
                    <el-form-item label="Modèle de chaque moto" v-if="formEstimate.moto_nb > 0">
                      <el-col :span="24">
                        <el-input
                          type="textarea"
                          v-model="formEstimate.moto_list"
                        />
                      </el-col>
                    </el-form-item>
                    <el-checkbox-group v-model="formEstimate.copy_moto" v-if="formEstimate.moto_nb > 0 && formEstimate.type == 'roundTrip' && formEstimate.moto_nb_return > 0" @change="copyListMoto">
                      <el-checkbox value="true" name="copy_moto">
                        Utiliser les mêmes infos pour le trajet retour
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-if="formEstimate.type === 'roundTrip' && formEstimate.moto_nb_return > 0 && formEstimate.copy_moto.length == 0" class="roundTrip">
                      <br>
                      <el-form-item><b>Trajet RETOUR</b></el-form-item><br>
                      <el-form-item label="Modèle de chaque moto">
                        <el-col :span="24">
                          <el-input
                            type="textarea"
                            v-model="formEstimate.moto_list_return"
                          />
                        </el-col>
                      </el-form-item>
                    </div>
                  </div>

                  <div v-if="stepEstimate == 3">
                    <div v-if="formEstimate.passenger_nb > 0">
                      <el-form-item v-if="formEstimate.type == 'roundTrip'"><b>Trajet ALLER</b></el-form-item>
                      <br v-if="formEstimate.type == 'roundTrip'">
                      <div
                        v-for="(passenger, index) in formEstimate.passenger_list"
                        :key="index"
                      >
                        <el-form-item
                          v-if="index === 0"
                          label="Liste des voyageurs (facultatif) :"
                        ></el-form-item>
                        <el-col :span="6">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.firstname" placeholder="Prénom" maxlength="45" />
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.lastname" placeholder="NOM" maxlength="45" />
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.email" placeholder="E-mail" maxlength="200" />
                          </el-form-item>
                        </el-col>
                        <hr v-if="index != formEstimate.passenger_list.length - 1">
                        <br v-if="index != formEstimate.passenger_list.length - 1">
                      </div>
                    </div>
                    <el-checkbox-group v-model="formEstimate.copy_passenger" v-if="formEstimate.passenger_nb > 0 && formEstimate.type == 'roundTrip' && formEstimate.passenger_nb_return > 0 && formEstimate.passenger_nb == formEstimate.passenger_nb_return" @change="copyListPassenger">
                      <el-checkbox value="true" name="copy_passenger">
                        Utiliser les mêmes infos pour le trajet retour
                      </el-checkbox>
                    </el-checkbox-group>
                    <div v-if="formEstimate.type === 'roundTrip' && formEstimate.passenger_nb_return > 0 && formEstimate.copy_passenger.length == 0" class="roundTrip">
                      <br>
                      <el-form-item><b>Trajet RETOUR</b></el-form-item>
                      <br>
                      <div
                        v-for="(passenger, index) in formEstimate.passenger_list_return"
                        :key="index"
                      >
                        <el-form-item
                          v-if="index === 0"
                          label="Liste des voyageurs (facultatif) :"
                        ></el-form-item>
                        <el-col :span="6">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.firstname" placeholder="Prénom" maxlength="45" />
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.lastname" placeholder="NOM" maxlength="45" />
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.email" placeholder="E-mail" maxlength="200" />
                          </el-form-item>
                        </el-col>
                      </div>
                    </div>
                  </div>

                  <div v-if="stepEstimate == 4">
                    <br><br>
                    <el-form-item v-if="formEstimate.type == 'roundTrip' && formEstimate.passenger_nb == 0"><b>Trajet ALLER</b></el-form-item>
                    <br v-if="formEstimate.type == 'roundTrip' && formEstimate.passenger_nb == 0">
                    <div v-if="formEstimate.passenger_nb == 0">
                      <el-col :span="24">
                        <el-form-item prop="charge">
                          <el-checkbox-group v-model="formEstimate.charge">
                            <el-checkbox value="true" name="charge">
                              Si vous souhaitez une autre adresse de chargement : <br>
                              <el-form-item v-if="formEstimate.charge.length > 0">
                                <el-input v-model="formEstimate.address_charge" placeholder="Votre adresse de chargement" maxlength="255" />
                              </el-form-item>
                            </el-checkbox>
                          </el-checkbox-group>
                        </el-form-item>
                      </el-col>    

                      <br><br>

                      <el-col :span="24">
                        <el-form-item prop="discharge">
                          <el-checkbox-group v-model="formEstimate.discharge">
                            <el-checkbox value="true" name="discharge">
                              Si vous souhaitez une autre adresse de déchargement : <br>
                              <el-form-item v-if="formEstimate.discharge.length > 0">
                                <el-input v-model="formEstimate.address_discharge" placeholder="Votre adresse de déchargement" maxlength="255" />
                              </el-form-item>
                            </el-checkbox>
                          </el-checkbox-group>
                        </el-form-item>
                      </el-col>    

                      <br><br>
                    </div>
                    <div v-if="formEstimate.type === 'roundTrip' && formEstimate.passenger_nb == 0" class="roundTrip">
                      <el-form-item><b>Trajet RETOUR</b></el-form-item>
                      <br>
                      <div v-if="formEstimate.passenger_nb_return == 0">
                        <el-col :span="24">
                          <el-form-item prop="charge_return">
                            <el-checkbox-group v-model="formEstimate.charge_return">
                              <el-checkbox value="true" name="charge_return">
                                Si vous souhaitez une autre adresse de chargement : <br>
                                <el-form-item v-if="formEstimate.charge_return.length > 0">
                                  <el-input v-model="formEstimate.address_charge_return" placeholder="Votre adresse de chargement" maxlength="255" />
                                </el-form-item>
                              </el-checkbox>
                            </el-checkbox-group>
                          </el-form-item>
                        </el-col>    

                        <br><br>

                        <el-col :span="24">
                          <el-form-item prop="discharge_return">
                            <el-checkbox-group v-model="formEstimate.discharge_return">
                              <el-checkbox value="true" name="discharge_return">
                                Si vous souhaitez une autre adresse de déchargement : <br>
                                <el-form-item v-if="formEstimate.discharge_return.length > 0">
                                  <el-input v-model="formEstimate.address_discharge_return" placeholder="Votre adresse de déchargement" maxlength="255" />
                                </el-form-item>
                              </el-checkbox>
                            </el-checkbox-group>
                          </el-form-item>
                        </el-col>    

                        <br><br>
                      </div>
                    </div>
                    <el-col :span="24">
                      <el-checkbox-group v-model="formEstimate.insurance">
                        <el-checkbox value="true" name="insurance">
                          Assurance annulation
                        </el-checkbox>
                        <el-alert type="info" :closable="false">
                          Avec l'assurance annulation: Annulation et remboursement
                          intégral possible jusqu'à 48h avant le départ, hors coût
                          de l'assurance annulation qui représente {{insurance * 100}}% du billet
                          avec un minimum de 50 euros.
                        </el-alert>
                      </el-checkbox-group>
                    </el-col>
                    <br /><br />
                    <el-col :span="24">
                      <el-form-item>
                        <span style="color: #606266">Code promo</span>&nbsp;
                        <el-tooltip
                          content="Non cumulable"
                          placement="top"
                        >
                          <el-icon color="gray" size="20"><InfoFilled /></el-icon>
                        </el-tooltip>
                        <br>
                        <el-input type="text" v-model="formEstimate.code" maxlength="45" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Comment avez-vous entendu parler de nous ?">
                        <el-select
                          v-model="formEstimate.how"
                          placeholder="Sélectionner"
                          size="large"
                        >
                          <el-option
                            v-for="item in channels"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Commentaire">
                        <el-input
                          type="textarea"
                          v-model="formEstimate.comment"
                          maxlength="800"
                          show-word-limit 
                        />
                      </el-form-item>
                    </el-col>
                  </div>

                  <div v-if="stepEstimate == 5" style="font-size: 15px;">
                    <br>
                    <b>Veuillez vérifier vos informations saisies :</b>
                    <br>
                    <br>
                    <hr>
                    <br>
                    <br>
                    <b>Type de trajet : </b>{{ formEstimate.type == 'oneWay' ? 'Aller-simple' : 'Aller-retour' }}<br>
                    <br>
                    <b>Ville de départ : </b><span class="rose">{{ formEstimate.depart_city || 'Non renseigné'}}</span>
                    <br>
                    <b>Ville d'arrivée : </b><span class="rose">{{ formEstimate.arrival_city || 'Non renseigné'}}</span><br>
                    <br>
                    <b>Date de départ : </b>{{ formEstimate.depart_date }}<br>
                    <br>
                    <b>Nombre de motos : </b>{{formEstimate.moto_nb}}
                    <br>
                    <b>Nombre de voyageurs : </b>{{formEstimate.passenger_nb}}
                    <br>
                    <br>
                    <hr>
                    <br>
                    <b>Vos coordonnées de contact</b>
                    <br><br>
                    <b>Prénom : </b>{{formEstimate.firstname}}
                    <br>
                    <b>NOM : </b>{{formEstimate.lastname}}
                    <br>
                    <b>E-mail : </b>{{ formEstimate.email }}
                    <br>
                    <b>Téléphone : </b>{{formEstimate.phone}}
                    <br>
                    <br>
                    <hr v-if="formEstimate.moto_nb > 0">
                    <br v-if="formEstimate.moto_nb > 0">

                    <div v-if="formEstimate.moto_nb > 0">
                      <b>Modèle de chaque moto (facultatif)</b>
                      <br><br>
                      {{formEstimate.moto_list}}
                    </div>
                    <br v-if="formEstimate.passenger_nb > 0">
                    <hr v-if="formEstimate.passenger_nb > 0">
                    <br v-if="formEstimate.passenger_nb > 0">
                    <div
                      v-for="(passenger, index) in formEstimate.passenger_list"
                      :key="index"
                    >
                      <el-form-item
                        v-if="index === 0"
                        label="Liste des voyageurs :"
                      ></el-form-item>
                      <el-col :span="6">
                        <el-form-item>
                          <el-input type="text" v-model="passenger.firstname" placeholder="Prénom" disabled="false"/>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-input type="text" v-model="passenger.lastname" placeholder="NOM" disabled="false"/>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-input type="text" v-model="passenger.email" placeholder="E-mail" disabled="false"/>
                        </el-form-item>
                      </el-col>
                    </div>
                    <br>
                    <hr>
                    <br>
                    <div v-if="formEstimate.passenger_nb == 0">
                      <b>Adresse de chargement personnalisée : </b>{{ formEstimate.charge.length > 0 ? "Oui - " + (formEstimate.address_charge || "Adresse non renseignée") : "Non"}}<br>
                      <b>Adresse de déchargement personnalisée : </b>{{ formEstimate.discharge.length > 0 ? "Oui - " + (formEstimate.address_discharge || "Adresse non renseignée") : "Non"}}<br>
                    </div>
          
                    <div v-if="formEstimate.type != 'oneWay'" class="roundTrip">
                      <br>
                      <b>Trajet RETOUR</b><br>
                      <br>
                      <b>Date de retour : </b>{{ formEstimate.date_return }}<br>
                      <br>
                      <b>Ville de départ : </b><span class="rose">{{ formEstimate.depart_return || 'Non renseigné'}}</span>
                      <br>
                      <b>Ville d'arrivée : </b><span class="rose">{{ formEstimate.arrival_return || 'Non renseigné'}}</span><br>
                      <br>
                      <b>Nombre de motos : </b>{{formEstimate.moto_nb_return}}
                      <br>
                      <b>Nombre de voyageurs : </b>{{formEstimate.passenger_nb_return}}
                      <br>
                      <br>
                      <hr v-if="formEstimate.moto_nb_return > 0">
                      <br v-if="formEstimate.moto_nb_return > 0">

                      <div v-if="formEstimate.moto_nb_return > 0">
                        <b>Modèle de chaque moto (facultatif)</b>
                        <br><br>
                        {{formEstimate.moto_list_return}}
                      </div>
                      <br v-if="formEstimate.passenger_nb_return > 0">
                      <hr v-if="formEstimate.passenger_nb_return > 0">
                      <br v-if="formEstimate.passenger_nb_return > 0">
                      <div
                        v-for="(passenger, index) in formEstimate.passenger_list_return"
                        :key="index"
                      >
                        <el-form-item
                          v-if="index === 0"
                          label="Liste des voyageurs :"
                        ></el-form-item>
                        <el-col :span="6">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.firstname" placeholder="Prénom" disabled="false"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.lastname" placeholder="NOM" disabled="false"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item>
                            <el-input type="text" v-model="passenger.email" placeholder="E-mail" disabled="false"/>
                          </el-form-item>
                        </el-col>
                      </div>
                      <br>
                      <hr>
                      <br>
                      <div v-if="formEstimate.passenger_nb_return == 0">
                        <b>Adresse de chargement personnalisée : </b>{{ formEstimate.charge_return.length > 0 ? "Oui - " + (formEstimate.address_charge_return || "Adresse non renseignée") : "Non"}}<br>
                        <b>Adresse de déchargement personnalisée : </b>{{ formEstimate.discharge_return.length > 0 ? "Oui - " + (formEstimate.address_discharge_return || "Adresse non renseignée") : "Non"}}<br>
                      </div>
                    </div>
                    <b>Assurance annulation : </b>{{ formEstimate.insurance.length > 0 ? "Oui" : "Non"}}<br>
                    <br>
                    <b>Code Promo : </b>{{ formEstimate.code }}<br>
                    <br>
                    <b>Comment avez-vous entendu parler de nous ? </b>{{ formEstimate.how ? channels.find(item => item.id === formEstimate.how).name : '' }}<br>
                    <br>
                    <b>Commentaire : </b>
                    <br>{{formEstimate.comment}}
                    <br><br><hr><br><br>
                    <el-col :span="24"></el-col>
                    <el-col :span="24">
                      <el-form-item prop="conditionCheck">
                        <el-checkbox-group v-model="formEstimate.conditionCheck">
                          <el-checkbox value="true" name="conditionCheck">
                            <span style="color: red">*</span>Je reconnais avoir pris connaissance et accepté
                            <a
                              href="/condition"
                              target="_blank"
                              style="color: #e6007e; text-decoration: underline"
                              >les Conditions Générales de Ventes</a>.
                          </el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>

                    <el-col :span="24"></el-col>
                    <el-col :span="24"></el-col>
                    
                    <el-col :span='24'>
                      <el-form-item required prop='rgpd'>
                        <el-checkbox-group v-model="formEstimate.rgpd">
                          <el-checkbox value="true" name="rgpd">
                            <span style="color: red">*</span>Accord RGPD : En soumettant ce formulaire de contact, vous consentez à ce que nous recueillions et traitions vos données personnelles uniquement dans le but de répondre à votre demande. Vos informations ne seront pas utilisées à d'autres fins ni partagées avec des tiers sans votre consentement explicite. Pour en savoir plus sur notre politique de confidentialité et vos droits en matière de protection des données, veuillez consulter notre page dédiée à la vie privée.
                          </el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>

                    <el-col :span="24"></el-col>
                    <el-col :span="24"></el-col>

                    <el-col :span="24">
                      <el-form-item prop="subscriber">
                        <el-checkbox-group v-model="formEstimate.subscriber">
                          <el-checkbox value="true" name="subscriber">
                            S'abonner à nos actualités
                          </el-checkbox>
                        </el-checkbox-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24"></el-col>
                  </div>
                  
                  <div style="width: 100%; display: flex; justify-content: space-between">
                    <div v-if="stepEstimate == 0"></div>
                    <el-button v-if="stepEstimate > 0" @click="
                      [3, 4].includes(stepEstimate) ? 
                        !formEstimate.passenger_nb && !formEstimate.passenger_nb_return ?
                          !formEstimate.moto_nb && !formEstimate.moto_nb_return ? 
                            stepEstimate = stepEstimate - 3 : 
                          stepEstimate = stepEstimate - 2 :
                        !formEstimate.moto_nb && !formEstimate.moto_nb_return ? 
                          stepEstimate == 3 ? 
                            stepEstimate = stepEstimate - 2 : 
                          stepEstimate -- :
                        stepEstimate-- :
                      stepEstimate--
                    ">
                      Précédent
                    </el-button>
                    <el-button v-if="stepEstimate < 5" @click="nextStepEstimate" color="#000047" plain>Suivant</el-button>
                    <el-button v-if="stepEstimate == 5" @click="sendEstimate()" color="#E6007E">Envoyer la demande de devis</el-button>
                  </div>
                  <br>
                  <hr>
                  <br>
                  <div
                    style="
                      font-size: 12px;
                      color: #000047;
                      font-weight: 400;
                      text-align: center;
                    "
                  >
                    Ce site est protégé par reCAPTCHA et Google, les
                    <a href="https://policies.google.com/privacy" target="_blank" class="rose"><u>Règles de confidentialité</u></a> ainsi que les
                    <a href="https://policies.google.com/terms" target="_blank" class="rose"><u>Conditions d'utilisation</u></a> s'appliquent.
                  </div>
                  <br />
                </el-form>
              </div>

            </div>
          </div>
          <hr />
        </div>
        <div v-if="currentTab == 3">
          <div class="user-routes">
            <el-switch
              v-model="tmpVal"
              size="large"
              inline-prompt
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
              active-text="A venir"
              inactive-text="Réalisé"
            />
            <br />
            <div class="results">
              <div class="result-container">
                <div class="card-route">
                  <div class="card-route-header">
                    <div class="card-route-header-date">
                      {{ fullDateFR("20240124") }}
                    </div>
                    <div class="card-route-header-info">
                      <div
                        style="
                          padding: 5px 20px;
                          margin-right: 15px;
                          border-radius: 10px;
                          background: gray;
                          color: white;
                        "
                      >
                        Facture
                      </div>
                    </div>
                  </div>
                  <div class="card-route-info">
                    <div class="card-route-info-first">
                      <div class="card-route-info-route">
                        <b><span style="color: black">Paris</span></b>
                        <span class="material-symbols-outlined rose">east</span
                        ><b><span style="color: black">Lyon</span></b>
                      </div>
                      <div class="card-route-info-services">
                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise 220v"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prise 220v"
                              >power</span
                            >
                          </template>
                        </el-popover>

                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Prise USB"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Prises USB"
                              >usb</span
                            >
                          </template>
                        </el-popover>

                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Café"
                        >
                          <template #reference>
                            <span class="material-symbols-outlined" title="Café"
                              >coffee</span
                            >
                          </template>
                        </el-popover>

                        <el-popover
                          placement="top"
                          trigger="click"
                          content="Jeux de société"
                        >
                          <template #reference>
                            <span
                              class="material-symbols-outlined"
                              title="Jeux de société"
                              >playing_cards</span
                            >
                          </template>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span class="rose"><b>Moto(s):</b></span> 2 <br />
                    - test<br />
                    Test xxx <br /><br />
                    <span class="rose"><b>Passager(s):</b></span> 2 <br />
                    - Test TEST<br />
                    - <br /><br />
                    <span class="rose"><b>Assurance:</b></span> Non
                  </div>
                </div>
              </div>
            </div>
            <div class="footer">
              <div style="margin: 50px">
                <div
                  style="
                    font-size: 20px;
                    color: #e6007e;
                    font-weight: 400;
                    text-align: center;
                  "
                >
                  SUIVEZ-NOUS
                  <br />
                  <a
                    href="https://fr.linkedin.com/company/symone"
                    target="_blank"
                    ><i
                      class="fab fa-linkedin"
                      style="color: #000047; font-size: 20px"
                    ></i
                  ></a>
                  <a href="https://twitter.com/SymoneMobility" target="_blank"
                    ><i
                      class="fab fa-twitter"
                      style="color: #000047; font-size: 20px"
                    ></i
                  ></a>
                  <a
                    href="https://www.instagram.com/symonemobility/?hl=fr"
                    target="_blank"
                    ><i
                      class="fab fa-instagram"
                      style="color: #000047; font-size: 20px"
                    ></i
                  ></a>
                  <a
                    href="https://www.facebook.com/SymoneMobility/"
                    target="_blank"
                    ><i
                      class="fab fa-facebook"
                      style="color: #000047; font-size: 20px"
                    ></i
                  ></a>
                  <br />
                  <br />
                  NOUS CONTACTER
                  <br />
                  <span style="color: #000047"
                    >contact@symone.fr<br />06 17 33 43 93</span
                  >
                  <br />
                  <br />
                  ÉCRIVEZ-NOUS
                  <br />
                  <a href="/contact" target="_blanc"
                    ><span style="color: #000047">Contact</span></a
                  >
                </div>
                <div
                  style="
                    font-size: 20px;
                    color: #000047;
                    font-weight: 400;
                    text-align: center;
                  "
                >
                  <br />
                  <a href="/" target="_blank">Bienvenue à bord</a>
                  <br /><br />
                  <a href="/moto" target="_blank">Symone à moto</a>
                  <br /><br />
                  <a href="/#home-tag-2" target="_blank"
                    >Une nouvelle expérience de la route</a
                  >
                  <br /><br />
                  <a href="/condition" target="_blank"
                    >Conditions Générales de Vente</a
                  >
                  <br />
                </div>
              </div>
              <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
            </div>
          </div>
        </div>
      </div>
        <div>
          <div class="about2" id="about2">
            <div class="title border">
              <span style="color: #e6007e">U</span>n voyage, un roadtrip, un
              circuit ?
              <br />
              Symone vous transporte avec votre moto aux portes de vos terrains
              de jeux
            </div>
            <div
              style="
                max-width: 1000px;
                margin-top: 60px;
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
              "
            >
              <video
                id="videoSymoneMoto"
                style="border-radius: 10px; width: 40%"
                src="../assets/videos/Publicite-Symone-moto-V12.mp4"
                autoplay="autoplay"
                loop="loop"
                muted=""
                controls="controls"
              ></video>
              <div style="width: 70%; margin: 20px">
                <div
                  class="title"
                  style="text-align-last: auto; font-size: 40px"
                >
                  <span style="color: #e6007e">P</span>ratique
                </div>
                <div
                  class="title"
                  style="text-align-last: auto; font-size: 20px; line-height: 1"
                >
                  En amont du réseau autoroutier, votre moto est chargée et
                  amarrée en toute sécurité sur une remorque spécialement
                  aménagée pour le transport de motos. Ensuite installez-vous
                  confortablement et profitez du voyage !
                </div>
              </div>
            </div>
            <div
              style="
                max-width: 1000px;
                flex-direction: row-reverse;
                margin-top: 60px;
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
              "
            >
              <img
                src="../assets/images/symonemoto4-scaled-e1693995436192.jpg"
                style="border-radius: 10px; max-width: 40%; max-height: 250px"
              />
              <div style="width: 65%; margin: 20px">
                <div
                  class="title"
                  style="text-align: center; font-size: 40px"
                >
                  <span style="color: #e6007e">U</span>ne expérience inédite
                </div>
                <div
                  class="title"
                  style="
                    text-align-last: right;
                    font-size: 20px;
                    line-height: 1;
                  "
                >
                  Vous voyagez dans un van tout confort conduit par un
                  professionnel. Vous arrivez à destination en toute sécurité,
                  de manière plus décarbonée et en profitant enfin de toutes ces
                  longues heures sur l'autoroute que vous passiez derrière le
                  guidon.
                </div>
              </div>
            </div>
            <div
              style="
                max-width: 1000px;
                margin-top: 60px;
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
              "
            >
              <img
                src="../assets/images/symonemoto1-scaled.jpg"
                style="border-radius: 10px; max-width: 40%; max-height: 250px"
              />
              <div style="width: 65%; margin: 20px">
                <div
                  class="title"
                  style="text-align-last: auto; font-size: 40px"
                >
                  <span style="color: #e6007e">U</span>n service pour tous
                </div>
                <div
                  class="title"
                  style="text-align-last: auto; font-size: 20px; line-height: 1"
                >
                  À l'arrivée, récupérez instantanément votre moto et
                  faites-vous plaisir sur les plus belles routes. Symone propose
                  ses services en journée et la nuit, pour les professionnels et
                  les particuliers. Votre moto est assurée pendant toute la
                  durée du trajet en Symone.
                </div>
              </div>
            </div>
          </div>
        </div>
              <div class="footer">
                <div style="margin: 50px">
                  <div
                    style="
                      font-size: 20px;
                      color: #e6007e;
                      font-weight: 400;
                      text-align: center;
                    "
                  >
                    SUIVEZ-NOUS
                    <br />
                    <a
                      href="https://fr.linkedin.com/company/symone"
                      target="_blank"
                      ><i
                        class="fab fa-linkedin"
                        style="color: #000047; font-size: 20px"
                      ></i
                    ></a>
                    <a href="https://twitter.com/SymoneMobility" target="_blank"
                      ><i
                        class="fab fa-twitter"
                        style="color: #000047; font-size: 20px"
                      ></i
                    ></a>
                    <a
                      href="https://www.instagram.com/symonemobility/?hl=fr"
                      target="_blank"
                      ><i
                        class="fab fa-instagram"
                        style="color: #000047; font-size: 20px"
                      ></i
                    ></a>
                    <a
                      href="https://www.facebook.com/SymoneMobility/"
                      target="_blank"
                      ><i
                        class="fab fa-facebook"
                        style="color: #000047; font-size: 20px"
                      ></i
                    ></a>
                    <br />
                    <br />
                    NOUS CONTACTER
                    <br />
                    <span style="color: #000047"
                      >contact@symone.fr<br />06 17 33 43 93</span
                    >
                    <br />
                    <br />
                    ÉCRIVEZ-NOUS
                    <br />
                    <a href="/contact" target="_blanc"
                      ><span style="color: #000047">Contact</span></a
                    >
                  </div>
                  <div
                    style="
                      font-size: 20px;
                      color: #000047;
                      font-weight: 400;
                      text-align: center;
                    "
                  >
                    <br />
                    <a href="/" target="_blank">Bienvenue à bord</a>
                    <br /><br />
                    <a href="/moto" target="_blank">Symone à moto</a>
                    <br /><br />
                    <a href="/#home-tag-2" target="_blank"
                      >Une nouvelle expérience de la route</a
                    >
                    <br /><br />
                    <a href="/condition" target="_blank"
                      >Conditions Générales de Vente</a
                    >
                    <br />
                  </div>
                </div>
                <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
                <br>
                <div
                    style="
                      font-size: 15px;
                      color: #000047;
                      font-weight: 400;
                      text-align: center;
                    "
                  >
                  Ce site est protégé par reCAPTCHA et Google, les
                  <a href="https://policies.google.com/privacy" target="_blank" class="rose"><u>Règles de confidentialité</u></a> ainsi que les
                  <a href="https://policies.google.com/terms" target="_blank" class="rose"><u>Conditions d'utilisation</u></a> s'appliquent.
                </div>
              </div>
    </div>
    <el-dialog center :title="formReservation.price_unit > 0 ? 'Réservation' : 'Pré-réservation'" color="white" v-model="showReservationPopup" :close-on-click-modal="false">
      <el-form
        ref="ruleReservationRef"
        style="max-width: 600px; width: 100%; padding: 50px 50px 0 50px"
        :model="formReservation"
        :rules="rulesReservation"
        label-width="auto"
        label-position="top"
        status-icon
        scroll-to-error
        v-loading="isReservationSending"
        id="formReservationTop"
      >
        <el-steps style="max-width: 600px; width: 100%;" :active="stepReservation" finish-status="success" align-center>
          <el-step v-if="stepReservation > 0" title="Trajet" @click="stepReservation = 0" class="clickable" />
          <el-step v-else title="Trajet" />
          <el-step v-if="stepReservation > 1" title="Contact" @click="stepReservation = 1" class="clickable" />
          <el-step v-else title="Contact" />
          <el-step v-if="stepReservation > 2" title="Moto" @click="stepReservation = 2" class="clickable" />
          <el-step v-else title="Moto" />
          <el-step v-if="stepReservation > 3" title="Voyageur" @click="stepReservation = 3" class="clickable" />
          <el-step v-else title="Voyageur" />
          <el-step v-if="stepReservation > 4" title="Options" @click="stepReservation = 4" class="clickable" />
          <el-step v-else title="Options" />
          <el-step title="Récapitulatif" />
        </el-steps>
        <br><br><br>
        <div v-if="stepReservation == 0">
          <el-col :span="12">
            <el-form-item v-if="formReservation.price_unit > 0" label="Ville de départ" prop="depart">
              <Autocomplete
                :key="refreshAutocomplete"
                :disabled="formReservation.depart ? true : false"
                v-model="formReservation.depart"
              ></Autocomplete>
              <a
                v-if="formReservation.address_depart"
                :title="formReservation.address_depart"
                :href="`https://www.google.com/maps?q=${formReservation.address_depart}`"
                target="_blank"
              ><u
                  ><b>Adresse de départ</b></u
                >
              </a>
            </el-form-item>
            <el-form-item v-else label="Ville de départ">
              <Autocomplete
                :key="refreshAutocomplete"
                :disabled="formReservation.depart ? true : false"
                v-model="formReservation.depart"
              ></Autocomplete>
              <a
                v-if="formReservation.address_depart"
                :title="formReservation.address_depart"
                :href="`https://www.google.com/maps?q=${formReservation.address_depart}`"
                target="_blank"
              ><u
                  ><b>Adresse de départ</b></u
                >
              </a>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="formReservation.price_unit > 0" label="Ville d'arrivée" prop="arrival">
              <Autocomplete
                :key="refreshAutocomplete"
                :disabled="formReservation.arrival ? true : false"
                v-model="formReservation.arrival"
              ></Autocomplete>
              <a
                v-if="formReservation.address_arrival"
                :title="formReservation.address_arrival"
                :href="`https://www.google.com/maps?q=${formReservation.address_arrival}`"
                target="_blank"
              ><u
                  ><b>Adresse d'arrivée</b></u
                >
              </a>
            </el-form-item>
            <el-form-item v-else label="Ville d'arrivée">
              <Autocomplete
                :key="refreshAutocomplete"
                :disabled="formReservation.arrival ? true : false"
                v-model="formReservation.arrival"
              ></Autocomplete>
              <a
                v-if="formReservation.address_arrival"
                :title="formReservation.address_arrival"
                :href="`https://www.google.com/maps?q=${formReservation.address_arrival}`"
                target="_blank"
              ><u
                  ><b>Adresse d'arrivée</b></u
                >
              </a>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="En cas de problème de saisie, veuillez indiquer la ville dans le commentaire en étape 5 : Options."></el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Date de départ" required prop="date_depart">
              <el-input
                v-model="formReservation.date_depart"
                type="text"
                placeholder="Date de départ"
                style="width: 100%"
                :disabled="formReservation.date_depart ? true : false"
              />
            </el-form-item>
          </el-col>
          <br><br>
          <el-col :span="12">
            <el-form-item label="Prix TTC par moto (0~1 voyageur)" required>
              <el-input
                type="text"
                v-model="formReservation.price"
                :disabled="true"
              />
              {{ formReservation.price_unit > 0 ? (formReservation.passenger_nb > 0 ? (formReservation.price_unit / 1.1).toFixed(2) + '€ HT' : (formReservation.price_unit / 1.2).toFixed(2) + '€ HT') : '' }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Prix si voyageur supplémentaire" required>
              <el-input
                type="text"
                v-model="formReservation.price_passenger"
                :disabled="true"
              />
              {{ formReservation.price_unit > 0 ? (formReservation.passenger_nb > 0 ? (formReservation.price_unit * (formReservation.price_passenger_rate / 100) / 1.1).toFixed(2) + '€ HT' : (formReservation.price_unit * (formReservation.price_passenger_rate / 100) / 1.2).toFixed(2) + '€ HT') : '' }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item 
              :label="`Nombre de motos`" 
              required 
              prop="moto_nb"
            >
              <el-input
                type="text"
                v-model.number="formReservation.moto_nb"
                @input="generateMotoListForReservation"
              />
              <div style="text-align: right; width: 100%">Il reste &nbsp;<b><span style="color:#e6007e">{{formReservation.places_moto - formReservation.moto_nb}}</span></b>&nbsp; place(s) disponible(s)</div>
            </el-form-item><br>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="`Nombre de voyageurs`"
              required
              prop="passenger_nb"
            >
              <el-input
                type="text"
                v-model.number="formReservation.passenger_nb"
                @input="generatePassengerListForReservation"
              />
              <div style="text-align: right; width: 100%">Il reste &nbsp;<b><span style="color:#e6007e">{{formReservation.places_passenger - formReservation.passenger_nb}}</span></b>&nbsp; place(s) disponible(s)</div>
            </el-form-item>
          </el-col>
        </div>

        <div v-if="stepReservation == 1">
          <el-form-item label="Vos coordonnées de contact">
            <el-col :span="12">
              <el-form-item label="Prénom" required prop="firstname">
                <el-input type="text" v-model="formReservation.firstname" />
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Nom" required prop="lastname">
                <el-input
                  type="text"
                  v-model="formReservation.lastname"
                  required
                />
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="E-mail" required prop="email">
                <el-input type="email" v-model="formReservation.email"/>
              </el-form-item><br>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Téléphone" required prop="phone">
                <el-input type="text" v-model="formReservation.phone" />
              </el-form-item><br>
            </el-col>
          </el-form-item>
        </div>

        <div v-if="stepReservation == 2 && formReservation.price_unit > 0">
          <div
            v-for="(moto, index) in formReservation.moto_list"
            :key="index"
          >
            <el-form-item
              v-if="index === 0"
              label="Liste des motos :"
              class="required-label"
            ></el-form-item>
            <el-col :span="24">
              <el-form-item :prop="`moto_list[${index}].model`">
                <el-input type="text" v-model="moto.model" placeholder="Modèle" />
              </el-form-item>
            </el-col>
          </div>
        </div>
        <div v-if="stepReservation == 2 && formReservation.price_unit == 0">
          <el-col :span="24">
            <el-form-item label="Modèle de chaque moto">
              <el-input
                type="textarea"
                v-model="formReservation.moto_list_estimate"
              />
            </el-form-item>
          </el-col>
        </div>

        <div v-if="stepReservation == 3">
          <div
            v-for="(passenger, index) in formReservation.passenger_list"
            :key="index"
          >
            <el-form-item
              v-if="index === 0"
              :label="formReservation.price_unit > 0 ? 'Liste des voyageurs :' : 'Liste des voyageurs (facultatif) :'"
            ></el-form-item>
            <el-col :span="6">
              <el-form-item v-if="formReservation.price_unit > 0" :prop="`passenger_list[${index}].firstname`">
                <el-input type="text" v-model="passenger.firstname" placeholder="Prénom"/>
              </el-form-item>
              <el-form-item v-else>
                <el-input type="text" v-model="passenger.firstname" placeholder="Prénom"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item v-if="formReservation.price_unit > 0" :prop="`passenger_list[${index}].lastname`">
                <el-input type="text" v-model="passenger.lastname" placeholder="NOM"/>
              </el-form-item>
              <el-form-item v-else>
                <el-input type="text" v-model="passenger.lastname" placeholder="NOM"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item v-if="formReservation.price_unit > 0" :prop="`passenger_list[${index}].email`">
                <el-input type="text" v-model="passenger.email" placeholder="E-mail"/>
              </el-form-item>
              <el-form-item v-else>
                <el-input type="text" v-model="passenger.email" placeholder="E-mail"/>
              </el-form-item>
            </el-col>
          </div>
        </div>

        <div v-if="stepReservation == 4">
          <div v-if="formReservation.passenger_nb == 0">
            <el-col :span="24">
              <el-form-item prop="charge">
                <el-checkbox-group v-model="formReservation.charge">
                  <el-checkbox value="true" name="charge">
                    Si vous souhaitez une autre adresse de chargement : 
                    <span class="rose">à partir de {{ charge }}€ TTC</span><br>
                    <el-form-item v-if="formReservation.charge.length > 0" prop="address_charge">
                      <el-input v-model="formReservation.address_charge" placeholder="Votre adresse de chargement"/>
                    </el-form-item>
                    <a
                      v-if="formReservation.address_depart"
                      :title="formReservation.address_depart"
                      :href="`https://www.google.com/maps?q=${formReservation.address_depart}`"
                      target="_blank"
                    ><u
                        ><b>Adresse prévue</b></u
                      >
                    </a>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>    

            <br><br>

            <el-col :span="24">
              <el-form-item prop="discharge">
                <el-checkbox-group v-model="formReservation.discharge">
                  <el-checkbox value="true" name="discharge">
                    Si vous souhaitez une autre adresse de déchargement : 
                    <span class="rose">à partir de {{ charge }}€ TTC</span><br>
                    <el-form-item v-if="formReservation.discharge.length > 0" prop="address_discharge">
                      <el-input v-model="formReservation.address_discharge" placeholder="Votre adresse de déchargement"/>
                    </el-form-item>
                    <a
                      v-if="formReservation.address_arrival"
                      :title="formReservation.address_arrival"
                      :href="`https://www.google.com/maps?q=${formReservation.address_arrival}`"
                      target="_blank"
                    ><u
                        ><b>Adresse prévue</b></u
                      >
                    </a>
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>  
          </div>  

          <br>

          <el-col :span="24">
            <el-form-item prop="insurance">
              <el-checkbox-group v-model="formReservation.insurance">
                <el-checkbox v-if="formReservation.price_unit > 0" value="true" name="insuranceReservation">
                  Assurance annulation : <span class="rose"><b>{{ formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * insurance) }}€ TTC</b></span>
                </el-checkbox>
                <el-checkbox v-else value="true" name="insuranceReservation">
                  Assurance annulation : 
                </el-checkbox>
                <el-alert type="info" :closable="false">
                  Avec l'assurance annulation: Annulation et remboursement intégral
                  possible jusqu'à 48h avant le départ, hors coût de l'assurance
                  annulation qui représente {{insurance * 100}}% du billet avec un minimum de 50
                  euros.
                </el-alert>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <br /><br />
          
          <el-col :span="24">
            <el-form-item prop="code">
              <span style="color: #606266">Code promo</span>&nbsp;
              <el-tooltip
                content="Non cumulable"
                placement="top"
              >
                <el-icon color="gray" size="20"><InfoFilled /></el-icon>
              </el-tooltip>
              <br>
              <el-input type="text" v-model="formReservation.code" maxlength="45" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Comment avez-vous entendu parler de nous ?">
              <el-select
                v-model="formReservation.how"
                placeholder="Sélectionner"
                size="large"
              >
                <el-option
                  v-for="item in channels"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Commentaire">
              <el-input type="textarea" v-model="formReservation.comment" />
            </el-form-item>
          </el-col>
          <el-col :span="24"></el-col>
          <div v-if="formReservation.price_unit > 0">
            <hr v-if="formReservation.price_unit > 0">
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"><b>Votre transport (Prix HT/TTC) :</b></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <div class="price-details">
              <el-col v-if="formReservation.price_unit > 0 && formReservation.code && formReservation.checkCode" :span="24">Code promo <b>{{ formReservation.code }}</b> appliqué</el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.code && formReservation.checkCode" :span="24"></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.moto_nb > 0" :span="24" style="font-size: 15px;">- Moto <b>x{{formReservation.moto_nb}}</b> = {{(parseFloat(formReservation.price_unit) * formReservation.moto_nb / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{(parseFloat(formReservation.price_unit) * formReservation.moto_nb).toFixed(2) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.passenger_nb > 0" :span="24" style="font-size: 15px;">- Voyageur <b>x{{formReservation.passenger_nb}}</b> = {{formReservation.passenger_nb <= formReservation.moto_nb ? "0" : parseFloat(formReservation.price_passenger / 1.1 * (formReservation.passenger_nb - formReservation.moto_nb)).toFixed(2) }}€ / <b>{{formReservation.passenger_nb <= formReservation.moto_nb ? "0" : parseFloat(formReservation.price_passenger * (formReservation.passenger_nb - formReservation.moto_nb)).toFixed(2) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.insurance.length > 0" :span="24" style="font-size: 15px;">- Assurance annulation = {{ formReservation.total <= 500 ? parseFloat(50 / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) : Math.floor(formReservation.total * insurance / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)) }}€ / <b>{{ formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * insurance) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.charge.length > 0" :span="24" style="font-size: 15px;">- Adresse de chargement personnalisée = {{ parseFloat(charge / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ charge }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.discharge.length > 0" :span="24" style="font-size: 15px;">- Adresse de déchargement personnalisée = {{ parseFloat(charge / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ charge }}€</b></el-col>
            </div>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24" style="color: #e6007e; font-size: 20px; text-align: right;"><b>
              Total : {{ parseFloat((parseFloat(formReservation.total) + 
                (formReservation.insurance.length > 0 ? 
                  formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * insurance)
                : 0) + 
                (formReservation.charge.length > 0 ? charge : 0) +
                (formReservation.discharge.length > 0 ? charge : 0)) / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ HT / {{ parseFloat(parseFloat(formReservation.total) + 
                (formReservation.insurance.length > 0 ? 
                  formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * insurance)
                : 0) + 
                (formReservation.charge.length > 0 ? charge : 0) +
                (formReservation.discharge.length > 0 ? charge : 0)).toFixed(2) }}€ TTC</b></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col :span="24"></el-col>
          </div>
        </div>
        
        <div v-if="stepReservation == 5">
          <el-timeline
            style="
              width: 200px;
              background: white;
              border-radius: 20px;
            "
          >
            <div class="timeline-bloc" v-if="formReservation.row.time0">
              <div class="time">
                {{
                  formatTimestamp(formReservation.row.time0, formReservation.row.places_moto_1)
                }}
              </div>
              <el-timeline-item color="#44bbc3" size="large">
                <div>
                  Début chargement
                  {{
                    formReservation.row.places_moto_1 <= 3
                      ? "30"
                      : "15"
                  }}
                  minutes avant le départ
                </div>
              </el-timeline-item>
            </div>
            <div class="timeline-bloc" v-if="formReservation.row.depart">
              <div v-if="formReservation.row.time0" class="time">
                {{ formReservation.row.time0 }}
              </div>
              <div v-else class="time white">00:00</div>
              <el-timeline-item color="black" size="large">
                <a
                  v-if="formReservation.row.address_depart"
                  :title="formReservation.row.address_depart"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_depart}`"
                  target="_blank"
                  ><u
                    ><b>{{ formReservation.row.depart.split(",")[0] }}</b></u
                  ></a
                >
                <div v-else>
                  <b>{{ formReservation.row.depart.split(",")[0] }}</b>
                </div>

                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_depart"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>
            <div class="timeline-bloc" v-if="formReservation.row.layover1">
              <div v-if="formReservation.row.time1" class="time">
                {{ formReservation.row.time1 }}
              </div>
              <div v-else class="time white">00:00</div>
              <el-timeline-item color="#E6007E" size="normal">
                <a
                  v-if="formReservation.row.address_layover1"
                  :title="formReservation.row.address_layover1"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_layover1}`"
                  target="_blank"
                >
                  <span class="rose"
                    ><u
                      ><b>{{
                        formReservation.row.layover1.split(",")[0]
                      }}</b></u
                    ></span
                  ></a
                >
                <div v-else>
                  <span class="rose"
                    ><b>{{
                      formReservation.row.layover1.split(",")[0]
                    }}</b></span
                  >
                </div>
                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_layover1"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>

            <div class="timeline-bloc" v-if="formReservation.row.layover2">
              <div v-if="formReservation.row.time2" class="time">
                {{ formReservation.row.time2 }}
              </div>
              <div v-else class="time white">00:00</div>
              <el-timeline-item color="#E6007E" size="normal"
                ><a
                  v-if="formReservation.row.address_layover2"
                  :title="formReservation.row.address_layover2"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_layover2}`"
                  target="_blank"
                >
                  <span class="rose"
                    ><u
                      ><b>{{
                        formReservation.row.layover2.split(",")[0]
                      }}</b></u
                    ></span
                  ></a
                >
                <div v-else>
                  <span class="rose"
                    ><b>{{
                      formReservation.row.layover2.split(",")[0]
                    }}</b></span
                  >
                </div>
                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_layover2"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>
            <div class="timeline-bloc" v-if="formReservation.row.layover3">
              <div v-if="formReservation.row.time3" class="time">
                {{ formReservation.row.time3 }}
              </div>
              <div v-else class="time white">00:00</div>
              <el-timeline-item color="#E6007E" size="normal"
                ><a
                  v-if="formReservation.row.address_layover3"
                  :title="formReservation.row.address_layover3"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_layover3}`"
                  target="_blank"
                >
                  <span class="rose"
                    ><u
                      ><b>{{
                        formReservation.row.layover3.split(",")[0]
                      }}</b></u
                    ></span
                  >
                </a>
                <div v-else>
                  <span class="rose"
                    ><b>{{
                      formReservation.row.layover3.split(",")[0]
                    }}</b></span
                  >
                </div>
                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_layover3"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>
            <div class="timeline-bloc" v-if="formReservation.row.arrival">
              <div v-if="formReservation.row.time4" class="time">
                {{ formReservation.row.time4 }}
              </div>
              <div v-else class="time white">00:00</div>

              <el-timeline-item color="black" size="large">
                <a
                  v-if="formReservation.row.address_arrival"
                  :title="formReservation.row.address_arrival"
                  :href="`https://www.google.com/maps?q=${formReservation.row.address_arrival}`"
                  target="_blank"
                >
                  <u
                    ><b>{{ formReservation.row.arrival.split(",")[0] }}</b></u
                  ></a
                >
                <div v-else>
                  <b>{{ formReservation.row.arrival.split(",")[0] }}</b>
                </div>

                <div
                  class="timeline-comment"
                  v-if="formReservation.row.address_arrival"
                >
                  Lien cliquable adresse
                </div>
              </el-timeline-item>
            </div>
          </el-timeline>
          <br>
          Veuillez vérifier vos informations saisies :
          <br>
          <br>
          <hr>
          <br>
          <br>
          <b>Ville de départ : </b><span class="rose">{{ formReservation.depart || 'Non renseigné' }}</span>
          <br>
          <b>Ville d'arrivée : </b><span class="rose">{{ formReservation.arrival || 'Non renseigné' }}</span><br>
          <br>
          <b>Date de départ : </b>{{ formReservation.date_depart }}<br>
          <br>
          <b>Prix TTC par moto (0~1 voyageur) </b>: {{ formReservation.price_unit > 0 ?  formReservation.price_unit : formReservation.price }}
          <br>
          <b>Prix si voyageur supplémentaire : </b>{{ formReservation.price_passenger }}<br>
          <br>
          <b>Nombre de motos : </b>{{formReservation.moto_nb}}
          <br>
          <b>Nombre de voyageurs : </b>{{formReservation.passenger_nb}}
          <br>
          <br>
          <hr>
          <br>
          <b>Vos coordonnées de contact</b>
          <br><br>
          <b>Prénom : </b>{{formReservation.firstname}}
          <br>
          <b>NOM : </b>{{formReservation.lastname}}
          <br>
          <b>E-mail : </b>{{ formReservation.email }}
          <br>
          <b>Téléphone : </b>{{formReservation.phone}}
          <br>
          <br>
          <hr v-if="formReservation.moto_nb > 0">
          <br v-if="formReservation.moto_nb > 0">

          <div v-if="formReservation.moto_nb > 0 && formReservation.price_unit > 0">
            <div
              v-for="(moto, index) in formReservation.moto_list"
              :key="index"
            >
              <el-form-item
                v-if="index === 0"
                label="Liste des motos :"
              ></el-form-item>
              <el-col :span="24">
                <el-form-item props="model">
                  <el-input type="text" v-model="moto.model" placeholder="Modèle" disabled="false"/>
                </el-form-item>
              </el-col>
            </div>
          </div>
          <div v-if="formReservation.moto_nb > 0 && formReservation.price_unit == 0">
            Modèle de chaque moto (facultatif)
            <br><br>
            {{formReservation.moto_list_estimate}}
          </div>
          <br v-if="formReservation.passenger_nb > 0">
          <hr v-if="formReservation.passenger_nb > 0">
          <br v-if="formReservation.passenger_nb > 0">
          <div
            v-for="(passenger, index) in formReservation.passenger_list"
            :key="index"
          >
            <el-form-item
              v-if="index === 0"
              label="Liste des voyageurs :"
            ></el-form-item>
            <el-col :span="6">
              <el-form-item>
                <el-input type="text" v-model="passenger.firstname" placeholder="Prénom" disabled="false"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item>
                <el-input type="text" v-model="passenger.lastname" placeholder="NOM" disabled="false"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-input type="text" v-model="passenger.email" placeholder="E-mail" disabled="false"/>
              </el-form-item>
            </el-col>
          </div>
          <br>
          <hr>
          <br>
          <b>Assurance annulation : </b>{{ formReservation.insurance.length > 0 ? "Oui" : "Non"}}<br>
          <b>Adresse de chargement personnalisée : </b>{{ formReservation.charge.length > 0 ? "Oui - " + (formReservation.address_charge || "Adresse non renseignée") : "Non"}}<br>
          <b>Adresse de déchargement personnalisée : </b>{{ formReservation.discharge.length > 0 ? "Oui - " + (formReservation.address_discharge || "Adresse non renseignée") : "Non"}}<br>
          <br>
          <b>Code Promo : </b>{{ formReservation.code }}
          <br>
          <b>Comment avez-vous entendu parler de nous ? </b>{{ formReservation.how ? channels.find(item => item.id === formReservation.how).name : '' }}
          <br>
          <b>Commentaire : </b>
          <br>{{formReservation.comment}}
          <br><br><hr><br><br>
          <div v-if="formReservation.price_unit > 0">
            <el-col v-if="formReservation.price_unit > 0" :span="24"><b>Votre transport (Prix HT/TTC) :</b></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <div class="price-details">
              <el-col v-if="formReservation.price_unit > 0 && formReservation.code && formReservation.checkCode" :span="24">Code promo <b>{{ formReservation.code }}</b> appliqué</el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.code && formReservation.checkCode" :span="24"></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.moto_nb > 0" :span="24" style="font-size: 15px;">- Moto <b>x{{formReservation.moto_nb}}</b> = {{(parseFloat(formReservation.price_unit) * formReservation.moto_nb / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{(parseFloat(formReservation.price_unit) * formReservation.moto_nb).toFixed(2) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.passenger_nb > 0" :span="24" style="font-size: 15px;">- Voyageur <b>x{{formReservation.passenger_nb}}</b> = {{formReservation.passenger_nb <= formReservation.moto_nb ? "0" : parseFloat(formReservation.price_passenger / 1.1 * (formReservation.passenger_nb - formReservation.moto_nb)).toFixed(2) }}€ / <b>{{formReservation.passenger_nb <= formReservation.moto_nb ? "0" : parseFloat(formReservation.price_passenger * (formReservation.passenger_nb - formReservation.moto_nb)).toFixed(2) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.insurance.length > 0" :span="24" style="font-size: 15px;">- Assurance annulation = {{ formReservation.total <= 500 ? parseFloat(50 / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) : Math.floor(formReservation.total * insurance / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)) }}€ / <b>{{ formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * insurance) }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.charge.length > 0" :span="24" style="font-size: 15px;">- Adresse de chargement personnalisée = {{ parseFloat(charge / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ charge }}€</b></el-col>
              <el-col v-if="formReservation.price_unit > 0 && formReservation.discharge.length > 0" :span="24" style="font-size: 15px;">- Adresse de déchargement personnalisée = {{ parseFloat(charge / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ / <b>{{ charge }}€</b></el-col>
            </div>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24" style="color: #e6007e; font-size: 20px; text-align: right;"><b>
              Total : {{ parseFloat((parseFloat(formReservation.total) + 
                (formReservation.insurance.length > 0 ? 
                  formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * insurance)
                : 0) + 
                (formReservation.charge.length > 0 ? charge : 0) +
                (formReservation.discharge.length > 0 ? charge : 0)) / (formReservation.passenger_nb > 0 ? 1.1 : 1.2)).toFixed(2) }}€ HT / {{ parseFloat(parseFloat(formReservation.total) + 
                (formReservation.insurance.length > 0 ? 
                  formReservation.total <= 500 ? 50 : Math.floor(formReservation.total * insurance)
                : 0) + 
                (formReservation.charge.length > 0 ? charge : 0) +
                (formReservation.discharge.length > 0 ? charge : 0)).toFixed(2) }}€ TTC</b></el-col>
            <el-col v-if="formReservation.price_unit > 0" :span="24"></el-col>
            <el-col :span="24"></el-col>
            <el-col :span="24"></el-col>
          </div>

          <el-col :span="24">
            <el-form-item prop="conditionCheck">
              <el-checkbox-group v-model="formReservation.conditionCheck">
                <el-checkbox value="true" name="conditionCheck">
                  <span style="color: red">*</span>Je reconnais avoir pris connaissance et accepté
                  <a
                    href="/condition"
                    target="_blank"
                    style="color: #e6007e; text-decoration: underline"
                    >les Conditions Générales de Ventes</a
                  >.
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>

          <el-col :span='24'></el-col>
          <el-col :span="24"></el-col>
          
          <el-col :span='24'>
            <el-form-item required prop='rgpd'>
              <el-checkbox-group v-model="formReservation.rgpd">
                <el-checkbox value="true" name="rgpd">
                  <span style="color: red">*</span>Accord RGPD : En soumettant ce formulaire de contact, vous consentez à ce que nous recueillions et traitions vos données personnelles uniquement dans le but de répondre à votre demande. Vos informations ne seront pas utilisées à d'autres fins ni partagées avec des tiers sans votre consentement explicite. Pour en savoir plus sur notre politique de confidentialité et vos droits en matière de protection des données, veuillez consulter notre page dédiée à la vie privée.
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>

          <el-col :span='24'></el-col>
          <el-col :span="24"></el-col>

          <el-col :span="24">
            <el-form-item prop="subscriber">
              <el-checkbox-group v-model="formReservation.subscriber">
                <el-checkbox value="true" name="subscriber">
                  S'abonner à nos actualités
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24"></el-col>
        </div>
        
        <div style="width: 100%; display: flex; justify-content: space-between">
          <div v-if="stepReservation == 0"></div>
          <el-button v-if="stepReservation > 0" @click="
            [3, 4].includes(stepReservation) ? 
              formReservation.passenger_nb == 0 ?
                formReservation.moto_nb == 0 ? 
                  stepReservation = stepReservation - 3 : 
                stepReservation = stepReservation - 2 :
              formReservation.moto_nb == 0 ? 
                stepReservation == 3 ? 
                  stepReservation = stepReservation - 2 : 
                stepReservation -- :
              stepReservation-- :
            stepReservation--
          ">
            Précédent
          </el-button>
          <el-button v-if="stepReservation < 5" @click="nextStep" color="#000047" plain>Suivant</el-button>
          <el-button v-if="stepReservation == 5 && formReservation.price_unit > 0 && formReservation.charge.length == 0 && formReservation.discharge.length == 0" @click="sendReservation()" color="#E6007E">Payer pour valider la réservation</el-button>
          <el-button v-if="(stepReservation == 5 && formReservation.price_unit == 0) || (stepReservation == 5 && formReservation.charge.length > 0) || (stepReservation == 5 && formReservation.discharge.length > 0)" @click="sendReservation()" color="#E6007E">Envoyer la demande de devis</el-button>
        </div>
        <br>
        <hr>
        <br>
        <div
          style="
            font-size: 12px;
            color: #000047;
            font-weight: 400;
            text-align: center;
          "
        >
          Ce site est protégé par reCAPTCHA et Google, les
          <a href="https://policies.google.com/privacy" target="_blank" class="rose"><u>Règles de confidentialité</u></a> ainsi que les
          <a href="https://policies.google.com/terms" target="_blank" class="rose"><u>Conditions d'utilisation</u></a> s'appliquent.
        </div>
        <br />
      </el-form>
    </el-dialog>

    <el-dialog center title="Comment ça fonctionne ?" v-model="showHelpPopup">
      <div class="route-how">
        <br />
        <p></p>
        <p>
          <strong
            >Sur un trajet déjà défini, n'hésitez pas à faire une demande si
            vous souhaitez un autre point de chargement et/ou de
            déchargement</strong
          >
          en cliquant sur «&nbsp;autre trajet souhaité sur ce parcours/demande
          de devis&nbsp;»
        </p>
        <br />
        <p>
          Les trajets à l'international pour 2024 ne sont disponibles que pour
          les groupes.
        </p>
        <br />
        <p><strong>Grâce au moteur de recherche :</strong></p>
        <br />
        <ol>
          <li>
            Soit vous cliquez sur Rechercher pour voir tous les trajets Symone
            disponibles.
          </li>
          <li>
            Soit vous rentrez une date et/ou une ville de départ puis cliquez
            sur Rechercher pour trouver les trajets à proximité.
          </li>
          <li>
            Cliquez sur Détails pour obtenir plus d'informations, le comparatif
            de prix avec le même trajet sur sa moto et cliquez sur Je
            pré-réserve pour accéder au formulaire.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          <b
            >Si vous ne trouvez pas de trajets correspondants, faites une
            demande avec votre trajet souhaité dans le formulaire ci-dessus, aux
            dates et lieux de votre choix.
          </b>
        </p>
        <br />
        <p>
          <b
            >Nous avons plusieurs Symone moto en circulation pour répondre à
            votre demande.</b
          >
        </p>
        <br />
        <p>
          Si un trajet vous intéresse mais que vous avez des contraintes
          particulières (seulement une portion du trajet, horaires, etc),
          contactez-nous : 06 17 33 43 93
        </p>
        <br />
        <p>
          NB : un tarif spécifique pourrait être fait pour des motos de + de
          300kg
        </p>
        <p></p>
      </div>
    </el-dialog>

    <el-dialog center title="Inscription" v-model="showRegistrationPopup" :close-on-click-modal="false">
      <el-form
        ref="ruleRegistrationRef"
        style="max-width: 600px; padding: 50px 50px 0 50px"
        :model="formRegistration"
        :rules="rulesRegistration"
        label-width="auto"
        label-position="top"
        v-loading="isRegistrationSending"
        status-icon
      >
        <el-form-item>
          <el-col :span="24">
            <el-form-item label="E-mail" required prop="email">
              <el-input type="email" v-model="formRegistration.email" required/>
            </el-form-item>
          </el-col><br><br><br><br>
          <el-col :span="12">
            <el-form-item label="Mot de passe" required prop="password">
              <el-input type="password" v-model="formRegistration.password" required/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Confirmation Mot de passe" required prop="checkPassword">
              <el-input
                type="password"
                v-model="formRegistration.checkPassword"
                required
              />
            </el-form-item>
          </el-col><br><br>
          <el-col :span="12">
            <el-form-item label="Prénom" required prop="firstname">
              <el-input type="text" v-model="formRegistration.firstname" required/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Nom" required prop="lastname">
              <el-input
                type="text"
                v-model="formRegistration.lastname"
                required
              />
            </el-form-item>
          </el-col><br><br><br><br>
          <el-col :span="24">
            <el-form-item label="Téléphone" required prop="phone">
              <el-input type="text" v-model="formRegistration.phone" required/>
            </el-form-item>
          </el-col>
        </el-form-item>
        <br />
        <el-col :span="24">
          <el-form-item label="Adresse domicile">
            <el-input type="text" v-model="formRegistration.address" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Nom de la société">
            <el-input type="text" v-model="formRegistration.society_name" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Adresse de la société">
            <el-input type="text" v-model="formRegistration.society_address" />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Numéro fiscal">
            <el-input type="text" v-model="formRegistration.tax_number" />
          </el-form-item>
        </el-col>
        <el-col :span="24"></el-col>
        <button
          class="btn-trans-blue w-1"
          type="button"
          @click="sendRegistration()"
        >
          Valider
        </button>
      </el-form>
    </el-dialog>

    <el-dialog center title="Connexion" v-model="showLoginPopup" :close-on-click-modal="false">
      <el-form
        ref="ruleLoginRef"
        style="max-width: 400px; padding: 50px 50px 0 50px"
        :model="formLogin"
        :rules="rulesLogin"
        label-width="auto"
        label-position="top"
        v-loading="isLoginSending"
        status-icon
      >
        <el-form-item>
          <el-col :span="24">
            <el-form-item label="E-mail" required prop="email">
              <el-input type="email" v-model="formLogin.email" required/>
            </el-form-item>
          </el-col><br><br><br><br>
          <el-col :span="24">
            <el-form-item label="Mot de passe" required prop="password">
              <el-input type="password" v-model="formLogin.password" required/>
            </el-form-item>
          </el-col>
          <el-col :span="24"></el-col>
          <a href="/forgotpsw" target="_blank">Mot de passe oublié ?</a>
        </el-form-item>
        <el-col :span="24"></el-col>
        <button
          class="btn-trans-blue w-1"
          type="button"
          @click="sendLogin()"
        >
          Se connecter
        </button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Autocomplete from "@/components/Autocomplete.vue";
import config from "@/config";
import Cookies from "js-cookie";
import { ElNotification } from "element-plus";
import { getCountryAbbreviation } from "@/utils/countryUtils";
import { useHead } from "@vueuse/head";
import { useReCaptcha } from 'vue-recaptcha-v3'

export default {
  name: "SymoneMoto",
  components: {
    Autocomplete,
  },
  metaInfo() {
    return {
      title: "Moto - Symone",
      meta: [
        {
          name: "description",
          content:
            "Confiez à Symone vos trajets longue distance sur autoroutes. Vous et votre moto arriverez à destination dans les meilleures conditions pour profiter du plaisir de piloter là où le meilleur de votre passion peut s'exercer. Transport de motos seules sous conditions.",
        },
        {
          name: "keywords",
          content: "transport moto, moto, transport",
        },
      ],
    };
  },
  data() {
    return {
      meta: {
        title: "Moto - Symone",
        keywords: "transport moto, moto, transport",
        description:
          "Confiez à Symone vos trajets longue distance sur autoroutes. Vous et votre moto arriverez à destination dans les meilleures conditions pour profiter du plaisir de piloter là où le meilleur de votre passion peut s'exercer. Transport de motos seules sous conditions.",
      },
      about: true,
      channels: [],
      charge: 0,
      checkLogin: false,
      tagBg1:
        "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
      tagBg2:
        "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1",
      tagBg3:
        "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
      tagBg4:
        "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
      tagBg5:
        "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
      subMenuRoute: "color: #44bbc3",
      subMenuDevis: "color: white",
      currentSubMenuRoute: true,
      currentTab: 2,
      insurance: 0,
      items: [],
      currentIndex: 0,
      currentTag: 0,
      slideIndex: 0,
      search: {
        depart: "",
        arrival: "",
        date: "",
      },
      formEstimate: {
        copy_moto: ["true"],
        copy_passenger: ["true"],
        address_charge: '',
        address_discharge: '',
        charge: [],
        discharge: [],
        type: "oneWay",
        depart_date: null,
        depart_city: "",
        date_return: null,
        arrival_city: "",
        moto_nb: null,
        moto_list: '',
        distance: "",
        passenger_nb: null,
        passenger_list: [],
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        code: "",
        how: "",
        comment: "",
        insurance: [],
        status: 1,
        subscriber: [],
        conditionCheck: [],
        rgpd: [],
        address_charge_return: '',
        address_discharge_return: '',
        charge_return: [],
        discharge_return: [],
        depart_return: "",
        arrival_return: "",
        moto_nb_return: null,
        moto_list_return: '',
        distance_return: "",
        passenger_nb_return: null,
        passenger_list_return: []
      },
      formLogin: {
        email: "",
        password: ""
      },
      formRegistration: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        checkPassword: "",
        address: "",
        society_address: "",
        society_name: "",
        tax_number: null
      },
      formReservation: {
        address_depart: '',
        address_arrival: '',
        address_charge: '',
        address_discharge: '',
        charge: [],
        checkCode: false,
        discharge: [],
        price: "",
        price_charge: this.charge,
        price_discharge: this.charge,
        price_unit_origin: 0,
        price_passenger: 0,
        price_passenger_origin: 0,
        price_passenger_rate: 0,
        price_insurance_rate: this.insurance * 100,
        date_depart: null,
        depart: "",
        arrival: "",
        moto_nb: 0,
        moto_list: [],
        moto_list_estimate: '',
        passenger_nb: 0,
        passenger_list: [],
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        code: "",
        how: "",
        comment: "",
        id: "",
        title: "",
        route: null,
        insurance: [],
        price_unit: 0,
        total: 0,
        option: "",
        places_moto: 0,
        places_passenger: 0,
        status: 1,
        type: 1,
        subscriber: [],
        conditionCheck: [],
        row: null,
        rgpd: []
      },
      results: [],
      resultsYYYYMM: [],
      currentYYYYMM: 'Tous',
      tmpResults: [],
      tmpResultsO: [],
      collapseStatus: [],
      currentPage: 1,
      pageSize: 10,
      partnersLogo: [
        {
          src: require("@/assets/images/ui/a75e3a15-a873-4baa-a96c-d0601bde9b32.png"),
          link: "https://liberty-rider.com/fr",
          alt: "Liberty Rider",
        },
        {
          src: require("@/assets/images/ui/MDM-LOGO-RECTANGLE-BLEU-LIBERTE PARTAGEE -ASSUREE.png"),
          link: "https://www.mutuelledesmotards.fr/",
          alt: "Mutuelle des motards",
        },
        {
          src: require("@/assets/images/ui/logo-bimpair-menu.png"),
          link: "https://bimpair.com/",
          alt: "Bimp Air",
        },
        {
          src: require("@/assets/images/ui/axa_passion_logo_solid-blue.png"),
          link: "https://passion.axa.fr/",
          alt: "AXA Passion",
        },
        {
          src: require("@/assets/images/ui/Logo TWO WHEELS THERAPY.png"),
          link: "https://twowheelstherapy.fr/",
          alt: "TWO WHEELS THERAPY",
        },
        {
          src: require("@/assets/images/ui/app-gps-moto-68-degres-logo_rouge.svg"),
          link: "https://app68.eu/",
          alt: "68°"
        },
      ],
      isLoading: false,
      isEstimateSending: false,
      isLoginSending: false,
      isRegistrationSending: false,
      isReservationSending: false,
      showLoginPopup: false,
      showRegistrationPopup: false,
      showReservationPopup: false,
      showHelpPopup: false,
      stepEstimate: 0,
      stepReservation: 0,
      refreshAutocomplete: 0,
      rulesEstimate: {
        type: [
          {
            required: true,
            message: "Champ requis",
            trigger: "change",
          },
        ],
        depart_date: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        date_return: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        moto_nb: [
          {
            required: true,
            message: "Champ requis",
            validator: this.checkEstimateMotoPassengerCount,
            trigger: "blur",
          },
        ],
        passenger_nb: [
          {
            required: true,
            message: "Champ requis",
            validator: this.checkEstimateMotoPassengerCount,
            trigger: "blur",
          },
        ],
        moto_nb_return: [
          {
            required: true,
            message: "Champ requis",
            validator: this.checkEstimateMotoPassengerCountReturn,
            trigger: "blur",
          },
        ],
        passenger_nb_return: [
          {
            required: true,
            message: "Champ requis",
            validator: this.checkEstimateMotoPassengerCountReturn,
            trigger: "blur",
          },
        ],
        firstname: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        lastname: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Veuillez saisir une adresse E-mail valide",
            validator: (rule, value, callback) => {
              const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (!emailPattern.test(value) && !value) {
                return callback(new Error(`Adresse e-mail non valide`));
              }
              callback(); 
            },
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        conditionCheck: [
          {
            type: "array",
            required: true,
            message: "Veuillez cocher la case",
            trigger: "change",
          },
        ],
        rgpd: [
          {
            type: "array",
            required: true,
            message: "Veuillez cocher la case",
            trigger: "change",
          },
        ],
      },
      rulesLogin: {
        email: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
      },
      rulesRegistration: {
        firstname: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        lastname: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            validator: this.checkEmail,
            trigger: ["blur"],
          },
        ],
        phone: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        checkPassword: [
          {
            required: true,
            message: "Les mots de passe ne correspondent pas",
            validator: this.checkPassword,
            trigger: "blur",
          },
        ],
      },
      rulesReservation: {
        address_charge: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        address_discharge: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        depart: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        arrival: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        date_depart: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: false,
            validator: this.checkDiscountCode,
            trigger: "blur",
          },
        ],
        moto_nb: [
          {
            required: true,
            validator: this.checkReservationMotoPassengerCount,
            trigger: "blur",
          },
        ],
        passenger_nb: [
          {
            required: true,
            validator: this.checkReservationMotoPassengerCount,
            trigger: "blur",
          },
        ],
        firstname: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        lastname: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Veuillez saisir une adresse E-mail valide",
            validator: (rule, value, callback) => {
              const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if (!emailPattern.test(value) && !value) {
                callback(new Error(`Adresse e-mail non valide`));
              }
              callback(); 
            },
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        conditionCheck: [
          {
            type: "array",
            required: true,
            message: "Veuillez cocher la case",
            trigger: "change",
          },
        ],
        rgpd: [
          {
            type: "array",
            required: true,
            message: "Veuillez cocher la case",
            trigger: "change",
          },
        ],
      },
      token: null,
      visibleResultsYYYYMM: []
    };
  },
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    const recaptcha = async () => {
      await recaptchaLoaded()
      const token = await executeRecaptcha('submit_form')
      return token
    }

    return {
      recaptcha
    }
  },
  async created() {
    await useHead({
      title: this.meta.title,
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      meta: [
        {
          name: "keywords",
          content: this.meta.keywords,
        },
        {
          name: "description",
          content: this.meta.description,
        },
      ],
    });
    await this.getResults();
    await this.getOptions();
    await this.getChannels();
    if (
      this.$route.query.searchOptionsByHome &&
      this.$route.query.searchOptionsByHome.length > 0
    ) {
      this.search.depart = this.$route.query.searchOptionsByHome[0];
      this.search.arrival = this.$route.query.searchOptionsByHome[1];
      this.searchCities();
      this.refreshAutocomplete++;
    }
  },
  watch: {
    currentYYYYMM(val){
      const index = this.resultsYYYYMM.indexOf(val);
      const totalLength = this.resultsYYYYMM.length;

      let startIndex = index - 2;
      let endIndex = index + 2;

      if (index <= 2) {
        startIndex = 0;
        endIndex = 4;
      }
      else if (index >= totalLength - 5) {
        startIndex = totalLength - 5;
        endIndex = totalLength - 1;
      }

      this.visibleResultsYYYYMM = this.resultsYYYYMM.slice(startIndex, endIndex + 1);
    },
    currentSubMenuRoute(value) {
      if (!value) {
        this.$nextTick(() => {
          let targetElement = document.getElementById("devis-title");
          if (targetElement)
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
        });
      }
    },
    stepEstimate(value) {
      if (value == 3) {
        this.$nextTick(() => {
          if(this.formEstimate.passenger_nb > 0){
            this.formEstimate.passenger_list[0].firstname = this.formEstimate.firstname;
            this.formEstimate.passenger_list[0].lastname = this.formEstimate.lastname;
            this.formEstimate.passenger_list[0].email = this.formEstimate.email;
          }
          
          if(this.formEstimate.passenger_nb_return > 0){
            this.formEstimate.passenger_list_return[0].firstname = this.formEstimate.firstname;
            this.formEstimate.passenger_list_return[0].lastname = this.formEstimate.lastname;
            this.formEstimate.passenger_list_return[0].email = this.formEstimate.email;
          }
        });
      }
      this.$nextTick(() => {
        let targetElement = document.getElementById("formEstimateTop");
        if (targetElement)
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
      });
    },
    stepReservation(value) {
      if (value == 3) {
        this.$nextTick(() => {
          this.formReservation.passenger_list[0].firstname = this.formReservation.firstname;
          this.formReservation.passenger_list[0].lastname = this.formReservation.lastname;
          this.formReservation.passenger_list[0].email = this.formReservation.email;
        });
      }
      if (value == 4 && this.formReservation.price_unit > 0) {
        this.$nextTick(() => {
          this.$refs.ruleReservationRef
            .validateField('code', (valid) => {
              if(valid){
                console.log('Validation passed');
              } else {
                return;
              }
            });
        });
      }
      if(value == 5){
        window.dataLayer?.push({
          event: "conversion",
          send_to: "AW-16522766943/mHFdCIOr--wZEN_U1cY9",
        });
        window.dataLayer?.push({
          event: "conversion_laststep",
          send_to: "AW-16522766943/mHFdCIOr--wZEN_U1cY9",
        });
        this.$emit("conversion_laststep", "conversion_laststep");
      }
      this.$nextTick(() => {
        let targetElement = document.getElementById("formReservationTop");
        if (targetElement)
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
      });
    },
  },
  computed: {
    totalItems() {
      return this.tmpResults.length;
    },

    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      if (this.tmpResults.slice(startIndex, endIndex).length > 0) {
        if (
          !this.checkDate(this.tmpResults.slice(startIndex, endIndex)[0].date)
        ) {
          this.tmpResults.slice(startIndex, endIndex)[0]["first"] = true;
        }
      }
      return this.tmpResults.slice(startIndex, endIndex);
    },
  },
  async mounted() {
    await useHead({
      title: this.meta.title,
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      meta: [
        {
          name: "keywords",
          content: this.meta.keywords,
        },
        {
          name: "description",
          content: this.meta.description,
        },
      ],
    });
    this.checkAuthToken();
    await this.getResults();
    const urlParams = new URLSearchParams(window.location.search);
    const aValue = urlParams.get("currentTab");
    if (aValue !== null) {
      this.switchTab(aValue);
    }
    const tabToOpen = urlParams.get("tab");
    if (tabToOpen == "tab-devis") {
      const tab = document.querySelector(`#tab-devis`);
      if (tab) {
        tab.click();
        this.$nextTick(() => {
          let targetElement = document.getElementById("devis-title");
          if (targetElement)
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
        });
      }
    }
    const action = urlParams.get("action");
    if (action === 'special') {
        this.searchSp();
    }
  },
  methods: {
    backTop() {
      this.$nextTick(() => {
        let targetElement = document.getElementById("results");
        if (targetElement)
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    },
    async checkEmail(rule, value, callback) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(value)) {
        callback(new Error("Veuillez saisir une adresse E-mail valide"));
        return;
      }

      try {
        let response = await axios.get(
          `${config.API_BASE_URL}/user/get/email/${value}`
        );

        if (response.data.length > 0) {
          callback(new Error("Cet utilisateur existe déjà"));
          return;
        } else {
          callback();
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'email:', error);
        callback(new Error("Erreur de validation de l'email"));
      }
    },
    checkAuthToken(){
      const tk = Cookies.get('authToken');
      if (!tk) {
        console.log('No token found.');
        this.token = null;
      } else {
        fetch(`${config.API_BASE_URL}/user/token`, {
            method: 'POST',
            headers: { 
              'Authorization': `Bearer ${tk}`,
              'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
          if (data.valid) {
            console.log('Token is valid');
            this.token = data.user;
          } else {
            console.log('Token is invalid.');
            Cookies.remove('authToken');
            this.token = null;
            window.location.reload();
          }
        })
        .catch(error => {
          console.error('Error validating token:', error);
          this.token = null;
        });
      }
    },
    async checkDiscountCode(rule, value, callback) {
      if(this.formReservation.row.discount_group_moto && this.formReservation.row.discount_group_value && this.formReservation.moto_nb >= this.formReservation.row.discount_group_moto ){
        this.formReservation.price_unit = this.formReservation.price_unit_origin * (1 - (this.formReservation.row.discount_group_value / 100));
        let price_passenger = 0;
        if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
        this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
      } else {
        this.formReservation.price_unit = this.formReservation.price_unit_origin;
        let price_passenger = 0;
        if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
        this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
      }
      this.formReservation.price_passenger = (this.formReservation.price_unit * this.formReservation.price_passenger_origin).toFixed(2);
      if (value) {
        try{
          const response = await axios.get(
            `${config.API_BASE_URL}/discountCode/verify/${value}/${this.formReservation.id}`
          );
          console.log(response.data);
          if(response.data && response.data.length > 0) {
            let price_passenger1 = 0;
            this.formReservation.price_unit = response.data[0].type == 1 ? (this.formReservation.price_unit * (1 - (response.data[0].value / 100))).toFixed(2) : (this.formReservation.price_unit - response.data[0].value).toFixed(2);
            this.formReservation.price_passenger = (this.formReservation.price_unit * this.formReservation.price_passenger_origin).toFixed(2);
            if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger1 = ((this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin)).toFixed(2);
            this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger1)).toFixed(2);
            this.formReservation.checkCode = true;
            callback();
          } else {
            callback(new Error('Code non valide'));
            this.formReservation.code = '';
            this.formReservation.checkCode = false;
          }
        } catch(error){
          if(error.response){
            callback(new Error(error.response.data.message));
            this.formReservation.code = '';
            this.formReservation.checkCode = false;
          }
        }
      } else {
        this.formReservation.checkCode = false;
        callback();
      }
    },
    checkEstimateMotoPassengerCount(rule, value, callback) {
      if (value === '' || value === null) {
        callback(new Error('Champ requis'));
      } else if (this.formEstimate.moto_nb == 0 && this.formEstimate.passenger_nb == 0) {
        callback(new Error('Le nombre de motos ou de passagers doit être > 0.'));
      } else {
        if(value > 0) this.formEstimate.charge = [];
        callback();
      }
    },
    checkEstimateMotoPassengerCountReturn(rule, value, callback) {
      if (value === '' || value === null) {
        callback(new Error('Champ requis'));
      } else if (this.formEstimate.moto_nb_return == 0 && this.formEstimate.passenger_nb_return == 0) {
        callback(new Error('Le nombre de motos ou de passagers doit être > 0.'));
      } else {
        if(value > 0) this.formEstimate.charge_return = [];
        callback();
      }
    },
    checkPassword(rule, value, callback) {
      if (value === '' || value === null) {
        callback(new Error('Veuillez confirmer votre mot de passe'));
      } else if (value !== this.formRegistration.password) {
        callback(new Error('Les mots de passe ne correspondent pas'));
      } else {
        callback();
      }
    },
    checkReservationMotoPassengerCount(rule, value, callback) {
      if (value === '' || value === null) {
        callback(new Error('Champ requis'));
      } else if (this.formReservation.moto_nb == 0) {
        callback(new Error('Le nombre de motos doit être > 0, sinon veuillez faire une demande de devis'));
      } else {
        if(this.formReservation.passenger_nb > (this.formReservation.moto_nb * 3)) this.formReservation.passenger_nb = this.formReservation.moto_nb * 3;
        if(value > 0) this.formReservation.charge = [];
        callback();
      }
    },
    copyListMoto(){
      this.formEstimate.moto_list_return = this.formEstimate.moto_list;
    },
    copyListPassenger(){
      this.formEstimate.passenger_list_return = this.formEstimate.passenger_list;
    },
    logout(){
      Cookies.remove('authToken');
      window.location.reload();
    },
    showCountryAbbreviation(name) {
      return getCountryAbbreviation(name.trim());
    },
    openLoginPopup() {
      this.formLogin = {
        email: "",
        password: ""
      };

      this.showLoginPopup = true;
    },
    openRegistrationPopup() {
      this.formRegistration = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        checkPassword: "",
        address: "",
        society_address: "",
        society_name: "",
        tax_number: null
      };

      this.showRegistrationPopup = true;
    },
    openReservationPopup(row, option) {
      this.stepReservation = 0;
      this.formReservation = {
        address_depart: '',
        address_arrival: '',
        address_charge: '',
        address_discharge: '',
        charge: [],
        checkCode: false,
        discharge: [],
        price: "",
        price_charge: this.charge,
        price_discharge: this.charge,
        price_unit_origin: 0,
        price_passenger: 0,
        price_passenger_origin: 0,
        price_passenger_rate: 0,
        price_insurance_rate: this.insurance * 100,
        date_depart: null,
        depart: "",
        arrival: "",
        moto_nb: 0,
        moto_list: [],
        moto_list_estimate: '',
        passenger_nb: 0,
        passenger_list: [],
        firstname: this.token ? this.token.firstname : "",
        lastname: this.token ? this.token.lastname : "",
        email: this.token ? this.token.email : "",
        phone: this.token ? this.token.phone : "",
        code: "",
        how: "",
        comment: "",
        id: "",
        title: "",
        route: null,
        insurance: [],
        total: 0,
        price_unit: 0,
        option: "",
        places_moto: 0,
        places_passenger: 0,
        status: 5,
        type: 1,
        subscriber: [],
        conditionCheck: [],
        row: row,
        rgpd: []
      };

      if (row.type == "event") {
        this.formReservation.price = `Tarif à calculer par notre service(Event: ${row.name})`;
        this.formReservation.title = "Event - " + row.name;
        this.formReservation.id = row.id;
      } else {
        this.formReservation.title = `${row.depart}${
          row.layover1 ? " → " + row.layover1 : ""
        }${row.layover2 ? " → " + row.layover2 : ""}${
          row.layover3 ? " → " + row.layover3 : ""
        }→${row.arrival}`;
        this.formReservation.id = row.id;
        this.formReservation.date_depart = this.formatDate(row.date);
        this.formReservation.route = row;

        this.formReservation.option = option;
        row['option'] = option;
        let getPlaces = this.calculatePlaces(row);
        this.formReservation.places_moto = getPlaces.places_moto;
        this.formReservation.places_passenger = getPlaces.places_passenger;
        switch (option) {
          case "0-1":
            this.formReservation.address_depart = row.address_depart
            this.formReservation.address_arrival = row.address_layover1
            this.formReservation.price_passenger_rate = row.price_passenger_0_1;
            this.formReservation.depart = row.depart;
            this.formReservation.arrival = row.layover1;
            if (/^\d+$/.test(row.price0_1)) {
              if (!row.discount_value){
                this.formReservation.price = row.price0_1 + "€";
                this.formReservation.price_unit = row.price0_1;
                this.formReservation.price_unit_origin = row.price0_1;
              }
              else {
                if (row.discount_type == 1) {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price0_1) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price0_1) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price0_1) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price0_1}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price =
                    parseInt(row.price0_1) - parseInt(row.discount_value);
                  this.formReservation.price_unit_origin = parseInt(row.price0_1) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                    parseInt(row.price0_1) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price0_1}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price0_1;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_0_1 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_0_1 / 100;
            }
            break;
          case "0-2":
            this.formReservation.address_depart = row.address_depart
            this.formReservation.address_arrival = row.address_layover2
            this.formReservation.price_passenger_rate = row.price_passenger_0_2;
            this.formReservation.depart = row.depart;
            this.formReservation.arrival = row.layover2;
            if (/^\d+$/.test(row.price0_2)) {
              if (!row.discount_value){
                this.formReservation.price = row.price0_2 + "€";
                this.formReservation.price_unit = row.price0_2;
                this.formReservation.price_unit_origin = row.price0_2;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price0_2) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price0_2) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price0_2) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price0_2}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price0_2) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                    parseInt(row.price0_2) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price0_2) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price0_2}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price0_2;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_0_2 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_0_2 / 100;
            }
            break;
          case "0-3":
            this.formReservation.address_depart = row.address_depart
            this.formReservation.address_arrival = row.address_layover3
            this.formReservation.depart = row.depart;
            this.formReservation.arrival = row.layover3;
            this.formReservation.price_passenger_rate = row.price_passenger_0_3;
            if (/^\d+$/.test(row.price0_3)) {
              if (!row.discount_value){
                this.formReservation.price = row.price0_3 + "€";
                this.formReservation.price_unit = row.price0_3;
                this.formReservation.price_unit_origin = row.price0_3;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price0_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price0_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price0_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price0_3}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price0_3) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                    parseInt(row.price0_3) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price0_3) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price0_3}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price0_3;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_0_3 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_0_3 / 100;
            }
            break;
          case "0-4":
            this.formReservation.address_depart = row.address_depart
            this.formReservation.address_arrival = row.address_arrival
            this.formReservation.depart = row.depart;
            this.formReservation.arrival = row.arrival;
            this.formReservation.price_passenger_rate = row.price_passenger_0_4;
            if (/^\d+$/.test(row.price0_4)) {
              if (!row.discount_value){
                this.formReservation.price = row.price0_4 + "€";
                this.formReservation.price_unit = row.price0_4;
                this.formReservation.price_unit_origin = row.price0_4;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price0_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price0_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price0_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price0_4}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                  parseInt(row.price0_4) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                  parseInt(row.price0_4) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price0_4) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price0_4}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price0_4;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_0_4 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_0_4 / 100;
            }
            break;
          case "1-2":
            this.formReservation.address_depart = row.address_layover1
            this.formReservation.address_arrival = row.address_layover2
            this.formReservation.depart = row.layover1;
            this.formReservation.arrival = row.layover2;
            this.formReservation.price_passenger_rate = row.price_passenger_1_2;
            if (/^\d+$/.test(row.price1_2)) {
              if (!row.discount_value){
                this.formReservation.price = row.price1_2 + "€";
                this.formReservation.price_unit = row.price1_2;
                this.formReservation.price_unit_origin = row.price1_2;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price1_2) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price1_2) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price1_2) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price1_2}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                  parseInt(row.price1_2) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                  parseInt(row.price1_2) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price1_2) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price1_2}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price1_2;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_1_2 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_1_2 / 100;
            }
            break;
          case "1-3":
            this.formReservation.address_depart = row.address_layover1
            this.formReservation.address_arrival = row.address_layover3
            this.formReservation.depart = row.layover1;
            this.formReservation.arrival = row.layover3;
            this.formReservation.price_passenger_rate = row.price_passenger_1_3;
            if (/^\d+$/.test(row.price1_3)) {
              if (!row.discount_value){
                this.formReservation.price = row.price1_3 + "€";
                this.formReservation.price_unit = row.price1_3;
                this.formReservation.price_unit_origin = row.price1_3;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price1_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price1_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price1_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price1_3}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                  parseInt(row.price1_3) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                  parseInt(row.price1_3) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price1_3) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price1_3}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price1_3;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_1_3 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_1_3 / 100;
            }
            break;
          case "1-4":
            this.formReservation.address_depart = row.address_layover1
            this.formReservation.address_arrival = row.address_arrival
            this.formReservation.depart = row.layover1;
            this.formReservation.arrival = row.arrival;
            this.formReservation.price_passenger_rate = row.price_passenger_1_4;
            if (/^\d+$/.test(row.price1_4)) {
              if (!row.discount_value){
                this.formReservation.price = row.price1_4 + "€";
                this.formReservation.price_unit = row.price1_4;
                this.formReservation.price_unit_origin = row.price1_4;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price1_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price1_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price1_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price1_4}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                  parseInt(row.price1_4) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                  parseInt(row.price1_4) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price1_4) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price1_4}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price1_4;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_1_4 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_1_4 / 100;
            }
            break;
          case "2-3":
            this.formReservation.address_depart = row.address_layover2
            this.formReservation.address_arrival = row.address_layover3
            this.formReservation.depart = row.layover2;
            this.formReservation.arrival = row.layover3;
            this.formReservation.price_passenger_rate = row.price_passenger_2_3;
            if (/^\d+$/.test(row.price2_3)) {
              if (!row.discount_value){
                this.formReservation.price = row.price2_3 + "€";
                this.formReservation.price_unit = row.price2_3;
                this.formReservation.price_unit_origin = row.price2_3;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price2_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price2_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price2_3) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price2_3}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                  parseInt(row.price2_3) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                  parseInt(row.price2_3) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price2_3) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price2_3}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price2_3;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_2_3 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_2_3 / 100;
            }
            break;
          case "2-4":
            this.formReservation.address_depart = row.address_layover2
            this.formReservation.address_arrival = row.address_arrival
            this.formReservation.depart = row.layover1;
            this.formReservation.arrival = row.arrival;
            this.formReservation.price_passenger_rate = row.price_passenger_2_4;
            if (/^\d+$/.test(row.price2_4)) {
              if (!row.discount_value){
                this.formReservation.price = row.price2_4 + "€";
                this.formReservation.price_unit = row.price2_4;
                this.formReservation.price_unit_origin = row.price2_4;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price2_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price2_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price2_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price2_4}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                  parseInt(row.price2_4) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                  parseInt(row.price2_4) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price2_4) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price2_4}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price2_4;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_2_4 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_2_4 / 100;
            }
            break;
          case "3-4":
            this.formReservation.address_depart = row.address_layover3
            this.formReservation.address_arrival = row.address_arrival
            this.formReservation.depart = row.layover3;
            this.formReservation.arrival = row.arrival;
            this.formReservation.price_passenger_rate = row.price_passenger_3_4;
            if (/^\d+$/.test(row.price3_4)) {
              if (!row.discount_value){
                this.formReservation.price = row.price3_4 + "€";
                this.formReservation.price_unit = row.price3_4;
                this.formReservation.price_unit_origin = row.price3_4;
              }
              else {
                if (row.discount_type == "1") {
                  this.formReservation.price_unit_origin = 
                    parseInt(row.price3_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price_unit = 
                    parseInt(row.price3_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price =
                    parseInt(row.price3_4) *
                    (1 - parseInt(row.discount_value) / 100);
                  this.formReservation.price += `€(${row.price3_4}€ -${row.discount_value}%)`;
                } else {
                  this.formReservation.price_unit_origin = 
                  parseInt(row.price3_4) - parseInt(row.discount_value);
                  this.formReservation.price_unit = 
                    parseInt(row.price3_4) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price3_4) - parseInt(row.discount_value);
                  this.formReservation.price =
                    parseInt(row.price3_4) - parseInt(row.discount_value);
                  this.formReservation.price += `€(${row.price3_4}€ -${row.discount_value}€)`;
                }
              }
            } else {
              this.formReservation.price = row.price3_4;
            }
            if(this.formReservation.price_unit > 0) {
              this.formReservation.price_passenger = (this.formReservation.price_unit * (row.price_passenger_3_4 / 100)).toFixed(2);
              this.formReservation.price_passenger_origin = row.price_passenger_3_4 / 100;
            }
            break;
          default:
            this.formReservation.price = "à vérifier par notre service";
            this.formReservation.price_passenger = "à vérifier par notre service";
            break;
        }
      }
      if(this.formReservation.price_unit == 0) this.formReservation.status = 1;

      this.refreshAutocomplete++;
      this.showReservationPopup = true;
    },
    async getChannels() {
      try {
        let response = await axios.get(
          `${config.API_BASE_URL}/channel/get`
        );
        this.channels = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error("Error channels/get:", error);
      }
    },
    async getOptions() {
      try {
        let response = await axios.get(
          `${config.API_BASE_URL}/option/get/all`
        );
        this.insurance = response.data[0].value / 100;
        this.charge = response.data[1].value;
      } catch (error) {
        console.error("Error options/get:", error);
      }
    },

    async getResults() {
      try {
        let response = await axios.get(
          `${config.API_BASE_URL}/route/get/results`
        );
        this.results = JSON.parse(JSON.stringify(response.data));
        this.tmpResults = JSON.parse(JSON.stringify(response.data));
        this.tmpResultsO = JSON.parse(JSON.stringify(response.data));

        this.resultsYYYYMM = Array.from(
          new Set(this.results.map(r => r.date.slice(0, 6)))
        );
        this.resultsYYYYMM.sort();
        this.resultsYYYYMM.unshift('Tous');
        this.visibleResultsYYYYMM = this.resultsYYYYMM.slice(0, 6);
        this.currentYYYYMM = 'Tous';
        this.currentPage = 1;
        this.backTop();
      } catch (error) {
        console.error("Error results/get:", error);
      }
    },

    async searchCities() {
      try {
        this.currentSubMenuRoute = true;
        this.subMenuRoute = "color: #44bbc3";
        this.subMenuDevis = "color: white";

        this.isLoading = true;
        this.$nextTick(() => {
          let targetElement = document.getElementById("results");
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        });

        let tmp = JSON.parse(JSON.stringify(this.results));

        if (this.search.depart) {
          let cityName = this.search.depart.split(",")[0].trim();
          let countryName = this.search.depart
            .split(",")[this.search.depart.split(",").length - 1].trim();

          const geocodingApiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${cityName},${countryName}&accept-language=fr&&bounded=1&viewbox=-10.8544921875,35.817813158696616,33.57421875,70.29390560813168`;
          const response = await fetch(geocodingApiUrl);
          if (!response.ok) {
            throw new Error("City not found");
          }
          const data = await response.json();

          if (data.length === 0) {
            throw new Error("City not found");
          }

          const { lat, lon } = data[0];

          const overpassQuery = `
              [out:json];
              (
                  node(around:100000,${lat},${lon})[place=city];
                  way(around:100000,${lat},${lon})[place=city];
                  relation(around:100000,${lat},${lon})[place=city];
                  node(around:100000,${lat},${lon})[place=town];
                  way(around:100000,${lat},${lon})[place=town];
                  relation(around:100000,${lat},${lon})[place=town];
              );
              out;
          `;

          const responsesearch = await axios.post(
            "https://overpass-api.de/api/interpreter",
            overpassQuery
          );

          const cities = responsesearch.data.elements.map((element) => ({
            id: element.id,
            name: element.tags.name,
          }));

          tmp = tmp.filter((result) => {
            if (!result.depart) {
              return true;
            }
            return cities.some(
              (city) =>
                city.name?.toLowerCase() ===
                  (result.depart?.split(",")[0] || "").toLowerCase() ||
                city.name?.toLowerCase() ===
                  (result.layover1?.split(",")[0] || "").toLowerCase() ||
                city.name?.toLowerCase() ===
                  (result.layover2?.split(",")[0] || "").toLowerCase() ||
                city.name?.toLowerCase() ===
                  (result.layover3?.split(",")[0] || "").toLowerCase()
            );
          });
        }
        if (this.search.arrival) {
          let cityName = this.search.arrival.split(",")[0].trim();
          let countryName = this.search.arrival
            .split(",")[this.search.arrival.split(",").length - 1].trim();

          const geocodingApiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${cityName},${countryName}&accept-language=fr&&bounded=1&viewbox=-10.8544921875,35.817813158696616,33.57421875,70.29390560813168`;
          const response = await fetch(geocodingApiUrl);
          if (!response.ok) {
            throw new Error("City not found");
          }
          const data = await response.json();

          if (data.length === 0) {
            throw new Error("City not found");
          }

          const { lat, lon } = data[0];

          const overpassQuery = `
              [out:json];
              (
                  node(around:100000,${lat},${lon})[place=city];
                  way(around:100000,${lat},${lon})[place=city];
                  relation(around:100000,${lat},${lon})[place=city];
                  node(around:100000,${lat},${lon})[place=town];
                  way(around:100000,${lat},${lon})[place=town];
                  relation(around:100000,${lat},${lon})[place=town];
              );
              out;
          `;

          const responsesearch = await axios.post(
            "https://overpass-api.de/api/interpreter",
            overpassQuery
          );

          const cities = responsesearch.data.elements.map((element) => ({
            id: element.id,
            name: element.tags.name,
          }));

          tmp = tmp.filter((result) => {
            if (!result.depart) {
              return true;
            }
            return cities.some(
              (city) =>
                city.name?.toLowerCase() ===
                  (result.arrival?.split(",")[0] || "").toLowerCase() ||
                city.name?.toLowerCase() ===
                  (result.layover1?.split(",")[0] || "").toLowerCase() ||
                city.name?.toLowerCase() ===
                  (result.layover2?.split(",")[0] || "").toLowerCase() ||
                city.name?.toLowerCase() ===
                  (result.layover3?.split(",")[0] || "").toLowerCase()
            );
          });
        }
        if (this.search.date) {
          const targetDateObject = new Date(
            `${this.search.date.substring(0, 4)}-${this.search.date
              .substring(4, 6)
              .padStart(2, "0")}-${this.search.date
              .substring(6, 8)
              .padStart(2, "0")}`
          );

          const startDate = new Date(targetDateObject);
          startDate.setDate(startDate.getDate() - 10);

          const endDate = new Date(targetDateObject);
          endDate.setDate(endDate.getDate() + 10);

          tmp = tmp.filter((date) => {
            const currentDate = new Date(
              date.date.substring(0, 4),
              parseInt(date.date.substring(4, 6)) - 1,
              date.date.substring(6, 8)
            );
            return currentDate >= startDate && currentDate <= endDate;
          });
        }
        if (tmp.length == 0 || tmp.every((item) => item.type === "event")) {
          this.currentSubMenuRoute = false;
          this.subMenuDevis = "color: #44bbc3";
          this.subMenuRoute = "color: white";

          this.$nextTick(() => {
            let targetElement = document.getElementById("devis-title");
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          });
        } else {
          if (tmp.filter((item) => item.type === "route").length <= 5) {
            let routeCount = 0;
            let firstEventIndexBeforeRoute = -1;
            let lastEventIndexAfterRoute = -1;

            for (let i = 0; i < tmp.length; i++) {
              const item = tmp[i];
              if (item.type === "route") {
                routeCount++;
              } else {
                if (routeCount === 0) {
                  firstEventIndexBeforeRoute = i;
                }
              }
            }
            if (firstEventIndexBeforeRoute !== -1) {
              tmp = tmp.slice(firstEventIndexBeforeRoute);
            }
            tmp.reverse();
            routeCount = 0;
            for (let i = 0; i < tmp.length; i++) {
              const item = tmp[i];
              if (item.type === "route") {
                routeCount++;
              } else {
                if (routeCount === 0) {
                  lastEventIndexAfterRoute = i;
                }
              }
            }

            if (lastEventIndexAfterRoute !== -1) {
              tmp = tmp.slice(0, lastEventIndexAfterRoute + 1);
            }
            tmp.reverse();
          }

          this.resultsYYYYMM = Array.from(
            new Set(tmp.map(r => r.date.slice(0, 6)))
          );
          this.resultsYYYYMM.sort();
          if (this.resultsYYYYMM.length > 1) this.resultsYYYYMM.unshift("Tous");
          if(this.resultsYYYYMM.length > 0) {
            this.visibleResultsYYYYMM = this.resultsYYYYMM.slice(0, 6);
            this.currentYYYYMM = this.resultsYYYYMM[0];
          }
          this.tmpResults = tmp;
          this.tmpResultsO = tmp;
          this.$nextTick(() => {
            let targetElement = document.getElementById("results");
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          });
        }
        this.currentPage = 1;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    },
    searchSp() {
      try {
        this.currentSubMenuRoute = true;
        this.subMenuRoute = "color: #44bbc3";
        this.subMenuDevis = "color: white";

        this.isLoading = true;
        this.$nextTick(() => {
          let targetElement = document.getElementById("results");
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        });


        let tmp = JSON.parse(JSON.stringify(this.results));
        tmp = tmp.filter((r) => {
          return (
            (r.depart && r.arrival && r.depart.toLowerCase() === 'paris, france' && r.arrival.toLowerCase() === 'marseille, france') ||
            (r.depart && r.arrival && r.depart.toLowerCase() === 'marseille, france' && r.arrival.toLowerCase() === 'paris, france')
          );
        });

        if (tmp.length == 0 || tmp.every((item) => item.type === "event")) {
          this.currentSubMenuRoute = false;
          this.subMenuDevis = "color: #44bbc3";
          this.subMenuRoute = "color: white";

          this.$nextTick(() => {
            let targetElement = document.getElementById("devis-title");
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          });
        } else {
          if (tmp.filter((item) => item.type === "route").length <= 5) {
            let routeCount = 0;
            let firstEventIndexBeforeRoute = -1;
            let lastEventIndexAfterRoute = -1;

            for (let i = 0; i < tmp.length; i++) {
              const item = tmp[i];
              if (item.type === "route") {
                routeCount++;
              } else {
                if (routeCount === 0) {
                  firstEventIndexBeforeRoute = i;
                }
              }
            }
            if (firstEventIndexBeforeRoute !== -1) {
              tmp = tmp.slice(firstEventIndexBeforeRoute);
            }
            tmp.reverse();
            routeCount = 0;
            for (let i = 0; i < tmp.length; i++) {
              const item = tmp[i];
              if (item.type === "route") {
                routeCount++;
              } else {
                if (routeCount === 0) {
                  lastEventIndexAfterRoute = i;
                }
              }
            }

            if (lastEventIndexAfterRoute !== -1) {
              tmp = tmp.slice(0, lastEventIndexAfterRoute + 1);
            }
            tmp.reverse();
          }

          this.resultsYYYYMM = Array.from(
            new Set(tmp.map(r => r.date.slice(0, 6)))
          );
          this.resultsYYYYMM.sort();
          if (this.resultsYYYYMM.length > 1) this.resultsYYYYMM.unshift("Tous");
          if(this.resultsYYYYMM.length > 0) {
            this.visibleResultsYYYYMM = this.resultsYYYYMM.slice(0, 6);
            this.currentYYYYMM = this.resultsYYYYMM[0];
          }
          this.tmpResults = tmp;
          this.tmpResultsO = tmp;
          this.$nextTick(() => {
            let targetElement = document.getElementById("results");
            targetElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          });
        }
        this.currentPage = 1;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      }
    },

    calculatePlaces(tj){
      let nb_moto = tj.moto_max;
      let nb_passenger = tj.passenger_max;

      for (let i = 1; i <= Number(tj.option.split("-")[1])-Number(tj.option.split("-")[0]); i++) {
        tj[`places_moto_${i}`] < nb_moto ? nb_moto = tj[`places_moto_${i}`] : "";
        tj[`places_passenger_${i}`] < nb_passenger ? nb_passenger = tj[`places_passenger_${i}`] : "";
      }
      tj.places_moto = nb_moto;
      tj.places_passenger = nb_passenger;
      return tj;
    },

    setDefaultReturnInfo(val){
      if(val == 'roundTrip'){
        this.formEstimate.depart_return = this.formEstimate.arrival_city
        this.formEstimate.arrival_return = this.formEstimate.depart_city
        this.formEstimate.moto_nb_return = this.formEstimate.moto_nb
        this.formEstimate.passenger_nb_return = this.formEstimate.passenger_nb
        this.formEstimate.passenger_nb_return = Math.floor(this.formEstimate.passenger_nb_return);
        if (this.formEstimate.passenger_nb_return > 20) {
          this.formEstimate.passenger_nb_return = 20;
        }
        if(this.formEstimate.passenger_nb_return !== ''){
          const currentLength = this.formEstimate.passenger_list_return.length;

          if (this.formEstimate.passenger_nb_return > currentLength) {
            for (let i = currentLength; i < this.formEstimate.passenger_nb_return; i++) {
              this.formEstimate.passenger_list_return.push({
                firstname: "",
                lastname: "",
                email: ""
              });
            }
          }

          if (this.formEstimate.passenger_nb_return < currentLength) {
            this.formEstimate.passenger_list_return.splice(this.formEstimate.passenger_nb_return);
          }
        }
        this.refreshAutocomplete++
      }
    },

    generateMotoListForReservation() {
      if(this.formReservation.moto_nb) this.formReservation.moto_nb = Math.floor(this.formReservation.moto_nb);
      if (this.formReservation.moto_nb > this.formReservation.places_moto) {
        this.formReservation.moto_nb = this.formReservation.places_moto;
      }
      if (this.formReservation.moto_nb < 0) {
        this.formReservation.moto_nb = 0;
      }
      if(this.formReservation.moto_nb !== ''){
        const currentLength = this.formReservation.moto_list.length;

        if (this.formReservation.moto_nb > currentLength) {
          for (let i = currentLength; i < this.formReservation.moto_nb; i++) {
            this.formReservation.moto_list.push({
              model: "",
            });
          }
        }

        if (this.formReservation.moto_nb < currentLength) {
          this.formReservation.moto_list.splice(this.formReservation.moto_nb);
        }

        if(this.formReservation.row.discount_group_moto && this.formReservation.row.discount_group_value && this.formReservation.moto_nb >= this.formReservation.row.discount_group_moto ){
          this.formReservation.price_unit = this.formReservation.price_unit_origin * (1 - (this.formReservation.row.discount_group_value / 100));
          let price_passenger = 0;
          if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
          this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
        } else {
          this.formReservation.price_unit = this.formReservation.price_unit_origin;
          let price_passenger = 0;
          if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
          this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
        }
        this.formReservation.price_passenger = (this.formReservation.price_unit * this.formReservation.price_passenger_origin).toFixed(2);
        this.formReservation.price = this.formReservation.price_unit;
      }
    },

    generatePassengerList() {
      if(this.formEstimate.passenger_nb) this.formEstimate.passenger_nb = Math.floor(this.formEstimate.passenger_nb);
      if (this.formEstimate.passenger_nb > 20) {
        this.formEstimate.passenger_nb = 20;
      }
      if (this.formEstimate.passenger_nb < 0) {
        this.formEstimate.passenger_nb = 0;
      }
      if(this.formEstimate.passenger_nb !== ''){
        const currentLength = this.formEstimate.passenger_list.length;

        if (this.formEstimate.passenger_nb > currentLength) {
          for (let i = currentLength; i < this.formEstimate.passenger_nb; i++) {
            this.formEstimate.passenger_list.push({
              firstname: "",
              lastname: "",
              email: ""
            });
          }
        }

        if (this.formEstimate.passenger_nb < currentLength) {
          this.formEstimate.passenger_list.splice(this.formEstimate.passenger_nb);
        }
      }
    },
    generatePassengerListReturn() {
      if(this.formEstimate.passenger_nb_return) this.formEstimate.passenger_nb_return = Math.floor(this.formEstimate.passenger_nb_return);
      if (this.formEstimate.passenger_nb_return > 20) {
        this.formEstimate.passenger_nb_return = 20;
      }
      if (this.formEstimate.passenger_nb_return < 0) {
        this.formEstimate.passenger_nb_return = 0;
      }
      if(this.formEstimate.passenger_nb_return !== ''){
        const currentLength = this.formEstimate.passenger_list_return.length;

        if (this.formEstimate.passenger_nb_return > currentLength) {
          for (let i = currentLength; i < this.formEstimate.passenger_nb_return; i++) {
            this.formEstimate.passenger_list_return.push({
              firstname: "",
              lastname: "",
              email: ""
            });
          }
        }

        if (this.formEstimate.passenger_nb_return < currentLength) {
          this.formEstimate.passenger_list_return.splice(this.formEstimate.passenger_nb_return);
        }
      }
    },
    generatePassengerListForReservation() {
      if (this.formReservation.passenger_nb) this.formReservation.passenger_nb = Math.floor(this.formReservation.passenger_nb);
      if (this.formReservation.passenger_nb > this.formReservation.places_passenger) {
        this.formReservation.passenger_nb = this.formReservation.places_passenger;
      }
      if (this.formReservation.passenger_nb < 0) {
        this.formReservation.passenger_nb = 0;
      }
      if(this.formReservation.passenger_nb !== ''){
        const currentLength = this.formReservation.passenger_list.length;

        if (this.formReservation.passenger_nb > currentLength) {
          for (let i = currentLength; i < this.formReservation.passenger_nb; i++) {
            this.formReservation.passenger_list.push({
              firstname: "",
              lastname: "",
              email: ""
            });
          }
        }

        if (this.formReservation.passenger_nb < currentLength) {
          this.formReservation.passenger_list.splice(this.formReservation.passenger_nb);
        }
      }
    },
    async getCityCoordinates(city, country) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${city},${country}`
        );
        const data = await response.json();

        if (data.length > 0) {
          return {
            lat: parseFloat(data[0].lat),
            lon: parseFloat(data[0].lon),
          };
        } else {
          throw new Error("No data found for the given city and country.");
        }
      } catch (error) {
        console.error("Error occurred while fetching city coordinates:", error);
        return null;
      }
    },
    async getRouteDistance(city1, country1, city2, country2) {
      try {
        const coordinates1 = await this.getCityCoordinates(city1, country1);
        const coordinates2 = await this.getCityCoordinates(city2, country2);

        const response = await fetch(
          `https://router.project-osrm.org/route/v1/driving/${coordinates1.lon},${coordinates1.lat};${coordinates2.lon},${coordinates2.lat}?overview=false`
        );
        const data = await response.json();

        if (data.code === "Ok" && data.routes && data.routes.length > 0) {
          const distanceInMeters = data.routes[0].distance;
          const distanceInKilometers = distanceInMeters / 1000;
          return distanceInKilometers;
        } else {
          throw new Error(
            "Unable to find route or invalid response from server."
          );
        }
      } catch (error) {
        console.error("Error occurred while fetching route distance:", error);
        return null;
      }
    },
    async calculateDistance(str1, str2) {
      const city1 = str1.split(",")[0];
      const country1 = str1.split(",")[str1.split(",").length - 1];
      const city2 = str2.split(",")[0];
      const country2 = str2.split(",")[str1.split(",").length - 1];

      const distance = await this.getRouteDistance(
        city1,
        country1,
        city2,
        country2
      );
      if (distance !== null) {
        return `${distance.toFixed(2)}km`;
      } else {
        return "Failed to calculate route distance.";
      }
    },
    async sendLogin() {
      try {
        if (!this.$refs.ruleLoginRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleLoginRef.validate((valid, fields) => {
            if (valid) {
              console.log("submit!");
              resolve(true);
            } else {
              console.log("error submit:" + reject, fields);
              resolve(false);
            }
          });
        });
        if (!check) return;
        this.isLoginSending = true;
        const response = await axios.post(
          `${config.API_BASE_URL}/user/login`,
          { data: this.formLogin }
        );
        console.log(response.data);
        if(response.data) {
          this.token = response.data.user;
          Cookies.set('authToken', response.data.token, { expires: 1, secure: true, sameSite: 'Strict' });
        }
        ElNotification({
          title: "Success",
          message:
            "Connecté(e) avec succès.",
          type: "success",
          position: "bottom-right",
          duration: 30000,
        });

        this.isLoginSending = false;
        this.showLoginPopup = false;
        window.location.reload();
      } catch (error) {
        if (error.response) {
            if (error.response.status === 404) {
                console.error('Email OU mot de passe incorrect.');
                ElNotification({
                  title: "Erreur",
                  message:
                    "Email OU mot de passe incorrect.",
                  type: "error",
                  position: "bottom-right",
                  duration: 0,
                });
                this.isLoginSending = false;
            } else {
                console.error('Erreur:', error.response.data.message);
                ElNotification({
                  title: "Erreur",
                  message: error.response.data.message,
                  type: "error",
                  position: "bottom-right",
                  duration: 0,
                });
                this.isLoginSending = false;
            }
        } else if (error.request) {
            console.error('Aucune réponse reçue du serveur.', error.request);
            ElNotification({
              title: "Erreur",
              message: 'Aucune réponse reçue du serveur. Veuillez réessayer plus tard.',
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
        } else {
          console.error('Erreur:', error.message);
          ElNotification({
            title: "Erreur",
            message: error.message,
            type: "error",
            position: "bottom-right",
            duration: 0,
          });
          this.isLoginSending = false;
        }
      }
    },
    async sendRegistration() {
      try {
        if (!this.$refs.ruleRegistrationRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleRegistrationRef.validate((valid, fields) => {
            if (valid) {
              console.log("submit!");
              resolve(true);
            } else {
              console.log("error submit:" + reject, fields);
              resolve(false);
            }
          });
        });
        if (!check) return;
        this.isRegistrationSending = true;
        const response = await axios.post(
          `${config.API_BASE_URL}/user/add`,
          { data: this.formRegistration }
        );
        console.log(response.data);
        ElNotification({
          title: "Success",
          message:
            "Compte créé avec succès.",
          type: "success",
          position: "bottom-right",
          duration: 30000,
        });

        this.isRegistrationSending = false;
        this.showRegistrationPopup = false;
      } catch (error) {
        console.error("Error Send/Registration:", error);
      }
    },
    async sendReservation() {
      try {
        
        const tk = await this.recaptcha();
        console.log('reCAPTCHA token:', tk);

        const captcha = await axios.post(`${config.API_BASE_URL}/recaptcha`, {
          data: {token: tk},
        });

        if (captcha.status !== 200) {
          alert('Verification failed, are you robot ? Please try again.');
          return;
        }

        if (!this.$refs.ruleReservationRef) return;

        this.$refs.ruleReservationRef
          .validateField(['conditionCheck', 'rgpd'], async(valid) => {
            if(valid){
              console.log('Validation passed');
              
              this.isReservationSending = true;
              if(this.formReservation.price_unit > 0){
                this.formReservation['insurance_rate'] = 1 + this.insurance;
              } 
              const response = await axios.post(
                `${config.API_BASE_URL}/mail/reservation`,
                [this.formReservation, this.formReservation.how ? this.channels[this.formReservation.how - 1].name : null]
              );
              console.log(response.data);
              if(response.data.payment_url) window.open(response.data.payment_url, '_blank');
              this.$router.push("/form/quotation/succes");
              if(this.formReservation.price_unit > 0){
                window.dataLayer?.push({
                  event: "conversion",
                  send_to: "AW-16522766943/qCrwCKqZ-ewZEN_U1cY9",
                });
                window.dataLayer?.push({
                  event: "conversion_payment",
                  send_to: "AW-16522766943/qCrwCKqZ-ewZEN_U1cY9",
                });
                this.$emit("conversion_payment", "conversion_payment");
                alert("Veuillez effectuer le paiement sur la nouvelle page. Si le paiement est déjà effectué, veuillez attendre notre confirmation. Si votre navigateur bloque les fenêtres contextuelles, vous pouvez tout de même effectuer le paiement via le lien de paiement reçu par mail.");
              } else {
                window.dataLayer?.push({
                  event: "conversion",
                  send_to: "AW-16522766943/ZgX9CI2gkqgZEN_U1cY9",
                });
                window.dataLayer?.push({
                  event: "conversion_devis",
                  send_to: "AW-16522766943/ZgX9CI2gkqgZEN_U1cY9",
                });
                this.$emit("conversion_devis", "conversion_devis");
                alert("Demande envoyée, nous vous répondrons dans les plus brefs délais.");
              }

              this.isReservationSending = false;
              this.showReservationPopup = false;
            } else {
              return;
            }
          });
      } catch (error) {
        console.error("Error Send/Reservation:", error);
        this.isReservationSending = false;
        ElNotification({
          title: "Erreur",
          message:
            "Une erreur est survenue. Veuillez nous excuser pour ce désagrément. Nous vous tiendrons informé dès que le problème sera résolu.",
          type: "error",
          position: "bottom-right",
          duration: 0,
        });
      }
    },
    async sendEstimate() {
      try {
        const tk = await this.recaptcha();
        console.log('reCAPTCHA token:', tk);

        const captcha = await axios.post(`${config.API_BASE_URL}/recaptcha`, {
          data: {token: tk},
        });

        if (captcha.status !== 200) {
          alert('Verification failed, are you robot ? Please try again.');
          return;
        }

        if (!this.$refs.ruleEstimateRef) return;

        this.$refs.ruleEstimateRef.validateField(['conditionCheck', 'rgpd'], async(valid) => {
          if (valid) {
            
            this.isEstimateSending = true;
            if (this.formEstimate.depart_city && this.formEstimate.arrival_city) {
              this.formEstimate.distance = await this.calculateDistance(
                this.formEstimate.depart_city,
                this.formEstimate.arrival_city
              );
            }
            if (this.formEstimate.depart_return && this.formEstimate.arrival_return) {
              this.formEstimate.distance_return = await this.calculateDistance(
                this.formEstimate.depart_return,
                this.formEstimate.arrival_return
              );
            }
            if(this.formEstimate.copy_moto.length > 0) this.formEstimate.moto_list_return = this.formEstimate.moto_list;
            if(this.formEstimate.copy_passenger.length > 0) this.formEstimate.passenger_list_return = this.formEstimate.passenger_list;
            const response = await axios.post(
              `${config.API_BASE_URL}/mail/estimate`,
              [this.formEstimate, this.formEstimate.how ? this.channels[this.formEstimate.how - 1].name : null]
            );
            window.dataLayer?.push({
              event: "conversion",
              send_to: "AW-16522766943/ZgX9CI2gkqgZEN_U1cY9",
            });
            window.dataLayer?.push({
              event: "conversion_devis",
              send_to: "AW-16522766943/ZgX9CI2gkqgZEN_U1cY9",
            });
            this.$emit("conversion_devis", "conversion_devis");
            console.log(response.data);
            this.$router.push("/form/quotation/succes");
            alert("Demande envoyée, nous vous répondrons dans les plus brefs délais.");
            this.isEstimateSending = false;
          } else {
            return;
          }
        })
      } catch (error) {
        console.error("Error Send/Devis:", error);
        this.isEstimateSending = false;
        ElNotification({
          title: "Erreur",
          message:
            "Une erreur est survenue. Veuillez nous excuser pour ce désagrément. Nous vous tiendrons informé dès que le problème sera résolu.",
          type: "error",
          position: "bottom-right",
          duration: 0,
        });
      }
    },
    formatDate(dateString) {
      const year = dateString.slice(0, 4);
      const month = dateString.slice(4, 6).padStart(2, "0");
      const day = dateString.slice(6, 8).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    formatTimestamp(timestamp, nbMoto) {
      const [hours, minutes] = timestamp.split(":").map(Number);
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      if (nbMoto && nbMoto <= 3) date.setMinutes(date.getMinutes() - 30);
      else date.setMinutes(date.getMinutes() - 15);

      const formattedHours = String(date.getHours()).padStart(2, "0");
      const formattedMinutes = String(date.getMinutes()).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    },
    fullDateFR(dateString) {
      const months = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];

      const daysOfWeek = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];

      const year = dateString.substring(0, 4);
      const monthIndex = parseInt(dateString.substring(4, 6)) - 1;
      const day = dateString.substring(6, 8);

      const date = new Date(year, monthIndex, day);

      const dayOfWeek = daysOfWeek[date.getDay()];
      const month = months[monthIndex];

      return `${
        dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)
      } ${day} ${month} ${year}`;
    },
    checkDate(dateString) {
      const today = new Date();
      const todayYear = today.getFullYear();
      const todayMonth = today.getMonth() + 1;
      const todayDay = today.getDate();

      const year = parseInt(dateString.substring(0, 4));
      const month = parseInt(dateString.substring(4, 6));
      const day = parseInt(dateString.substring(6, 8));
      const date = new Date(year, month - 1, day);

      return date > new Date(todayYear, todayMonth - 1, todayDay);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.collapseStatus = Array.from({ length: this.pageSize }, () => false);
      this.$nextTick(() => {
        let targetElement = document.getElementById("results");
        if (targetElement)
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    },
    handleCurrentYYYYMM(type, page) {
      if(type == 2) {
        this.currentYYYYMM = page;
        if(page === 'Tous') this.tmpResults = JSON.parse(JSON.stringify(this.tmpResultsO));
        else this.tmpResults = this.tmpResultsO.filter(r => r.date.slice(0, 6) == page);
        this.$nextTick(() => {
          let targetElement = document.getElementById("results");
          if (targetElement)
            targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        });
      }
      if(type == 1){   
        const index = this.resultsYYYYMM.indexOf(this.visibleResultsYYYYMM[0]);

        let startIndex = index - 1;
        let endIndex = index + 3;

        if (index === 0 && this.resultsYYYYMM[0] != 'Tous') {
          startIndex = 0;
          endIndex = 4;
        } else if(index === 'Tous'){
          startIndex = 0;
          endIndex = 4;
        } 

        this.visibleResultsYYYYMM = this.resultsYYYYMM.slice(startIndex, endIndex + 1);
      }else if(type == 3){
        const index = this.resultsYYYYMM.indexOf(this.visibleResultsYYYYMM[0]);
        const totalLength = this.resultsYYYYMM.length;

        let startIndex = index + 1;
        let endIndex = index + 5;
        
        if (index === totalLength - 5) {
          startIndex = totalLength - 5;
          endIndex = totalLength - 1;
        }

        this.visibleResultsYYYYMM = this.resultsYYYYMM.slice(startIndex, endIndex + 1);
      }
    },
    switchTab(tab) {
      this.currentTab = tab;
      if (tab == 1) {
        this.tagBg1 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg2 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg3 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg4 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg5 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }

      if (tab == 2) {
        this.tagBg2 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg3 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg1 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg4 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg5 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }

      if (tab == 3) {
        this.tagBg3 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg2 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg1 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg4 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg5 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }

      if (tab == 4) {
        this.tagBg4 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg2 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg1 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg3 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg5 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }

      if (tab == 5) {
        this.tagBg5 =
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
        this.tagBg2 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg1 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg3 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        this.tagBg4 =
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
      }
    },
    nextStep(){
      if(this.stepReservation == 0 && this.formReservation.price_unit > 0) {
        let price_passenger = 0;
        this.formReservation.price_passenger = (this.formReservation.price_unit * this.formReservation.price_passenger_origin).toFixed(2);
        if(this.formReservation.passenger_nb > this.formReservation.moto_nb) price_passenger = (this.formReservation.passenger_nb - this.formReservation.moto_nb) * (this.formReservation.price_unit * this.formReservation.price_passenger_origin);
        this.formReservation.total = ((parseFloat(this.formReservation.price_unit) * Number(this.formReservation.moto_nb)) + parseFloat(price_passenger)).toFixed(2);
      }
      const reservationFieldsToValidate = {
        0: ['depart', 'arrival', 'date_depart', 'moto_nb', 'passenger_nb'],
        1: ['email', 'firstname', 'lastname', 'phone'],
        4: ['code', 'address_charge', 'address_discharge'],
        5: ['conditionCheck', 'rgpd']
      }
      const estimateFieldsToValidate = {
        0: ['date_depart', 'moto_nb', 'passenger_nb'],
        1: ['email', 'firstname', 'lastname', 'phone'],
        5: ['conditionCheck', 'rgpd']
      }

      if(this.stepReservation == 2 && this.formReservation.price_unit > 0){
        this.formReservation.moto_list.forEach((_, index) => {
          this.rulesReservation[`moto_list[${index}].model`] = [
            {
              required: true,
              message: "Champ requis",
              trigger: "blur",
            }
          ];
          this.$refs.ruleReservationRef
            .validateField(`moto_list[${index}].model`, (valid) => {
              if(valid){
                console.log('Validation passed');
              } else {
                return;
              }
            });
        });
      }

      if(this.stepReservation == 3){
        this.formReservation.passenger_list.forEach((_, index) => {
          this.rulesReservation[`passenger_list[${index}].firstname`] = [
            {
              required: true,
              message: "Champ requis",
              trigger: "blur",
            }
          ];
          this.rulesReservation[`passenger_list[${index}].lastname`] = [
            {
              required: true,
              message: "Champ requis",
              trigger: "blur",
            }
          ];
          this.rulesReservation[`passenger_list[${index}].email`] = [
            {
              required: true,
              message: "Veuillez saisir une adresse E-mail valide",
              validator: (rule, value, callback) => {
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!emailPattern.test(value) && !value) {
                  callback(new Error(`Adresse e-mail non valide`));
                }
                callback(); 
              },
              trigger: ["blur", "change"],
            }
          ];
          ['firstname', 'lastname', 'email'].forEach((field) => {
            this.$refs.ruleReservationRef
              .validateField(`passenger_list[${index}].${field}`, (valid) => {
                if(valid){
                  console.log('Validation passed');
                } else {
                  return;
                }
              });
          });
        });
      }

      const fields = this.formReservation.price_unit > 0 ? reservationFieldsToValidate[this.stepReservation] : estimateFieldsToValidate[this.stepReservation];

      this.$refs.ruleReservationRef.validateField(fields, (valid) => {
        if (valid) {
          if(this.formReservation.moto_nb == 0 && this.stepReservation == 1 && this.formReservation.passenger_nb > 0) this.stepReservation = this.stepReservation + 2;
          else if(this.formReservation.moto_nb == 0 && this.stepReservation == 1 && this.formReservation.passenger_nb == 0) this.stepReservation = this.stepReservation + 3;
          else if(this.stepReservation == 2 && this.formReservation.passenger_nb == 0) this.stepReservation = this.stepReservation + 2;
          else this.stepReservation++;
          
        } else {
          return;
        }
      })
    },
    nextStepEstimate(){
      const estimateFieldsToValidate = {
        0: ['depart_date', 'moto_nb', 'passenger_nb'],
        1: ['email', 'firstname', 'lastname', 'phone'],
        5: ['conditionCheck']
      }
      if(this.formEstimate.type != 'oneWay') estimateFieldsToValidate[0] = ['depart_date', 'date_return', 'moto_nb', 'passenger_nb', 'moto_nb_return', 'passenger_nb_return'];
      const fields = estimateFieldsToValidate[this.stepEstimate];
      if(this.stepEstimate == 4){
        if(this.formEstimate.copy_moto.length > 0) this.formEstimate.moto_list_return = this.formEstimate.moto_list;
        if(this.formEstimate.copy_passenger.length > 0) this.formEstimate.passenger_list_return = this.formEstimate.passenger_list;
      }

      this.$refs.ruleEstimateRef.validateField(fields, (valid) => {
        if (valid) {
          if(!this.formEstimate.moto_nb && !this.formEstimate.moto_nb_return && this.stepEstimate == 1 && this.formEstimate.passenger_nb > 0) this.stepEstimate = this.stepEstimate + 2;
          else if(!this.formEstimate.moto_nb && !this.formEstimate.moto_nb_return && this.stepEstimate == 1 && !this.formEstimate.passenger_nb && !this.formEstimate.passenger_nb_return) this.stepEstimate = this.stepEstimate + 3;
          else if(this.stepEstimate == 2 && !this.formEstimate.passenger_nb && !this.formEstimate.passenger_nb_return) this.stepEstimate = this.stepEstimate + 2;
          else this.stepEstimate++;
        } else {
          return;
        }
      })
    },
    convertToFRMonthYear(item){
      let monthFR = {
        '01': 'Janvier',
        '02': 'Février',
        '03': 'Mars',
        '04': 'Avril',
        '05': 'Mai',
        '06': 'Juin',
        '07': 'Juillet',
        '08': 'Août',
        '09': 'Septembre',
        '10': 'Octobre',
        '11': 'Novembre',
        '12': 'Décembre'
      }
      return item == 'Tous' ? 'Tous' : monthFR[item.slice(4, 6)] + ' ' + item.slice(0, 4)
    }
  }
};
</script>

<style scoped type="scss">
.el-col-1,
.el-col-2,
.el-col-3,
.el-col-4,
.el-col-5,
.el-col-6,
.el-col-7,
.el-col-8,
.el-col-9,
.el-col-10,
.el-col-11,
.el-col-12,
.el-col-13,
.el-col-14,
.el-col-15,
.el-col-16,
.el-col-17,
.el-col-18,
.el-col-19,
.el-col-20,
.el-col-21,
.el-col-22,
.el-col-23,
.el-col-24 {
  display: inline-block;
  width: 100%;
  align-self: start;
  vertical-align: top;
}
>>>.el-checkbox {
  display: inline;
}
>>>.el-checkbox__label{
  text-wrap: auto;
}
>>>.el-dialog__header{
  background: #000047;
  border-radius: 10px;
  padding-top: 20px;
  font-weight: 800;
  text-transform: uppercase;
}
>>>.el-dialog__headerbtn{
  font-size: 30px;
  margin-top: 10px;
}
>>>span.el-dialog__title{
  color: white !important;
}
.sticky{
  width: 100%;
  position: fixed;
  z-index: 999;
  justify-self: anchor-center;
}
.btn-option{
  all: unset;
  padding: 10px 20px;
  background-color: #00004728;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.btn-option:hover{
  padding: 10px 20px;
  background-color: #e6007f3a;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #ffffffb0;
  z-index: 5;
}

.loading-spinner {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.timeline-bloc {
  display: flex;
}
.time {
  color: #909399;
  font-size: 12px;
  margin: -1px 10px 0 0;
}
.timeline-comment {
  margin: 0;
  font-size: 12px;
  color: #909399;
  line-height: none;
}

>>> .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
  display: inline;
}
>>> .el-timeline .timeline-bloc:last-child .el-timeline-item__tail {
  border-color: white;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.pagination-date{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  button{
    color: #000047;
    margin: 0 4px;
    padding: 3px 11px;
    border-radius: 5px;
    border: 1px solid #000047;
    font-size: 18px;
    cursor: pointer;
  }
  .active{
    background-color: #e6007e;
    color: white;
    border: 2px solid rgb(145, 145, 145);
    text-shadow: none;
  }
  
  .pagination-btn {
    font-size: 20px;
    border: none;
    border-radius: 2px;
    color: #000047;
  }
  .pagination-btn:disabled{
    color: rgb(172, 172, 172);
    cursor: initial;
  }
}
.autocomplete >>> ul {
  z-index: 3;
  color: dimgray;
}
.btn-trans-blue {
  background-color: transparent;
  color: #000047;
  font-size: 20px;
  border: 2px solid #000047;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-trans-blue:hover {
  background-color: #000047;
  color: white;
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.flex-col {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}
.form {
  background: #ffffff75;
  border-radius: 20px;
  margin-top: 50px;
  width: -webkit-fill-available !important;
  color: #000047;
  font-size: 20px;
  padding: 50px 50px 0 50px;
  div {
    position: relative;
  }
}
.w-1 {
  width: 100%;
}
.w-2 {
  width: 50%;
  text-align: center;
}

.border {
  text-align: center;
  text-shadow: -1px -1px 0 #000047, 1px -1px 0 #000047, -1px 1px 0 #000047,
    1px 1px 0 #000047;
}
.border-l{
  text-shadow: 
    5px 0 black,  /* 右 */
    -5px 0 black, /* 左 */
    0 5px black,  /* 下 */
    0 -5px black, /* 上 */
    3px 3px black,  /* 右下 */
    -3px -3px black,/* 左上 */
    3px -3px black, /* 右上 */
    -3px 3px black; /* 左下 */
}
.border-white {
  text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff;
}
.cta-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  img{
    margin: 10px 20px;
    max-height: 200px;
  }
  .cta-btn-txt{
    color: white;
    position: absolute;
    top: 18%;
    font-size: 25px;
    line-height: 50px;
    a:hover{
      cursor: pointer;
      font-weight: 900;
      -webkit-text-stroke: 1px black;
    }
  }
  .or{
    position: absolute;
    font-size: 80px;
    top: 66px;
    color: white;
    -webkit-text-stroke: 4px black;
    font-weight: 800;
    left: 2%;
  }
}
.rose {
  color: #e6007e !important;
}
.white {
  color: white;
}
.number {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #000047;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 400;
  margin-right: 10px;
}
.partners {
  margin: 40px 0 15px 0;
  background-color: #000047;
  text-align: center;
}
.partners-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  img {
    margin: 10px;
    max-height: 80px;
  }
  margin-bottom: 10px;
}
.price-details{
  background: whitesmoke;
  padding: 20px;
  border-radius: 10px;
}
>>> .el-alert__content {
  line-height: normal;
}

.roundTrip{
  padding: 10px;
  border: solid 2px #000047;
  border-radius: 20px;
  background-color: #a8a47a62;
  margin-bottom: 15px;
}

/**Route */
.route {
  background: linear-gradient(45deg, #00474491, #47450091);
  width: 100%;
  color: white;
  display: flex;
}
.route-search {
  display: inline-block;
  width: 30%;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background: linear-gradient(45deg, #ff009796, #050035a3);
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
}
.route-search-partners {
  display: block;
  img {
    max-height: 80px;
  }
}
.route-search-options {
  height: -webkit-fill-available;
  display: flex;
  width: -webkit-fill-available;
  align-items: center;

  .route-search-options-card {
    background: #1d2327;
    border-radius: 20px;
    width: 100%;
    padding: 30px;
    text-align: center;
    margin-top: 30px;
  }
}
.route-search-container {
  max-height: 450px;
  position: fixed;
  top: 35%;
  z-index: 2;
  border-radius: 20px;
  padding: 20px;
  background: #ffffff3d;
}
.route-results {
  display: inline-block;
  position: relative;
  width: 70%;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background: left;
  background-color: #ffffffbf;
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
}
.user-routes {
  display: inline-block;
  position: relative;
  width: 100%;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  overflow-y: auto;
  background: left;
  background-color: #ffffffbf;
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
}
.results {
  margin-top: 30px;
  width: 100%;
  max-width: 950px;
  text-align: -webkit-center;
}
>>> .el-pagination {
  place-content: center;
}
>>> .el-pagination.is-background .el-pager li.is-active {
  background-color: #000047;
}
.overlay-blue {
  color: #ffffff82;
  font-size: 60px;
  font-weight: 600;
  position: absolute;
  background: linear-gradient(25deg, #11237db3, #474500b0);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    transform: rotateZ(338deg);
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.card-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-route {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 5px 5px 1px #00000030;
  border: 1px solid #9f9f9f;
  .el-collapse {
    border: none !important;
    --el-collapse-header-height: auto;
    --el-collapse-header-font-size: 15px;
    text-align: left !important;
    padding: 10px 30px 10px 30px;
    >>> .el-collapse-item__header {
      text-align: left !important;
      font-weight: 400;
      border: none;
    }
    .btn-blue {
      margin-left: auto;
      font-size: 20px;
      cursor: pointer;
    }
    li,
    .el-timeline-item {
      cursor: auto;
    }
  }
}
>>> .el-pager {
  position: relative;
}
>>> .el-dialog__body {
  display: flex;
  justify-content: center;
}
.btn-blue {
  margin-left: auto;
  font-size: 20px;
  cursor: pointer;
}
.hr-line {
  display: flex;
  width: 100%;
  color: white;
  font-size: 40px;
  flex-direction: column-reverse;
  margin-bottom: 30px;
  text-shadow: -2px -2px 0 #000047, 2px -2px 0 #000047, -2px 2px 0 #000047,
    2px 2px 0 #000047;
}
.required-label::before {
  content: '*';
  color: red;
  float: inline-start;
}
.card-route-header {
  padding-left: 30px;
  min-height: 58px;
  align-items: center;
  background: #e6007e;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  .card-route-header-info {
    display: flex;
    align-self: stretch;
  }
}
.card-route-header-info-moto {
  background: #44bbc3;
  color: white;
  padding: 5px 10px;
  border-radius: 0 20px 0 20px;
  text-align: center;
  margin-right: -1px;
  font-weight: 300;
  place-content: center;
}
.route-options-discount {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}
.card-discount {
  font-size: 18px;
  background: #c50909;
  border-radius: 50px 5px 5px 50px;
  font-weight: 600;
  align-self: center;
  padding: 2px 10px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.card-discount-blue {
  background-color: #000047;
  font-size: 15px;
  border-radius: 50px;
  font-weight: 600;
  align-self: center;
  padding: 2px 10px;
  color: white;
}

.card-discount-red {
  background-color: #c50909;
  font-size: 15px;
  border-radius: 50px;
  font-weight: 600;
  align-self: center;
  padding: 2px 10px;
  color: white;
}
.card-route-info {
  text-align: left;
  background-color: whitesmoke;
  background-size: contain;
  background-position: bottom;
  font-size: 25px;
  padding: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-position: right;
  .card-route-info-first {
    display: flex;
    justify-content: space-between;
  }
  .card-route-info-second {
    font-size: 15px;
  }
  .card-route-info-route {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
.card-route-info-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.card-route-info-price{
  font-size: 30px;
  margin-left: auto;
  color: #000047;
  b {
    color: #c50909;
  }
}
.route-plan {
  margin-right: 30px;
  display: flex;
  background: #f3f3f3;
  border-radius: 20px;
  position: relative;
  padding: 30px;
  margin: 30px 0;
  ul {
    position: relative;
    padding: inherit;
    user-select: none;
  }
  >>> ul {
    position: relative;
    padding: inherit;
  }
  >>> li {
    background-color: transparent;
    border: none;
  }
}
.route-options {
  margin: 0 auto 0 auto;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    display: inline;
  }
}
.route-how {
  margin-right: 30px;
  position: relative;
  font-size: 20px;
  padding: 0 30px;
}
.route-eco {
  position: relative;
  font-size: 20px;
  border-radius: 20px;
  border: 2px solid black;
  padding: 30px;
  margin-bottom: 50px;
}
.submenu {
  background: #1d2327;
  height: 5px;
  width: 100%;
  z-index: 2;
}
.submenu-bottom {
  width: 100%;
  height: 40px;
  background: #1d2327;
  display: flex;
  position: sticky;
  border-bottom: 2px solid #e6007e;
}
.submenu-option {
  margin: 10px 30px;
  color: white;
  cursor: pointer;
  :hover {
    color: #44bbc3;
  }
}
.circle {
  width: 40px;
  height: 40px;
  margin: 10px 0;
  border-radius: 50%;
}

.about2 {
  background: linear-gradient(45deg, #00474491, #47450091);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px;
}
.window {
  width: 100%;
  box-sizing: border-box;
}
.tags {
  padding-left: 100px;
}
.tag {
  position: relative;
  z-index: 0;
  transition: font-size 0.5s ease;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400px;
  width: 200px;
  padding: 15px 25px 1px 25px;
  border-radius: 10px 10px 0 0;
  border: none;
  border-left: 1px solid #000047;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.5);
  transform: perspective(5px) rotateX(1.5deg);
  transform-origin: bottom;
  div {
    transform: perspective(5px) rotateX(-1.5deg);
    transform-origin: bottom;
  }
}
.tag-child {
  margin-left: -50px;
}
.menu-auth {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: white;
  border-radius: 10px;
  border: solid 2px white;
  height: min-content;
  padding: 8px;
  margin-right: 10px;
  button {
    background-color: transparent;
    border: none;
    color: white;
    white-space: nowrap;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
  }
  button:hover {
    color: #e6007e;
  }
}

.menu a {
  color: white;
  margin: 0 10px;
}
.nav {
  padding-top: 25px;
  margin-bottom: 25px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: start;
  overflow-x: auto;
}
.nav::-webkit-scrollbar {
  display: none;
}
.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.menu-mobile{
  display: none;
}
.footer {
  background-color:#ffffff94;
  height: 100vh;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.footer-title {
  font-size: 20px;
  color: #e6007e;
  font-weight: 400;
  text-align: center;
}
/**Tag-8 */
.home-tag-8 {
  height: 100vh;
  box-sizing: border-box;
  padding: 50px;
  background-image: url("../assets/images/home-7.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
.extra-news-list {
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* 隐藏IE和Edge的滚动条 */
  scrollbar-width: none; /* 隐藏Firefox的滚动条 */
}

.extra-news-list::-webkit-scrollbar {
  display: none; /* 隐藏Chrome和Safari的滚动条 */
}

.scrollable-container {
  display: flex;
  flex-wrap: nowrap;
}

.card-extra-news {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  flex: 0 0 auto;
  margin-right: 20px; /* 添加间距 */
  width: 300px; /* 设置每个卡片的宽度 */
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
}
a {
  text-decoration: none;
  color: inherit;
}
/**tag-6 */

.home-tag-6 {
  height: 100vh;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000047;
  background-image: url("../assets/images/ui/home-5.png");
  background-position: start;
  background-size: cover;
}
/**tag-7 */
.home-tag-7 {
  height: 100vh;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
}
.sponsor-list {
  width: 2000px;
  max-width: 100%;
  overflow: hidden; /* 隐藏溢出内容 */
  position: relative; /* 相对定位 */
  display: -webkit-inline-box;
  -webkit-box-align: center;
  box-sizing: border-box;
}

.slides-container {
  display: flex; /* 使用 flex 布局 */
  transition: transform 0.5s ease; /* 添加过渡效果 */
  padding: 0 100px;
  overflow: hidden;
  width: 400px;
}

.slides-container img {
  width: 100%; /* 图片宽度占满容器 */
  height: fit-content;
}

/**tag-5 */
.home-tag-5-list {
  display: flex;
  flex-wrap: wrap;
}

.card-price {
  display: flex;
  width: calc(50% - 20px); /* 每个卡片的宽度为父容器的一半 */
  margin: 10px;
  border-radius: 10px;
  background-color: white;
}

.card-image {
  flex: 1;
  border-radius: 10px;
  align-self: center;
  margin-left: 10px;
  max-width: 200px;
}

.card-image img {
  max-width: 200px; /* 图片宽度占满容器 */
  height: auto; /* 高度自适应 */
  border-radius: 10px;
}

.card-content {
  flex: 1;
  padding: 0 10px;
  align-self: center;
}
.home-tag-5 {
  height: 100vh;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000047;
}

.firstname {
  color: white;
  font-weight: 400;
  font-size: 40px;
}
.lastname {
  color: white;
  font-weight: 400;
  font-size: 20px;
}
.avatar {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 50%; /* 使图像成为圆形 */
}
/**Tag-4 */
.card {
  background-color: #000047;
  background-image: url("../assets/images/ui/aile-bleu-s.png");
  width: 250px;
  height: 400px;
  background-size: 100% auto;
  background-position: start;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin: 0 10px;
  display: inline-block;
}
.card-bg {
  display: flex;
  flex-direction: column;
  height: 100%;
  place-content: center;
  align-items: center;
}

.card:hover {
  background-image: url("../assets/images/ui/aile-rose.png");
}
.home-tag-4 {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  box-sizing: border-box;
  background-image: url("../assets/images/ui/aile-bleu.png");
  background-position: center;
}

/**Tag-3 */
.home-tag-3 {
  height: 100vh;
  box-sizing: border-box;
}
.material-symbols-outlined {
  font-size: 30px;
  color: white;
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 20;
}
.material-symbols-outlined:hover {
  cursor: default;
}

.home-tag-3-loop {
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: transform 0.5s, font-size 0.5s;
  height: 33vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  font-size: 18px;
}

.active {
  font-size: 24px; /* 点击后放大文字 */
  text-shadow: -2px -2px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* 描边效果，可以根据需要调整阴影的位置和颜色 */
}

.slideshow {
  padding: 50px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh; /* 背景图容器的高度 */
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out; /* 背景图过渡效果 */
  position: absolute; /* 相对定位 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
}
/*Tag-2*/
.home-tag-2 {
  width: 100%; /* 宽度占满父容器 */
  height: 100vh; /* 高度占满视口 */
  overflow: hidden; /* 隐藏溢出内容 */
  position: relative; /* 相对定位，用于放置视频 */
}

.home-tag-2 video {
  position: absolute; /* 绝对定位，覆盖整个容器 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 视频填充整个容器 */
}

.overlay {
  position: absolute; /* 相对定位 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ); /* 添加叠加的半透明背景 */
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  text-align: center;
}

/*tag-1*/
#homeFilter {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  position: relative;
  >>> ul {
    top: 50px;
  }
}
.btn-rose {
  border: none;
  background-color: #e6007e;
  color: white;
  border-radius: 5px;
  padding: 13px 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}

.btn-trans-white {
  font-family: "Roboto Condensed", sans-serif;
  background-color: transparent;
  color: white;
  font-size: 20px;
  margin-top: 20px;
  width: 30vw;
  border: 2px solid white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s; /* 添加过渡效果 */
}
.btn-trans-white:hover {
  background-color: white; /* 悬浮时背景色变为白色 */
  color: black; /* 悬浮时文字变为透明 */
}
.moto-bg {
  background-position: center right;
  background-image: url("../assets/images/bg-moto-r.jpg");
  height: 100vh;
  background-size: cover;
  font-family: "Roboto Condensed", sans-serif;
  overflow-y: auto;
  box-sizing: border-box;
  box-shadow: inset 5px 5px 200px 20px;
}
.home-tag-1-moto {
  background-position: bottom center; /* 垂直和水平居中 */
  background-image: url("../assets/images/iStock-905788418.png");
  height: 30vh;
  background-size: cover;
  padding: 50px;
  box-sizing: border-box;
  box-shadow: inset 5px 5px 200px 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.home-tag-1-filter {
  max-width: 50vw;
  position: static;
}
.home-tag-1-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  float: right;
  height: 100%;
}
.home-tag-1-bottom {
  height: 100%;
}
.title {
  color: white;
  font-size: 50px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 55px;
  margin-bottom: 20px;
  text-shadow: -1px -1px 0 #000047, 1px -1px 0 #000047, -1px 1px 0 #000047, 1px 1px 0 #000047;
}

.details {
  color: gray;
  font-size: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}
.home-tag-1-top-details {
  text-align: right;
}
.home-tag-1-bottom-details {
  text-align: left;
}
@media screen and (max-width: 768px){
  .card-route-info-footer-txt{
    div{
      max-width: 320px;
    }
  }
}
@media screen and (max-width: 1316px) {
  .route {
    flex-wrap: wrap;
  }
  .route-search {
    width: 100%;
    padding: 50px 10px;
  }
  .route-search-partners {
    display: none;
  }
  .route-results {
    width: 100%;
    padding: 50px 10px;
    box-sizing: border-box;
  }
  .route-search-container {
    position: relative;
    top: auto;
  }
  .route-plan {
    flex-wrap: wrap;
  }
  .route-options {
    width: 100%;
  }
  .card-route-header {
    padding-left: 10px;
  }
  .card-route-header-info {
    align-self: stretch;
  }
  .card-route-header-info-moto {
    place-content: center;
    width: auto;
  }
  .el-col {
    max-width: 99%;
  }
  .el-form {
    padding: 20px !important;
  }
  >>> .el-dialog {
    width: 98%;
  }
  .tags {
    white-space: nowrap;
    overflow-x: auto;
    padding-left: 0;
  }
  .tags::-webkit-scrollbar {
    display: none;
  }
  .nav {
    justify-content: left;
  }
  .nav img {
    margin-left: 10px !important;
  }
  .footer img {
    width: 100%;
    max-width: 600px;
  }
}

@media screen and (max-width: 512px) {
  .cta-btn{
    zoom: 0.7;
  }
  .lang{
    display: none;
  }
  .about1{
    padding: 10px 20px 20px 20px !important;
  }
  .about2{
    padding: 50px 20px 20px 20px;
    div{
      justify-content: center;
      flex-wrap: wrap;
      width: 100% !important;
    }
    video, img{
      width: 100% !important;
      max-width: none !important;
      max-height: none !important;
    }
  }
  .title{
    font-size: 30px;
    line-height: 40px;
  }
  .title.sub{
    font-size: 18px !important;
    line-height: 25px !important;
  }
  .el-col-6{
    width: 50% !important;
  }
  .card-route-info-footer-txt{
    min-width: 165px !important;
  }
  .card-route-info-price {
    font-size: 25px !important;
  }
  .menu-web{
    display: none;
  }
  .menu a{
    margin: 0 5px !important;
  }
  .menu-mobile{
    display: inline-block;
    border-radius: 30px;
    margin: -15px 0 0 15px;
    width: 65px;
  }
  .nav{
    justify-content: center;
    img{
      max-width: 200px !important;
    }
  }
  .pagination-date{
    flex-wrap: wrap;
  }
}
</style>
