<template>
  <div class="container-login">
    <a href="/" target="_self"
      ><img
        src="../assets/images/ui/logo.png"
        style="width: 350px"
    /></a>
    <el-form
      ref="ruleLoginRef"
      style="max-width: 600px; padding: 50px"
      :model="formLogin"
      :rules="rulesLogin"
      label-width="auto"
      label-position="top"
      v-loading="isLoginSending"
      status-icon
    >
      <el-form-item>
        <el-col :span="24">
          <el-form-item label="E-mail" required prop="email">
            <el-input type="email" v-model="formLogin.email" required />
          </el-form-item> </el-col
        ><br /><br /><br /><br />
        <el-col :span="24">
          <el-form-item label="Mot de passe" required prop="password">
            <el-input type="password" v-model="formLogin.password" required />
          </el-form-item>
        </el-col>
        <el-col :span="24"></el-col>
        <a href="/forgotpsw" target="_blank">Mot de passe oublié ?</a>
      </el-form-item>
      <el-col :span="24"></el-col>
      <button class="btn-trans-blue w-1" type="button" @click="login()">
        Se connecter
      </button>
    </el-form>
    <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import Cookies from "js-cookie";
import { ElNotification } from "element-plus";

export default {
  name: "LoginPage",
  data() {
    return {
      config: config,
      errorMessage: "",
      formLogin: {
        email: "",
        password: ""
      },
      isLoginSending: false,
      rulesLogin: {
        email: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Champ requis",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async login() {
      try {
        if (!this.$refs.ruleLoginRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleLoginRef.validate((valid, fields) => {
            if (valid) {
              console.log("submit!");
              resolve(true);
            } else {
              console.log("error submit:" + reject, fields);
              resolve(false);
            }
          });
        });
        if (!check) return;
        this.isLoginSending = true;
        const response = await axios.post(
          `${this.config.API_BASE_URL}/user/login`,
          {
            data: this.formLogin,
          }
        );
        if (response.data) {
          if(response.data.user.type == 3){
            ElNotification({
              title: "Erreur",
              message: "Vous n'avez pas le droit.",
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
            return;
          }
          else{
            this.token = response.data.token;
            Cookies.set("authToken", response.data.token, {
              expires: 1,
              secure: true,
              sameSite: "Strict",
            });
          }
        }
        ElNotification({
          title: "Success",
          message: "Connecté(e) avec succès.",
          type: "success",
          position: "bottom-right",
          duration: 10000,
        });
        this.isLoginSending = false;
        this.$router.push("/admin");
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            console.error("Email OU mot de passe incorrect.");
            ElNotification({
              title: "Erreur",
              message: "Email OU mot de passe incorrect.",
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
          } else {
            console.error("Erreur:", error.response.data.message);
            ElNotification({
              title: "Erreur",
              message: error.response.data.message,
              type: "error",
              position: "bottom-right",
              duration: 0,
            });
            this.isLoginSending = false;
          }
        } else if (error.request) {
          console.error("Aucune réponse reçue du serveur.", error.request);
          ElNotification({
            title: "Erreur",
            message:
              "Aucune réponse reçue du serveur. Veuillez réessayer plus tard.",
            type: "error",
            position: "bottom-right",
            duration: 0,
          });
          this.isLoginSending = false;
        } else {
          console.error("Erreur:", error.message);
          ElNotification({
            title: "Erreur",
            message: error.message,
            type: "error",
            position: "bottom-right",
            duration: 0,
          });
          this.isLoginSending = false;
        }
      }
    },
  },
};
</script>
<style scoped type="scss">
.container-login{
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  align-content: center;
  justify-items: center;
  padding: 30px;
  background: #000047;
  form {
    background: white;
    margin-top: 20px;
    padding: 30px;
    border-radius: 30px;
    text-align: center;
  }
}
</style>