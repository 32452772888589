<template>
  <div style="width: -webkit-fill-available;">
      <div class="nav">
          <a href="/" target="_self"
          ><img
              src="../assets/images/ui/logo.png"
              style="max-width: 250px"
          /></a>
      </div>
      <div class="container">
        <div class="title">Formulaire envoyé</div>
        <div class='w-1'>
          <div class="txt">
            Demande envoyée, nous vous répondrons dans les plus brefs délais. Redirection vers la page précédente en cours......
          </div>
        </div>
      </div>
      <div class="footer">
        <div style="margin: 50px">
          <div
            style="
              font-size: 20px;
              color: #e6007e;
              font-weight: 400;
              text-align: center;
            "
          >
            SUIVEZ-NOUS
            <br />
            <a href="https://fr.linkedin.com/company/symone" target="_blank"
              ><i
                class="fab fa-linkedin"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://twitter.com/SymoneMobility" target="_blank"
              ><i
                class="fab fa-twitter"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a
              href="https://www.instagram.com/symonemobility/?hl=fr"
              target="_blank"
              ><i
                class="fab fa-instagram"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
              ><i
                class="fab fa-facebook"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <br />
            <br />
            NOUS CONTACTER
            <br />
            <span style="color: #000047"
              >contact@symone.fr<br />06 17 33 43 93</span
            >
            <br />
            <br />
            ÉCRIVEZ-NOUS
            <br />
            <a href="/contact" target="_blanc"
              ><span style="color: #000047">Contact</span></a
            >
          </div>
          <div
            style="
              font-size: 20px;
              color: #000047;
              font-weight: 400;
              text-align: center;
            "
          >
            <br />
            <a href="/" target="_blank">Bienvenue à bord</a>
            <br /><br />
            <a href="/moto" target="_blank">Symone à moto</a>
            <br /><br />
            <a href="/#home-tag-2" target="_self"
              >Une nouvelle expérience de la route</a
            >
            <br /><br />
            <a
              href="/condition"
              target="_blank"
              >Conditions Générales de Vente</a
            >
            <br />
          </div>
        </div>
        <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
      </div>
    </div>
</template>

<script>

export default {
  name: "FormContactSucces",
  components: { 
  },
  data() {
    return {
    };
  },
  created(){
  },
  watch: {
  },
  mounted(){
    setTimeout(() => {
      this.$router.push('/contact');
    }, 8000)
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
*{
  text-decoration: none;
}
.el-checkbox{
  white-space: normal;
  height: auto;
}
.el-col-1, .el-col-2, .el-col-3, .el-col-4, .el-col-5, .el-col-6, .el-col-7, .el-col-8, .el-col-9, .el-col-10,.el-col-11, .el-col-12, .el-col-13, .el-col-14, .el-col-15, .el-col-16, .el-col-17, .el-col-18, .el-col-19, .el-col-20, .el-col-21, .el-col-22, .el-col-23, .el-col-24 {
  display: inline-block;
  width: 100%;
}
.loading-container, .loading-container-devis, .loading-container-reservation{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #ffffffb0;
  z-index: 5;
}

.loading-spinner, .loading-spinner-devis, .loading-spinner-reservation {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
.border {
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
  1px 1px 0 #000;
}
.container{
  color: #000047;
width: 100%;
}

.btn-trans-blue {
  background-color: transparent;
  color: #000047;
  font-size: 20px;
  border: 2px solid #000047;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-trans-blue:hover {
  background-color: #000047;
  color: white;
}
.title{
  color: #000047;
  font-size: 50px;
  font-weight: 600;
  
background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  min-height: 250px;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  
  display: flex;    
  align-items: center;
  text-align: center;
  justify-content: center;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nav {
  padding: 25px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #000047;
}
.txt{
max-width: 1000px;
text-align: left;
font-size: 25px;
*{
  margin: revert;
}
img{
  max-width: 100%;

}
}
.w-1{
width: 100%;
text-align: -webkit-center;
}
.menu {
display: flex;
}
.menu a {
color: white;
margin: 0 10px;
}
.details {
max-width: 40vw;
color: #dddddd;
font-size: 20px;
font-weight: 300;
text-transform: uppercase;
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
  1px 1px 0 #000;
}
.cover img{
max-width: 60vw;
max-height: 50vh;
}

.footer {
height: 100vh;
width: 100%;
padding: 50px;
box-sizing: border-box;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
img{
  max-width: 100%;
}
}
a{
color: #e6007e;
}
.form{
  padding: 50px 10px;
}
</style>
