<template>
  <div style="width: -webkit-fill-available;">
      <div class="nav">
          <a href="/" target="_self"
          ><img
              src="../assets/images/ui/logo.png"
              style="max-width: 250px"
          /></a>
      </div>
      <div class="container">
        <div class="title">Nous contacter</div>
        <div class='w-1'>
          <div class="loading-container" v-if="isSending">
                <div class="loading-spinner"></div>
              </div>
          <div class="txt">
            <el-form
                  ref="ruleRef"
                  style="max-width: 1000px"
                  :model="form"
                  :rules="rules"
                  label-width="auto"
                  label-position="top"
                  class="form"
                  status-icon
                >
                  <el-col :span="12">
                    <el-form-item label="Prénom" required prop='firstname'>
                      <el-input type="text" v-model="form.firstname"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Nom" required prop='lastname'>
                      <el-input type="text" v-model="form.lastname" required />
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span="18">
                    <el-form-item label="E-mail" required prop='email'>
                      <el-input
                        type="email"
                        v-model="form.email"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="Téléphone" prop='phone'>
                      <el-input type="text" v-model="form.phone"/>
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <el-col :span="24">
                    <el-form-item label="Commentaire">
                      <el-input
                        type="textarea"
                        v-model="form.comment"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span='24'>
                    <el-form-item label="Accord RGPD" required prop='required'>
                      <el-checkbox-group v-model="form.required">
                        <el-checkbox value="true" name="required">
                          En soumettant ce formulaire de contact, vous consentez à ce que nous recueillions et traitions vos données personnelles uniquement dans le but de répondre à votre demande. Vos informations ne seront pas utilisées à d'autres fins ni partagées avec des tiers sans votre consentement explicite. Pour en savoir plus sur notre politique de confidentialité et vos droits en matière de protection des données, veuillez consulter notre page dédiée à la vie privée.
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                  <br><br>
                  <button
                    class="btn-trans-blue w-1"
                    type="button"
                    @click="sendForm()"
                  >
                    Envoyer
                  </button>
                </el-form>
          </div>
        </div>
      </div>
      <div class="footer">
        <div style="margin: 50px">
          <div
            style="
              font-size: 20px;
              color: #e6007e;
              font-weight: 400;
              text-align: center;
            "
          >
            SUIVEZ-NOUS
            <br />
            <a href="https://fr.linkedin.com/company/symone" target="_blank"
              ><i
                class="fab fa-linkedin"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://twitter.com/SymoneMobility" target="_blank"
              ><i
                class="fab fa-twitter"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a
              href="https://www.instagram.com/symonemobility/?hl=fr"
              target="_blank"
              ><i
                class="fab fa-instagram"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
              ><i
                class="fab fa-facebook"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <br />
            <br />
            NOUS CONTACTER
            <br />
            <span style="color: #000047"
              >contact@symone.fr<br />06 17 33 43 93</span
            >
            <br />
            <br />
            ÉCRIVEZ-NOUS
            <br />
            <a href="/contact" target="_blanc"
              ><span style="color: #000047">Contact</span></a
            >
          </div>
          <div
            style="
              font-size: 20px;
              color: #000047;
              font-weight: 400;
              text-align: center;
            "
          >
            <br />
            <a href="/" target="_blank">Bienvenue à bord</a>
            <br /><br />
            <a href="/moto" target="_blank">Symone à moto</a>
            <br /><br />
            <a href="/#home-tag-2" target="_self"
              >Une nouvelle expérience de la route</a
            >
            <br /><br />
            <a
              href="/condition"
              target="_blank"
              >Conditions Générales de Vente</a
            >
            <br />
          </div>
        </div>
        <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
      </div>
    </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';
import { useReCaptcha } from 'vue-recaptcha-v3'

export default {
  name: "ContactPage",
  components: { 
  },
  data() {
    return {
      config: config,
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        comment: '',
        required: []
      },
      isSending: false,
      rules:{
        firstname: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        lastname: [
          { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Veuillez saisir une adresse E-mail valide', trigger: ['blur', 'change'] },
        ],
        required: [
          {
            type: 'array',
            required: true,
            message: 'Veuillez cocher cette case',
            trigger: 'change',
          },
        ],
      },
    };
  },
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    const recaptcha = async () => {
      await recaptchaLoaded()
      const token = await executeRecaptcha('submit_form')
      return token
    }

    return {
      recaptcha
    }
  },
  created(){
    
    document.title= 'Contact - Symone'
  },
  watch: {
  },
  mounted(){
  },
  methods: {
    async sendForm() {
      try {
        const tk = await this.recaptcha();
        console.log('reCAPTCHA token:', tk);

        const captcha = await axios.post(`${config.API_BASE_URL}/recaptcha`, {
          data: {token: tk},
        });

        if (captcha.status !== 200) {
          alert('Verification failed, are you robot ? Please try again.');
          return;
        }
        if (!this.$refs.ruleRef) return;
        let check = await new Promise((resolve, reject) => {
          this.$refs.ruleRef.validate((valid, fields) => {
            if (valid) {
              console.log('submit!');
              resolve(true);
            } else {
              console.log('error submit:' + reject, fields);
              resolve(false);
            }
          });
        });
        if(!check) return;
        this.isSending = true;
        const response = await axios.post(
          `${config.API_BASE_URL}/mail/contact`,
          this.form
        );
        console.log(response.data);this.$router.push('/form/contact/succes');
        this.$message({
          message:
            "Demande envoyée, nous vous répondrons dans les plus brefs délais.",
          type: "success",
          duration: 5000,
        });
        setTimeout(() => {
          this.isSending = false;
          location.reload();
        }, 5000); 
      } catch (error) {
        console.error("Error Send/Contact:", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
*{
  text-decoration: none;
}
.el-checkbox{
  white-space: normal;
  height: auto;
}
.el-col-1, .el-col-2, .el-col-3, .el-col-4, .el-col-5, .el-col-6, .el-col-7, .el-col-8, .el-col-9, .el-col-10,.el-col-11, .el-col-12, .el-col-13, .el-col-14, .el-col-15, .el-col-16, .el-col-17, .el-col-18, .el-col-19, .el-col-20, .el-col-21, .el-col-22, .el-col-23, .el-col-24 {
  display: inline-block;
  width: 100%;
}
.loading-container, .loading-container-devis, .loading-container-reservation{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: #ffffffb0;
  z-index: 5;
}

.loading-spinner, .loading-spinner-devis, .loading-spinner-reservation {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}
.border {
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
  1px 1px 0 #000;
}
.container{
  color: #000047;
width: 100%;
}

.btn-trans-blue {
  background-color: transparent;
  color: #000047;
  font-size: 20px;
  border: 2px solid #000047;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.btn-trans-blue:hover {
  background-color: #000047;
  color: white;
}
.title{
  color: #000047;
  font-size: 50px;
  font-weight: 600;
  
background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  min-height: 250px;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  
  display: flex;    
  align-items: center;
  text-align: center;
  justify-content: center;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nav {
  padding: 25px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #000047;
}
.txt{
max-width: 1000px;
text-align: left;
*{
  margin: revert;
}
img{
  max-width: 100%;

}
}
.w-1{
width: 100%;
text-align: -webkit-center;
}
.menu {
display: flex;
}
.menu a {
color: white;
margin: 0 10px;
}
.details {
max-width: 40vw;
color: #dddddd;
font-size: 20px;
font-weight: 300;
text-transform: uppercase;
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
  1px 1px 0 #000;
}
.cover img{
max-width: 60vw;
max-height: 50vh;
}

.footer {
height: 100vh;
width: 100%;
padding: 50px;
box-sizing: border-box;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
img{
  max-width: 100%;
}
}
a{
color: #e6007e;
}
.form{
  padding: 50px 10px;
}
</style>
