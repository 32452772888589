<template>
    <div>
        <div class="nav" style="position: fixed; z-index: 2">
            <a href="/" target="_self"
            ><img
                src="../assets/images/ui/logo.png"
                style="margin-left: -100px; max-width: 250px"
            /></a>
            <div class="menu details">
            <a href="/" target="_self">Accueil</a>
            <a href="/moto" target="_self">Symone à moto</a>
            <a href="/news" target="_self">Actus</a>
            <a href="https://symone.fr/nous-contacter/" target="_blank"
                >Contact</a
            >
            <a href="https://symone.fr/page-faq/" target="_blank">FAQ</a>
            <a href="/" target="_self"
                ><img
                src="../assets/images/ui/france.png"
                style="width: 25px; height: 25px"
            /></a>
            <a href="/en" target="_self"
                ><img
                src="../assets/images/ui/royaume-uni.png"
                style="width: 25px; height: 25px"
            /></a>
            </div>
        </div>
        <div class="list">
          <div class="title border">{{ news.title }}</div>
          <div class="date">
            <el-breadcrumb>
              <el-breadcrumb-item :to="{ path: '/' }">Accueil</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/news' }">Actus</el-breadcrumb-item>
              <el-breadcrumb-item>{{ news.title }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="date"><span style="color:#E6007E;">Date de publication: </span>{{ formattedDate(news.date_publication) }}</div>
          <div v-if="news.cover" class="cover"><img :src="`${config.API_BASE_URL}/assets/images/news/${news.cover}`"></div>
          <div class="html" v-html="news.html"></div>
          <div v-if="news.video" class="video"><video v-if="news.video" :src="`${config.API_BASE_URL}/assets/videos/news/${news.video}`" controls></video></div>
        </div>
        <div class="footer">
          <div style="margin: 50px">
            <div
              style="
                font-size: 20px;
                color: #e6007e;
                font-weight: 400;
                text-align: center;
              "
            >
              SUIVEZ-NOUS
              <br />
              <a href="https://fr.linkedin.com/company/symone" target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://twitter.com/SymoneMobility" target="_blank"
                ><i
                  class="fab fa-twitter"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a
                href="https://www.instagram.com/symonemobility/?hl=fr"
                target="_blank"
                ><i
                  class="fab fa-instagram"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
                ><i
                  class="fab fa-facebook"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <br />
              <br />
              NOUS CONTACTER
              <br />
              <span style="color: #000047"
                >contact@symone.fr<br />06 17 33 43 93</span
              >
              <br />
              <br />
              ÉCRIVEZ-NOUS
              <br />
              <a href="https://symone.fr/nous-contacter/" target="_blanc"
                ><span style="color: #000047">Contact</span></a
              >
            </div>
            <div
              style="
                font-size: 20px;
                color: #000047;
                font-weight: 400;
                text-align: center;
              "
            >
              <br />
              <a href="/" target="_blank">Bienvenue à bord</a>
              <br /><br />
              <a href="/moto" target="_blank">Symone à moto</a>
              <br /><br />
              <a href="https://symone.fr/en-savoir-plus/" target="_blank"
                >Une nouvelle expérience de la route</a
              >
              <br /><br />
              <a
                href="https://symone.fr/conditions-generales-de-vente/"
                target="_blank"
                >Conditions Générales de Vente</a
              >
              <br />
            </div>
          </div>
          <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
        </div>
      </div>
  </template>
  
  <script>
  import axios from 'axios';
  import config from '@/config';

  export default {
    name: "NewsPage",
    components: { 
    },
    data() {
      return {
        config: config,
        news: {},
      };
    },
    created(){
      
      document.title= 'Actus - Symone'
    },
    watch: {
    },
    mounted(){
      const id = this.$route.params.id;
      this.getNewsById(id);
    },
    methods: {
      async getNewsById(id){
        try {
          let response = await axios.get(`${config.API_BASE_URL}/news/get/id/${id}`); // 替换为实际的后端API地址
          this.news = response.data[0];

          console.log(1234,this.news)
        } catch (error) {
          console.error('Error fetching news:', error);
        }
      },
      formattedDate(date){
        return String(date).slice(6, 8) + "-" + String(date).slice(4, 6) + "-" + String(date).slice(0, 4);
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  *{
    text-decoration: none;
  }
.border {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
  .list{
    background-color: #f2f2f2;
  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  width: 100%;
  .video, .cover{
    width: 100%;
    text-align: center;
    img, video{
      border-radius: 20px;
    }
  }  
  .date{
    padding: 50px 100px;
  }
  .html{
    padding: 50px 100px;
  }
}

.footer {
  height: 100vh;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img{
    max-width: 100%;
  }
}
  .title{
    color: white;
    background-image: url("../assets/images/SYMONE3.jpg");
    height: 250px;
    background-size: cover;
    background-position: start;
    width: 100%;
    
    display: flex;
    align-items: end;
    justify-content: center;
    font-size: 60px;
  }
  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
.nav {
  padding-top: 25px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: start;
}
.nav::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    transparent
  );
}
.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.details {
  max-width: 40vw;
  color: #dddddd;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.cover img{
  max-width: 60vw;
  max-height: 50vh;
}
</style>
  