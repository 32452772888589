<template>
  <div style="width: -webkit-fill-available">
    <div class="nav">
      <a href="/" target="_self"
        ><img
          src="../assets/images/ui/logo.png"
          style="max-width: 250px"
      /></a>
    </div>
    <div class="container">
      <div class="title">FAQ</div>
      <div class="w-1">
        <div class="txt">
          <el-collapse accordion>
            <el-collapse-item title="Un trajet plus long ?" name="1">
              <div
                id="elementor-tab-content-1981"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="1"
                role="region"
                aria-labelledby="elementor-tab-title-1981"
                style="display: block"
              >
                <p>
                  <span style="color: #000000"
                    ><strong
                      >Gagnez du temps en voyageant plus doucement
                      !&nbsp;</strong
                    ></span
                  >
                </p>
                <p>
                  Un trajet en Symone en journée ne prendra que peu de temps
                  supplémentaire qu'en voiture. C<span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    >e sera de très nombreuses heures gagnées et valorisées dans
                    un espace tout confort pour faire autre chose que
                    conduire.</span
                  ><span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    >&nbsp;</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    >Pour un voyage de nuit vous gagnez même une journée car
                    vous avez combiné sommeil et déplacement. Vous arrivez frais
                    et reposé à destination.</span
                  >
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Et le temps de chargement ?" name="2">
              <div
                id="elementor-tab-content-1982"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="2"
                role="region"
                aria-labelledby="elementor-tab-title-1982"
                style="display: block"
              >
                <p>
                  Réalisé par un professionnel en amont du péage, la durée
                  totale de cette opération est d'environ 10 à 15 minutes pour
                  l'ensemble des véhicules.
                </p>
                <p>
                  Puisque celui-ci est effectué au fur et à mesure de l'arrivée
                  des véhicules,
                  <strong
                    >le temps de chargement sera transparent pour vous</strong
                  >. En attendant le départ, vous pouvez commencer à profiter
                  des nombreux services à bord.
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="Pourquoi les véhicules sont au-dessus ?"
              name="3"
            >
              <div
                id="elementor-tab-content-1983"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="3"
                role="region"
                aria-labelledby="elementor-tab-title-1983"
                style="display: block"
              >
                <p>
                  Plusieurs contraintes techniques et réglementaires nous
                  obligent à cette configuration, véhicules en haut (+ en bas à
                  l'arrière). Cependant, nous répondons aux normes de sécurité
                  imposées et très strictes (R66 comprise). Le plancher
                  surbaissé, la superstructure et l'emplacement des réservoirs
                  permettent d'avoir un centre de gravité très intéressant dans
                  cette configuration.&nbsp;
                </p>
                <p>
                  Cette configuration est également plus adaptée pour les
                  personnes à mobilité réduite et la «&nbsp;zone de vie&nbsp;»
                  est bien mieux protégée grâce à la structure renforcée.
                </p>
                <p>La longueur maximale (règlementaire) est de 18,75m.</p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="Quel sera le prix de ce service ?"
              name="4"
            >
              <div
                id="elementor-tab-content-1984"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="4"
                role="region"
                aria-labelledby="elementor-tab-title-1984"
                style="display: block"
              >
                <p>
                  <strong
                    >Le prix d'un trajet en Symone sera équivalent au coût réel
                    du même trajet avec votre voiture !&nbsp;</strong
                  >
                </p>
                <p>
                  <b
                    >La liberté d'esprit, le gain de temps libre, le confort, la
                    sécurité et l'écologie en plus !</b
                  >
                  Ce coût sera compensé en totalité par des gains sur le péage,
                  le carburant, l'usure de votre véhicule, son entretien, sa
                  décote kilométrique et d'autres frais induits bien souvent
                  négligés.
                </p>
                <p>
                  <strong
                    >Vous réservez votre billet pour une voiture, quel que soit
                    le nombre de passagers à bord.</strong
                  >
                </p>
                <p>
                  <strong
                    >Lors de la première saison de Symone moto les clients ont
                    été très satisfaits du prix et du service. Ils ont fait des
                    économies sur leur trajet avec tous les avantages du train
                    sur l'autoroute. Avec Symone autocar, cela ne coutera pas
                    plus cher que de prendre sa voiture. Avec le confort, la
                    sécurité et l'écologie en plus. Et c'est sans compter sur la
                    valeur que vous donnez à votre temps (pas exemple 6h de
                    temps libre gagné lors d'un trajet Paris-Marseille en
                    Symone).</strong
                  >
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="Comment effectuer la réservation d'un voyage ?"
              name="5"
            >
              <div
                id="elementor-tab-content-1985"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="5"
                role="region"
                aria-labelledby="elementor-tab-title-1985"
                style="display: block"
              >
                <p>
                  La réservation s'effectuera via notre application mobile et
                  notre site internet, où toutes les modalités y seront
                  détaillées.
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="Et le train avec location de voitures ?"
              name="6"
            >
              <div
                id="elementor-tab-content-1986"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="6"
                role="region"
                aria-labelledby="elementor-tab-title-1986"
                style="display: block"
              >
                <p>
                  <strong
                    >Chaque mode de déplacement à ses avantages, Symone est
                    complémentaire au rail et apporte les avantages du train sur
                    l'autoroute avec votre voiture dans vos bagages !</strong
                  >
                </p>
                <p>
                  Des dizaines de milliers de personnes prennent encore
                  l'autoroute tous les jours sur de longues distances et ont
                  toutes une bonne raison de ne pas prendre le train, ni même
                  l'autotrain quand il existait encore. Ces personnes ont besoin
                  de leur véhicule à l'arrivée, le billet par passager peut vite
                  rendre le trajet très coûteux, le temps de trajet porte à
                  porte est aussi rallongé si on habite en périphérie d'une
                  grande ville, trop de contraintes pour aller au centre-ville,
                  payer un parking ou un autre mode de transport, etc.
                </p>
                <p>
                  <strong
                    >Autant de bonnes raisons de prendre une
                    Symone.&nbsp;</strong
                  >
                </p>
                <p>
                  <span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    >Plus d'informations :</span
                  >
                </p>
                <p>
                  <a
                    style="
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                      background-color: #ffffff;
                    "
                    href="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F891004718882454%2F&amp;show_text=true&amp;width=560&amp;t=0%20width=560%20height=429%20style=border:none;overflow:hidden%20scrolling=no%20frameborder=0%20allowfullscreen=true%20allow=autoplay;%20clipboard-write;%20encrypted-media;%20picture-in-picture;%20web-share%20allowFullScreen=true/iframe"
                    ><iframe
                      style="overflow: hidden"
                      src="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F891004718882454%2F&amp;show_text=true&amp;width=560&amp;t=0"
                      data-opt-src="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F891004718882454%2F&amp;show_text=true&amp;width=560&amp;t=0"
                      width="560"
                      height="750"
                      frameborder="0"
                      scrolling="no"
                      allowfullscreen="allowfullscreen"
                      data-opt-lazy-loaded="true"
                    ></iframe
                  ></a>
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item
              title="En quoi Symone est différente de l'autotrain ?"
              name="7"
            >
              <div
                id="elementor-tab-content-1987"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="7"
                role="region"
                aria-labelledby="elementor-tab-title-1987"
                style="display: block"
              >
                <p>
                  <span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    ><strong
                      >Symone est très flexible, sera déjà sur votre route et
                      peut se déployer facilement entre n'importe quelles
                      villes</strong
                    >
                    grâce au maillage territorial autoroutier existant très
                    dense.</span
                  >
                </p>
                <p>
                  L'autotrain n'était plus adapté à son époque. Trop de
                  contraintes tant pour l'usager que pour la SNCF. Il fallait
                  aller au centre-ville de Paris pour charger sa voiture. Payer
                  un billet par passager dans un autre train et attendre encore
                  pour récupérer sa voiture. Les infrastructures et la
                  logistique limitaient les gares de départs et d'arrivées.
                </p>
                <p>Plus d'informations :</p>
                <p></p>
                <blockquote
                  class="wp-embedded-content"
                  data-secret="AHxir0rvIb"
                >
                  <a
                    href="https://mediarail.wordpress.com/2021/08/09/les-trains-auto-couchettes-de-jadis-quel-avenir-de-nos-jours/"
                    >Les trains auto-couchettes de jadis, quel avenir de nos
                    jours&nbsp;?</a
                  >
                </blockquote>
                <iframe
                  class="wp-embedded-content"
                  sandbox="allow-scripts"
                  security="restricted"
                  style="position: absolute; clip: rect(1px, 1px, 1px, 1px)"
                  title="«&nbsp;Les trains auto-couchettes de jadis, quel avenir de nos jours&nbsp;?&nbsp;» — Mediarail.be  - Rail Europe News"
                  src="about:blank"
                  data-opt-src="https://mediarail.wordpress.com/2021/08/09/les-trains-auto-couchettes-de-jadis-quel-avenir-de-nos-jours/embed/#?secret=QB8JJhnyOy#?secret=AHxir0rvIb"
                  data-secret="AHxir0rvIb"
                  width="600"
                  height="338"
                  frameborder="0"
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                ></iframe>
                <p></p>
              </div>
            </el-collapse-item>

            <el-collapse-item title="Et le véhicule autonome ?" name="8">
              <div
                id="elementor-tab-content-1988"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="8"
                role="region"
                aria-labelledby="elementor-tab-title-1988"
                style="display: block"
              >
                <p>
                  Des grands progrès ont été faits ces dernières années mais les
                  premiers véhicules autonomes qui permettront de dormir ou de
                  travailler dans sa voiture ne sont pas attendus avant 2040. Un
                  avenir lointain donc, même si les systèmes d'aide à la
                  conduite sur autoroute tendent à se multiplier vers 2030.
                </p>
                <p>
                  Surtout quand on sait que la moyenne d'âge du parc automobile
                  français est de 11 ans.
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item
              title="Pourquoi l'hydrogène ? Ce vecteur énergétique est-il prêt ?"
              name="9"
            >
              <div
                id="elementor-tab-content-1989"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="9"
                role="region"
                aria-labelledby="elementor-tab-title-1989"
                style="display: block"
              >
                <p>
                  L'hydrogène est l'énergie la mieux adaptée pour les modes de
                  transport lourds et longues distances comme Symone.
                  L'hydrogène apporte une grande autonomie et un temps de
                  chargement réduit.
                </p>
                <p>
                  Donc Symone et
                  <strong
                    >l'hydrogène vert (par électrolyse issue des énergies
                    renouvelables)</strong
                  >
                  pour les trajets partagés sur autoroute, et les autres
                  énergies pour les voitures individuelles.
                </p>
                <p>
                  Imaginez des petites citadines électriques qui se rechargent
                  sur le dos d'une Symone pendant que leurs passagers sirotent
                  leur café à l'intérieur de leur habitacle.
                </p>
                <p>
                  <strong
                    >Un très bon exemple de mix énergétique et de multimodalité.
                    La bonne énergie pour la bonne utilisation, c'est l'avenir
                    !</strong
                  >
                </p>
                <p>
                  Le déploiement des stations hydrogène et la motorisation des
                  véhicules lourds sont assez matures pour que les Symone
                  puissent fonctionner à l'hydrogène vert lors de la phase
                  d'industrialisation en 2023.
                </p>
                <p>Le prototype fonctionnera au bioGNV.</p>
              </div>
            </el-collapse-item>

            <el-collapse-item title='Pourquoi " Symone " ?' name="10">
              <div
                id="elementor-tab-content-19810"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="10"
                role="region"
                aria-labelledby="elementor-tab-title-19810"
                style="display: block"
              >
                <p>«&nbsp;En voiture Simone !&nbsp;»</p>
                <p>
                  Au départ, une simple blague entre fondateurs, ce nom prend
                  finalement tout son sens. Avec une touche internationale
                  «&nbsp;S<strong>y</strong>mone&nbsp;» pour un projet aussi
                  ambitieux qui a bien entendu vocation à s'exporter.
                </p>
                <p>
                  Popularisée par Guy Lux lorsqu'il présentait l'émission
                  «&nbsp;Intervilles&nbsp;» dans les années 1960, l'expression
                  est cependant un peu plus ancienne …
                </p>
                <p>
                  Si vous regardiez l'émission Intervilles présentée par le
                  tandem Guy Lux – Léon Zitrone, vous avez sûrement dû entendre
                  ces mots : «&nbsp;En voiture Simone, c'est moi qui conduis,
                  c'est toi qui klaxonnes !&nbsp;». Un clin d'oeil à Simone
                  Garnier, co animatrice de l'émission ? Oui, mais l'expression
                  en elle-même fait référence à une autre femme : Simone-Louise
                  de Pinet de Borde des Forest.
                </p>
                <p>
                  Simone-Louise de Pinet de Borde des Forest à été l'une des
                  premières femmes pilotes de France. Elle a obtenu son permis
                  de conduite en 1929 (rare à l'époque pour une femme) puis a
                  débuté les courses automobiles dès l'année suivante, en 1930.
                  Durant 27 ans, elle prend le départ de nombreuses courses et
                  n'aurait jamais eu d'accidents, un joli pied de nez aux
                  clichés sur les femmes au volant.&nbsp;
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item title="Pourquoi la chouette ?" name="11">
              <div
              >
                <p>
                  <b>De jour comme de nuit, elle veille sur vous !</b>
                </p>
                <p>
                  Pour les fondateurs de Symone, la chouette est tout un
                  symbole. C'est un clin d'oeil à leur ville d'origine, Dijon.
                  Elle est aussi tout adaptée pour personnifier et représenter
                  ce nouveau mode de déplacement vert avec son côté rassurant.
                </p>
                <p>
                  «&nbsp;C'est apparemment depuis le XVIIème siècle, suite à un
                  événement surnaturel, qu'une croyance populaire veut que si
                  l'on caresse cette petite sculpture à Dijon avec la main
                  gauche, pendant que la main droite est posée sur le coeur, en
                  faisant un voeu, alors celui-ci deviendra réalité. Cette
                  pratique est tellement répandue que la pierre est polie
                  !&nbsp;».
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item
              title='"Combien de tonnes de déplacées pour quelques passagers ? Dans "écologie", il y a la question du CO2 certes, mais aussi celle de l&apos;efficacité énergétique."'
              name="12"
            >
              <div>
                <p>
                  <strong
                    >L'énergie utilisée par une Symone chargée avec 6 à 9
                    voitures ou motos sera inférieure à l'énergie nécessaire
                    pour que ces mêmes véhicules se déplacent seuls sur
                    l'autoroute.</strong
                  >
                </p>
                <p>
                  En effet, un poids lourd de 44 tonnes diesel sur autoroute ne
                  consomme pas autant que 30 voitures diesel individuelles
                  (30*1,5t). Un poids lourd consomme en moyenne
                  «&nbsp;seulement&nbsp;» 6 fois plus qu'une voiture.
                </p>
                <p>
                  Car les poids lourds ont une vitesse plus faible, il faut
                  prendre en compte l'aérodynamisme de tous les véhicules
                  individuels, le frottement de plus de roues au sol, la
                  répartition des masses, le moteur des véhicules lourds est
                  plus adapté, …
                </p>
                <p>
                  Cependant, même si la question de la pertinence énergétique
                  avec Symone ne se pose donc pas, il est intéressant de se
                  demander si l'efficacité énergétique doit être prioritaire sur
                  la réduction de CO2. Surtout quand on compare de l'énergie
                  décarbonée (une Symone) avec de l'énergie carbonée (6 à 9
                  voitures/motos).
                </p>
                <p>
                  Cela permettra d'éviter le rejet de CO2 que ces véhicules
                  auraient produit. C'est l'effet recherché par tous dans la
                  transition écologique et énergétique.
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item
              title="Pourquoi Symone n'utilise qu'un seul véhicule pour transporter les voitures et leurs passagers ?"
              name="13"
            >
              <div>
                <iframe
                  style="border: none; overflow: hidden"
                  src="https://www.facebook.com/plugins/video.php?height=306&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F1289180988692204%2F&amp;show_text=true&amp;width=560&amp;t=0"
                  data-opt-src="https://www.facebook.com/plugins/video.php?height=306&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F1289180988692204%2F&amp;show_text=true&amp;width=560&amp;t=0"
                  width="800"
                  height="750"
                  frameborder="0"
                  scrolling="no"
                  allowfullscreen="allowfullscreen"
                  data-opt-lazy-loaded="true"
                ></iframe>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div class="footer">
      <div style="margin: 50px">
        <div
          style="
            font-size: 20px;
            color: #e6007e;
            font-weight: 400;
            text-align: center;
          "
        >
          SUIVEZ-NOUS
          <br />
          <a href="https://fr.linkedin.com/company/symone" target="_blank"
            ><i
              class="fab fa-linkedin"
              style="color: #000047; font-size: 20px"
            ></i
          ></a>
          <a href="https://twitter.com/SymoneMobility" target="_blank"
            ><i
              class="fab fa-twitter"
              style="color: #000047; font-size: 20px"
            ></i
          ></a>
          <a
            href="https://www.instagram.com/symonemobility/?hl=fr"
            target="_blank"
            ><i
              class="fab fa-instagram"
              style="color: #000047; font-size: 20px"
            ></i
          ></a>
          <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
            ><i
              class="fab fa-facebook"
              style="color: #000047; font-size: 20px"
            ></i
          ></a>
          <br />
          <br />
          NOUS CONTACTER
          <br />
          <span style="color: #000047"
            >contact@symone.fr<br />06 17 33 43 93</span
          >
          <br />
          <br />
          ÉCRIVEZ-NOUS
          <br />
          <a href="/contact" target="_blanc"
            ><span style="color: #000047">Contact</span></a
          >
        </div>
        <div
          style="
            font-size: 20px;
            color: #000047;
            font-weight: 400;
            text-align: center;
          "
        >
          <br />
          <a href="/" target="_blank">Bienvenue à bord</a>
          <br /><br />
          <a href="/moto" target="_blank">Symone à moto</a>
          <br /><br />
          <a href="/#home-tag-2" target="_self"
            >Une nouvelle expérience de la route</a
          >
          <br /><br />
          <a href="/condition" target="_blank">Conditions Générales de Vente</a>
          <br />
        </div>
      </div>
      <img src="../assets/images/ui/logo_couleur (1).png" alt="Logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqPage",
  components: {},
  data() {
    return {
	};
  },
  mounted(){
    this.$nextTick(() => {
      const elements = document.querySelectorAll('.el-collapse-item__header');
      elements.forEach(element => {
        element.style.lineHeight = 'normal';
        element.style.textAlign = 'left';
      });
    });
  }
};
</script>
<style lang="scss" scoped>
* {
  text-decoration: none;
}
.border {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.container {
  color: #000047;
  width: 100%;
}
.title {
  color: #000047;
  font-size: 50px;
  font-weight: 600;

  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  min-height: 250px;
  background-size: cover;
  background-position: bottom;
  width: 100%;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.nav {
  padding: 25px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #000047;
}
.txt {
  max-width: 1000px;
  text-align: left;
  * {
    margin: revert;
  }
  img {
    max-width: 100%;
  }
}
.w-1 {
  width: 100%;
  text-align: -webkit-center;
}
.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.details {
  max-width: 40vw;
  color: #dddddd;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.cover img {
  max-width: 60vw;
  max-height: 50vh;
}

.footer {
  height: 100vh;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 100%;
  }
}
a {
  color: #e6007e;
}
</style>
